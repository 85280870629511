import BaseViewModel from "../../BaseViewModel";
import APIService from "../../../Services/APIService";
import Client from "../../../Model/Client";
import {UpdateClientData} from "../../../Services/APIServiceInterfaces/Client";


export default class ClientDetailsPageViewModel extends BaseViewModel {

  clientId: number
  client: Client|null = null

  constructor(
    clientId: number,
  ) {
    super();

    this.clientId = clientId
  }

  async loadClient(): Promise<any> {
    this.enterLoadingProcess()
    this.updateView()

    const resp = await APIService.getInstance().loadClient(
      this.clientId,
    )
    this.client = resp.client

    this.leaveLoadingProcess()
    this.updateView()
  }

  sendSaveRequest(data: UpdateClientData): Promise<any> {
    return APIService.getInstance().updateClientRequest(data)
  }

  async archiveClient(): Promise<void> {
    this.client = await APIService.getInstance().archiveClient(this.clientId)
    this.updateView()
    return
  }

}