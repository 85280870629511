import {gql} from "graphql-request";
import {ContainersAtClientDoc} from "./ContainersAtClientGQLDocs";
import {OrderProductDocs} from "./OrderProductDocs";
import {OrderContainersDocs} from "./OrderContainersDocs";


export const getOrderStatusesDoc = () => {
  return gql`
    query orderStatusesQuery {
      orderStatuses {
        orderStatuses {
          id
          name
          queuePosition
          isOrderDoneStatus
        }
        count
      }
    }    
  `;
};

