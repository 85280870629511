import {gql} from "graphql-request";
import {IOrderDoc, Order} from "./interfaces/IOrderGQLDoc";


export interface MarkOrderAsDoneData_containersToConfirm {
  containerId: number,
  containerName: string,
  containerQuantity: number,
  containerBruttoPrice: number,
}
export interface MarkOrderAsDoneData {
  id: number,
  base64StringOfSignature: string | null,
  containersToConfirm: MarkOrderAsDoneData_containersToConfirm[],
}
export declare type MarkOrderAsDoneResponse = {
  order: Order
};

export const markOrderAsDoneDoc = () => {
  return gql`
    mutation markOrderAsDone(
      $id: ID!
      $base64StringOfSignature: String,
      $containersToConfirm: [ContainerToConfirm]!,
    ) {
      markOrderAsDone(
        id: $id
        base64StringOfSignature: $base64StringOfSignature
        containersToConfirm: $containersToConfirm
        isTest: false
      ) {
        ` +
    IOrderDoc()
    + `
      }
    }  
  `;
};
