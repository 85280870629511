import BaseVMComponent from "../BaseVMComponent";
import TableComponent, {ActionButtonsCol, TableColumn} from "../Shared/TableComponent";
import ContainersAtClientTableViewModel from "../../ViewModels/Clients/ContainersAtClientTableViewModel";
import {DateService} from "../../Services/DateService";
import ContainersAtClient from "../../Model/ContainersAtClient";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import {tab} from "@testing-library/user-event/dist/tab";


interface Props {
  navigation: NavigateFunction,
  page: number,
  clientId: number,
  simpleTable: boolean,
}
interface State {
}
class _ContainersAtClientTable extends BaseVMComponent<Props, State, any, ContainersAtClientTableViewModel> {
  static defaultProps = {
    simpleTable: false,
  }

  constructor(props: any) {
    super(props);

    this.state = {
    }
  }


  protected initViewModel(): void {
    this.viewModel = new ContainersAtClientTableViewModel(
      this.props.clientId,
      this.props.page,
      this.props.simpleTable
    )
  }

  async componentDidMount() {
    this.viewModel.loadContainersAtClient()
  }

  private onRowDetailsClick(rowIndex: number) {
    const clickedElement = this.viewModel.containersAtClient[rowIndex]
    this.props.navigation(`/admin/clients/client/${this.props.clientId}/containers-at-client/details/${clickedElement.id}`)
  }

  private getColumns(): TableColumn[] {
    let columns: TableColumn[] = [
      {
        title: "Info",
        valueKey: "info",
        resolveValue: (tableDataEntry: ContainersAtClient) => {
          const containersAtClient: ContainersAtClient = tableDataEntry
          const dateStr = DateService.getShortDateString(new Date(containersAtClient.date))
          let numberOfContainers = 0
          containersAtClient.containers.forEach((containerEntry) => {
            numberOfContainers += containerEntry.containerQuantity
          })

          let confirmedInfo: string = ""
          if (this.props.simpleTable) {
            if (!!containersAtClient.signatureSrcURL) {
              confirmedInfo = " (signiert)"
            }
          }
          return `${dateStr} - ${numberOfContainers} Kisten ${confirmedInfo}`
        }
      },
    ]

    if (!this.props.simpleTable) {
      columns.push(
        {
          title: "Signiert",
          valueKey: "confirmed",
          resolveValue: (containersAtClient: ContainersAtClient) => {
            if (!!containersAtClient.signatureSrcURL) {
              return "Ja"
            }
            return "Nein"
          }
        },
      )
    }

    columns.push(
      ActionButtonsCol
    )

    return columns
  }

  render() {
    let cardBodyStyle: React.CSSProperties | null = null
    if (this.props.simpleTable) {
      cardBodyStyle = {
        padding: 0,
      }
    }

    return (
      <>
        <TableComponent
          title={"Letzte Pfandbestätigungen"}
          columns={this.getColumns()}
          tableData={this.viewModel.containersAtClient}
          isLoading={this.viewModel.isLoading()}
          offset={this.viewModel.getRequestOffset()}
          limit={this.viewModel.getRequestLimit()}
          totalCount={this.viewModel.allElementsCount}
          onRowDetailsClick={(rowIndex, tableDataEntry) => {
            this.onRowDetailsClick(rowIndex)
          }}
          showSimpleTable={this.props.simpleTable}
          hideTableHeading={this.props.simpleTable}
          cardBodyStyle={cardBodyStyle}
          pageNumber={this.props.page}
          onPageClick={(page) => {
            this.props.navigation(`/admin/clients/client/${this.props.clientId}/containers-at-client/${page}`)
          }}
        />
      </>
    )
  }
}

const ContainersAtClientTable: any = (props: Props) => {
  const navigation = useNavigate();
  const { containersAtClientPage } = useParams();

  return (
    <_ContainersAtClientTable
      {...props}
      navigation={navigation}
      page={parseInt(containersAtClientPage || "1")}
      ref={(props as any).innerRef}
    />
  )
}

export default ContainersAtClientTable;