import React, {Component, Fragment} from "react";
import OrderContainerDetailsRow from "./OrderContainerDetailsRow";
import ContainersAtClient from "../../Model/ContainersAtClient";
import {DateService} from "../../Services/DateService";


interface Props {
  containersAtClient: ContainersAtClient | null,
}
interface State {

}
export default class ContainersAtClientComponent extends Component<Props, State> {
  render() {
    const containersAtClient = this.props.containersAtClient
    let quantityOfContainersAtClient = 0
    if (containersAtClient != null) {
      containersAtClient.containers.forEach(el =>
        quantityOfContainersAtClient += el.containerQuantity
      )
    }

    if (containersAtClient == null) {
      return (
        <p style={{
          color: "red",
        }}>
          keine Pfandbestätigung gefunden
        </p>
      )
    }

    const dateString = DateService.getShortDateString(new Date(containersAtClient.date))
    const DateHeadingEl = (
      <p className={"mb-2"}>Stand (letzter Auftrag/Gebindestandänderung): <b>{dateString}</b></p>
    )

    if (quantityOfContainersAtClient === 0) {
      return (
        <div>
          {DateHeadingEl}
          <p style={{
            color: "rgb(200, 129, 0)",
          }}>
            kein Gebinde bei Kunde
          </p>
        </div>
      )
    }

    let _elements: JSX.Element[] = []
    containersAtClient.containers.forEach(container => {
      _elements.push(
        <OrderContainerDetailsRow
          key={"con-at-client-" + container.id}
          containerInputData={{
            name: container.containerName,
            bruttoPrice: container.containerBruttoPrice,
            quantity: container.containerQuantity,
          }}
        />
      )
    })

    return (
      <Fragment>
        {DateHeadingEl}
        {_elements}
      </Fragment>
    )
  }
}
