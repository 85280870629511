import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import Client from "../../Model/Client";
import IBaseContainer from "../../Model/IBaseContainer";
import {AddContainersContainer} from "../Containers/AddContainersFormViewModel";
import {OrderContainersInput} from "../../Services/APIServiceGQLDocs/OrderContainersDocs";
import {ContainerConfirmationContainer} from "../ContainerConfirmationFormViewModel";


export class NoSignatureError extends Error {
  constructor() {
    super("no signature");
  }
}

export default class CustomContainerConfirmationPageViewModel extends BaseViewModel {

  clientId: number
  loading: boolean = true
  client: Client | null = null
  private containersInput: AddContainersContainer[] = []
  private additionalInfo: string | null = null
  private base64StringOfSignature: string | null = null


  constructor(
    clientId: number,
  ) {
    super();

    this.clientId = clientId
  }

  async loadClient(): Promise<any> {
    this.loading = true
    this.updateView()

    const resp = await APIService.getInstance().loadClient(
      this.clientId,
    )
    this.client = resp.client

    this.loading = false
    this.updateView()
  }

  onNewOrderContainersChange(containers: AddContainersContainer[]) {
    this.containersInput = containers
  }

  getContainersInput(): AddContainersContainer[] {
    return this.containersInput
  }

  getContainerInputAsOrderContainers(): ContainerConfirmationContainer[] {
    let containers: ContainerConfirmationContainer[] = []
    this.containersInput.forEach((el, index) => {
      if (el.container != null) {
        containers.push({
          containerId: el.container.id + "-" + index,
          containerInputData: {
            name: el.container.name,
            quantity: el.orderContainersInput.quantity,
            bruttoPrice: el.orderContainersInput.bruttoPrice,
          },
        })
      }
    })
    return containers
  }

  hasNoContainersSet(): boolean {
    let quantityContainers = 0
    this.containersInput.forEach(el =>
      quantityContainers += el.orderContainersInput.quantity
    )
    return quantityContainers === 0
  }

  getAdditionalInfo(): string | null {
    return this.additionalInfo
  }

  setAdditionalInfo(value: string | null) {
    if (value === "") {
      value = null
    }
    this.additionalInfo = value
  }

  getBase64StringOfSignature(): string | null {
    return this.base64StringOfSignature
  }

  setBase64StringOfSignature(value: string | null) {
    if (value === "") {
      value = null
    }
    this.base64StringOfSignature = value
  }

  private async sendSaveRequest(base64OfSignature: string | null) {
    let containers: OrderContainersInput[] = []

    for (let containerInput of this.containersInput) {
      containers.push({
        containerId: containerInput.container!.id,
        quantity: containerInput.orderContainersInput.quantity,
        bruttoPrice: containerInput.orderContainersInput.bruttoPrice,
      })
    }

    await APIService.getInstance().createContainersAtClient({
      clientId: this.clientId,
      additionalInfo: this.additionalInfo || undefined,
      signatureAsBase64: base64OfSignature || undefined,
      containers: containers,
    })
  }

  async onSaveWithoutConfirmationClick() {
    await this.sendSaveRequest(null)
  }

  async onSaveWithConfirmationClick() {
    await this.sendSaveRequest(this.base64StringOfSignature)
  }

}