import platform from "platform";
import * as Sentry from "@sentry/react";


export default class PlatformService {

  private platformName: string | null = null
  private platformVersion: string | null = null


  constructor() {
    this.platformName = platform?.name || null
    this.platformVersion = platform?.version || null
  }

  isSafari(): boolean {
    return this.platformName != null && this.platformName.toLowerCase() === "safari"
  }

  private splitVersionString(versionStr: string): number[] {
    const splitVersion = versionStr.split(".")

    let version: number[] = []
    for (let i = 0; i < splitVersion.length; i++) {
      const el = splitVersion[i];
      let num = parseInt(el)
      if (isNaN(num)) {
        console.error("could not decode version")
        Sentry.captureEvent({
          event_id: "could not decode version",
          message: `could not decode version - version: ${this.platformVersion}`
        })
        return []
      }
      version.push(num)
    }

    return version
  }

  getBrowserVersion(): number[] {
    if (this.platformVersion == null) {
      return []
    }
    return this.splitVersionString(this.platformVersion)
  }

  compareBrowserVersion(a: string, b: string): number {
    let splitVersionA = this.splitVersionString(a)
    let splitVersionB = this.splitVersionString(b)

    let returnVal: number = 0
    if (splitVersionB.length === 0 || splitVersionA.length === 0) {
      return returnVal
    }

    if (splitVersionA.length < splitVersionB.length) {
      for (let i = 0; i < splitVersionB.length; i++){
        if (!(splitVersionA.length - 1 >= i)) {
          splitVersionA.push(0)
        }
      }
    }

    for (let i = 0; i < splitVersionA.length; i++){
      if (!(splitVersionB.length - 1 >= i)) {
        break
      }
      const versionEl = splitVersionA[i]
      let browserVerEl = splitVersionB[i]
      if (versionEl < browserVerEl) {
        return -1
      } else if (versionEl === browserVerEl) {
        continue
      } else {
        // higher
        return 1
      }
    }

    return returnVal
  }

  supportsSubmitEvent(): boolean {
    this.getBrowserVersion()

    if (
      this.isSafari() &&
      this.platformVersion != null &&
      this.compareBrowserVersion(this.platformVersion, "15.4") < 0
    ) {
      return false
    }

    return true
  }
}