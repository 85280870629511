import React, {Component} from "react";
import {ProductInputRowStyles} from "./styles/ProductInputRowStyles";
import {DropDownComponent, DropDownOption} from "./DropDownComponent";
import {InputComponent} from "./InputComponent";
import {FormCheck, FormGroup} from "react-bootstrap";
import FloatService from "../../Services/FloatService";


interface Props {
  id: string,
  productSelect: {
    idValue: string | null,
    options: DropDownOption[],
    onValueChange?: (value: string | null) => void,
    inputDisabled: boolean,
  },
  quantityInput: {
    value: number,
    onChange?: (value: number) => void,
    inputDisabled: boolean,
  },
  priceInput: {
    value: number,
    onChange?: (value: number) => void,
    inputDisabled: boolean,
  },
  isFreeCheckbox: {
    checked: boolean,
    onChange?: (checked: boolean) => void,
    inputDisabled: boolean,
  },
  normalPrice: {
    value?: number,
  }
}

interface State {
}

export default class ProductInputRow extends Component<Props, State> {

  private getPriceSpan(): JSX.Element {
    let content: string = "-"
    if (this.props.normalPrice.value != null) {
      content = FloatService.toGermanCurrencyValueString(this.props.normalPrice.value)
    }

    return (
      <span
        style={ProductInputRowStyles.productPriceSpan}
      >
        (<span title={"Normalpreis"} style={{cursor: "default"}}>NP</span>: € {content})
      </span>
    )
  }

  render() {
    return (
      <div
        className={"ProductInputRow"}
        style={ProductInputRowStyles.productsInputRow}
      >
        <DropDownComponent
          onValueChange={(value) => {
            if (this.props.productSelect.onValueChange) {
              this.props.productSelect.onValueChange(value)
            }
          }}
          options={this.props.productSelect.options}
          labelTitle={""}
          idValue={this.props.productSelect.idValue}
          disabled={this.props.productSelect.inputDisabled}
          onInputValueChange={() => {}}
          className={"mb-0"}
        />

        <InputComponent
          labelTitle={""}
          hideLabel={true}
          onValueChange={(value) => {
            if (this.props.quantityInput.onChange == null) {
              return
            }

            let valAsNum = parseInt(value)
            if (isNaN(valAsNum)) {
              valAsNum = 0
            }
            this.props.quantityInput.onChange(valAsNum)
          }}
          type={"number"}
          numberProps={{
            min: 0,
          }}
          value={this.props.quantityInput.value.toString()}
          inlined={true}
          style={ProductInputRowStyles.productQuantityInput({lightBg: this.props.quantityInput.value === 0})}
          labelStyle={ProductInputRowStyles.productInputLabel}
          inputStyle={ProductInputRowStyles.productInputInput}
          disabled={this.props.quantityInput.inputDisabled}
        />


        <div className={"price-wrapper"} style={ProductInputRowStyles.priceWrapper}>
          <span style={ProductInputRowStyles.priceWrapperSpan}>à €</span>

          <InputComponent
            labelTitle={"Preis"}
            hideLabel={true}
            onValueChange={(value) => {
              if (this.props.priceInput.onChange == null) {
                return
              }

              let valAsNum = parseFloat(value)
              if (isNaN(valAsNum)) {
                valAsNum = 0
              }
              this.props.priceInput.onChange(valAsNum)
            }}
            type={"number"}
            numberProps={{
              min: 0,
            }}
            value={(this.props.isFreeCheckbox.checked ? null : this.props.priceInput.value.toString())}
            inlined={true}
            style={ProductInputRowStyles.productPriceInput}
            labelStyle={ProductInputRowStyles.productInputLabel}
            inputStyle={ProductInputRowStyles.productInputInput}
            disabled={this.props.priceInput.inputDisabled || this.props.isFreeCheckbox.checked}
            stepSize={0.01}
          />

          <FormGroup controlId={"show-done-orders"}>
            <FormCheck
              id={"is-free-product-" + this.props.id}
              title={"Gratis"}
              label={"Gratis"}
              style={{
                color: "gray",
                marginLeft: "1rem",
                opacity: this.props.isFreeCheckbox.inputDisabled ? 0.5 : 1,
              }}
              checked={this.props.isFreeCheckbox.checked}
              onChange={(value) => {
                if (this.props.isFreeCheckbox.onChange) {
                  this.props.isFreeCheckbox.onChange(value.target.checked)
                }
              }}
              disabled={this.props.isFreeCheckbox.inputDisabled}
            />
          </FormGroup>
        </div>

        {this.getPriceSpan()}
      </div>
    );
  }
}