import React, {Component, Fragment} from "react";
import Select, {SingleValue} from 'react-select'


export interface DropDownOption {
  value: string
  label: string
}

interface Props {
  labelTitle: string,
  options: DropDownOption[],
  idValue: string|null,
  onValueChange: (newIdValue: string|null) => void,
  onInputValueChange: (newIdValue: string) => void,
  disabled: boolean,
  required: boolean,
  disabledAutomaticFilter: boolean,
  className: string,
}
interface State {
  hasError: boolean,
}
export class DropDownComponent extends Component<Props, State> {
  static defaultProps = {
    required: false,
    disabledAutomaticFilter: false,
    className: "",
  }

  state: State = {
    hasError: false,
  }

  fromGroupRef: React.RefObject<HTMLDivElement>|null = null
  formElement: HTMLFormElement|null = null
  onFormSubmitFunction: () => void = () => {}


  constructor(props: Props) {
    super(props);

    this.fromGroupRef = React.createRef<HTMLDivElement>()
  }

  componentDidMount() {
    if (this.fromGroupRef != null && this.fromGroupRef.current != null)  {
      let currentEl: HTMLElement|null = this.fromGroupRef.current
      while (currentEl != null && currentEl.tagName.toLowerCase() !== "form") {
        currentEl = currentEl.parentElement
      }
      if (currentEl != null) {
        // is form element
        this.formElement = currentEl as HTMLFormElement
        this.onFormSubmitFunction = this.onFormSubmit.bind(this)
        this.formElement.addEventListener("submit", this.onFormSubmitFunction)
      }
    }
  }

  componentWillUnmount() {
    if (this.formElement != null) {
      this.formElement.removeEventListener("submit", this.onFormSubmitFunction)
    }
  }

  onFormSubmit() {
    this.validate()
  }

  private getFilterOption() {
    if (this.props.disabledAutomaticFilter) {
      return null
    }

    return undefined
  }

  private getLabel(): JSX.Element | null {
    let labelTitle = this.props.labelTitle

    if (!labelTitle) {
      return null
    }

    if (!this.props.required || this.props.disabled) {
      return (
        <label>{labelTitle}</label>
      )
    }

    return (
      <Fragment>
        <label>
          {labelTitle}
          <span style={style.requiredStar}>*</span> <span style={style.requiredString}>(Pflichtfeld)</span>
        </label>
      </Fragment>
    )
  }

  private validate() {
    if (this.props.required && this.props.idValue == null) {
      this.setState({
        hasError: true,
      })
    } else {
      this.setState({
        hasError: false,
      })
    }
  }

  private getDivClasses(): string {
    let classes = "form-group"
    if (this.props.className) {
      classes += " " + this.props.className
    }
    return classes
  }

  render() {
    let selectedOption = this.props.options.find(el => el.value === this.props.idValue)

    return (
      <div
        className={this.getDivClasses()}
        ref={this.fromGroupRef}
      >
        {this.getLabel()}
        <Select
          placeholder={"Auswählen ..."}
          options={this.props.options}
          value={selectedOption}
          isDisabled={this.props.disabled}
          required={this.props.required}
          noOptionsMessage={() => {
            return <span>keine Optionen</span>
          }}
          onChange={(newValue) => {
            if (newValue) {
              let newIdValue = newValue.value
              this.props.onValueChange(newIdValue)
            } else {
              this.props.onValueChange(null)
            }

            setTimeout(() => {
              this.validate()
            }, 250)
          }}
          onInputChange={(newValue) => {
            this.props.onInputValueChange(newValue)
          }}
          filterOption={this.getFilterOption()}
          styles={{
            control: (styles, { isDisabled}) => {
              if (isDisabled) {
                return {
                  ...styles,
                  backgroundColor: isDisabled ? '#e9ecef' : "white",
                  border: "1px solid #ced4da",
                };
              }

              if (this.state.hasError) {
                return {
                  ...styles,
                  border: "1px solid red",
                };
              }

              return styles;
            },
            // For the options
            option: (styles, { isDisabled}) => {
              if (isDisabled) {
                return {
                  ...styles,
                  backgroundColor: isDisabled ? '#e9ecef' : "white",
                };
              }
              return styles;
            },
            singleValue: (styles, { isDisabled }) => {
              if (isDisabled) {
                return {
                  ...styles,
                  color: "#495057",
                };
              }
              return styles;
            },
          }}
        />
      </div>
    );
  }
}


const style: any = {
  requiredStar: {
    color: "red",
    fontWeight: "normal",
  },
  requiredString: {
    color: "gray",
    fontWeight: "normal",
  },
}