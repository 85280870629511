import React, {Component} from "react";
import OrderProductDetailsRow from "../Orders/OrderForm/OrderProductDetailsRow";
import OrderProduct from "../../Model/OrderProduct";


interface Props {
  orderProducts: OrderProduct[],
}
interface State {

}
export default class OrderProductsList extends Component<Props, State> {

  render() {
    const productsOfOrderElements: JSX.Element[] = []
    this.props.orderProducts.forEach(product => {
      // ignore elements with quantity 0
      if (product.productQuantity === 0) {
        return
      }

      productsOfOrderElements.push(
        <OrderProductDetailsRow
          key={product.id + "_" + product.productId}
          product={product}
          quantity={product.productQuantity}
          price={product.productBruttoPrice}
          isFreeProduct={product.isFreeProduct}
        />
      )
    })

    return (
      <div className={"order-products-list"}>
        {productsOfOrderElements}
      </div>
    )
  }
}