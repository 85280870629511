import moment from "moment";


export enum DateFormat {
  SHORT,
  LONG,
}

export class DateService {

  static getWeekdayAsString(weekDay: number): string {
    let weekDayString = ""
    switch (weekDay) {
      case 1:
        weekDayString = "Montag"
        break;
      case 2:
        weekDayString = "Dienstag"
        break;
      case 3:
        weekDayString = "Mittwoch"
        break;
      case 4:
        weekDayString = "Donnerstag"
        break;
      case 5:
        weekDayString = "Freitag"
        break;
      case 6:
        weekDayString = "Samstag"
        break;
      case 0:
        weekDayString = "Sonntag"
        break;
    }

    return weekDayString
  }

  static getDateStringFromMs(
    dateMsString: string,
    dateFormat: DateFormat
  ): string|null {
    if (dateMsString == null) {
      return null
    }

    const momentDate = moment.unix(parseInt(dateMsString) / 1000)

    let dateString = ""
    if (dateFormat === DateFormat.LONG) {
      dateString = momentDate.format("DD.MM.YYYY")

      // get day of week
      let weekDayString = this.getWeekdayAsString(momentDate.weekday())
      if (weekDayString) {
        dateString = weekDayString + ", " + dateString
      }
    } else if (dateFormat === DateFormat.SHORT) {
      dateString = momentDate.format("DD.MM.YYYY")
    }

    return dateString
  }

  /**
   * returns -1 if b before a, 1 if a before b or 0 if same date
   */
  static compareDatesByDay(a: Date, b: Date): number {
    if (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    ) {
      // same date
      return 0
    }

    if (a.getFullYear() < b.getFullYear()) {
      // a before b
      return 1
    }
    if (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() < b.getMonth()
    ) {
      // a before b
      return 1
    }
    if (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() < b.getDate()
    ) {
      // a before b
      return 1
    }

    // b before a
    return -1
  }

  static getShortDateString(
    date: Date,
    weekday: "long" | "short" | "narrow" | null = 'long',
    hour: "numeric" | "2-digit" | null = null,
    minute: "numeric" | "2-digit" | null = null,
    second: "numeric" | "2-digit" | null = null,
  ): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: weekday || undefined,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: hour || undefined,
      minute: minute || undefined,
      second: second || undefined,
    };

    // eg: "Donnerstag, 11.03.2023"
    return date.toLocaleDateString("de-DE", options)
  }

  static getDateFromShortDateString(string: string): Date | "Invalid Date" {
    // check string form
    const regex = new RegExp(/^(?:\w+,)? ?([0-9]?[1-9])\.([0-9]?[1-9])\.((?:([1-9][0-9][0-9][0-9])|([1-9][0-9])))$/)
    const regexMatch = string.match(regex)
    if (regexMatch == null || regexMatch.length <= 4) {
      return "Invalid Date"
    }

    const dayStr = regexMatch[1]
    const monthStr = regexMatch[2]
    let yearStr = regexMatch[3]
    if (yearStr.length === 2) {
      yearStr = "20" + yearStr
    }
    const dateStr = `${yearStr}-${monthStr}-${dayStr}`

    return new Date(Date.parse(dateStr))
  }
}