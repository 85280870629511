import BaseViewModel from "./BaseViewModel";
import {APIError} from "../Services/APIService";
import {getErrorTextByError} from "../global/Error";


export default abstract class BaseFormViewModel<CreateRequestData> extends BaseViewModel {

  sendSaveRequest: (data: CreateRequestData) => Promise<any>

  saveIsLoading: boolean = false

  successfullySave: {
    visible: boolean,
    success: boolean,
  } = {
    visible: false,
    success: false,
  }
  error: {
    visible: boolean,
    string: string|null,
  } = {
    visible: false,
    string: null,
  }


  constructor(
    sendSaveRequest: (data: CreateRequestData) => Promise<any>,
  ) {
    super();

    this.sendSaveRequest = sendSaveRequest
    this.clearErrorFeedback()
  }

  viewDidMount() {
    super.viewDidMount();
  }

  clearErrorFeedback() {
    this.successfullySave = {
      visible: false,
      success: false,
    }
    this.error = {
      visible: false,
      string: null,
    }
  }

  protected abstract formDataIsValid(): boolean;

  protected abstract getCreateRequestData(): CreateRequestData

  getIdOfCreatedObjectFromResponse(resp: any): string | null {
    return null
  }

  async onSaveClick(
    closeAfterSave: boolean,
    onSuccessfullySaved: (closeAfterSave: boolean, newIdValue: string | null) => void,
  ) {
    if (this.saveIsLoading) {
      return
    }
    this.saveIsLoading = true
    this.updateView()

    this.successfullySave = {
      visible: false,
      success: false,
    }
    this.error = {
      visible: false,
      string: null,
    }
    this.updateView()

    if (!this.formDataIsValid()) {
      this.saveIsLoading = false
      this.updateView()
      return
    }

    let resp: {success: boolean, error: APIError|null, resp: any | null}
    try {
      const reqResponse = await this.sendSaveRequest(this.getCreateRequestData())
      resp = {
        success: true,
        error: null,
        resp: reqResponse,
      }
    } catch (e) {
      resp = {
        success: false,
        error: (e as APIError),
        resp: null,
      }
    }

    if (!resp.success) {
      this.error = {
        visible: true,
        string: getErrorTextByError(resp.error?.message)
      }
      this.updateView()
    } else {
      this.successfullySave = {
        visible: true,
        success: true,
      }
      this.updateView()

      let idOfNewObject = this.getIdOfCreatedObjectFromResponse(resp)
      onSuccessfullySaved(closeAfterSave, idOfNewObject)
    }

    this.saveIsLoading = false
    this.updateView()
  }

}