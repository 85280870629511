import BaseVMComponent from "../BaseVMComponent";
import TableComponent, {ActionButtonsCol, TableColumn} from "../Shared/TableComponent";
import ClientsTableComponentViewModel from "../../ViewModels/Clients/ClientsTableComponentViewModel";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import React from "react";
import {ClientFilterData} from "../Pages/Client/ClientsManagement/ClientFilterFormModal";
import lodash from "lodash";
import {ClientsRequestResponse_Client} from "../../Services/APIServiceInterfaces/Client";
import {DateService} from "../../Services/DateService";
import {AscOrDesc, IItemOrder} from "../../Services/APIServiceInterfaces/IItemOrder";


export type PossibleClientItemOrderColNames = "companyName" | "firstName" | "lastName" | "location" | "postalCode" | "street" | "lastOrderDate" | "containersAtClientDate" | "containersAtClientContainers" | "containersAtClientSignedDate"
export interface ClientItemOrder extends IItemOrder {
  colName: PossibleClientItemOrderColNames,
}
interface Props {
  page?: number,
  clientFilter: ClientFilterData,
  innerRef: any,
}
interface _Props extends Props {
  navigation: any,
}
interface _State {
}
class _ClientsTableComponent extends BaseVMComponent<_Props, _State, any, ClientsTableComponentViewModel> {

  constructor(props: _Props) {
    super(props);

    this.state = {
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ClientsTableComponentViewModel(this.props.clientFilter)
  }

  async componentDidMount() {
    super.componentDidMount()

    this.viewModel.setPage(this.props.page!)
    this.viewModel.loadClientsDebounced()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<_State>, snapshot?: any) {
    if (
      prevProps.page !== this.props.page
    ) {
      this.viewModel.setPage(this.props.page!)
      this.viewModel.loadClientsDebounced()
    }

    if (!lodash.isEqual(prevProps.clientFilter, this.props.clientFilter)) {
      this.viewModel.setClientFilter(this.props.clientFilter)
      this.viewModel.loadClientsDebounced()
    }
  }

  async reloadClients() {
    return this.viewModel.loadClientsDebounced()
  }

  private onPageChange(page: number) {
    this.props.navigation("/admin/clients/" + page)
  }

  private onRowDetailsClick(rowIndex: number) {
    const clickedClient = this.viewModel.clients[rowIndex]
    this.props.navigation("/admin/clients/client/" + clickedClient.id)
  }

  private onColumnHeaderClick(
    headerKey: PossibleClientItemOrderColNames,
  ) {
    let ascOrDesc: AscOrDesc = "asc"
    if (this.viewModel.getItemOrder().colName === headerKey && this.viewModel.getItemOrder().ascOrDesc === ascOrDesc) {
      // change to desc
      ascOrDesc = "desc"
    }

    this.viewModel.setItemOrder({
      colName: headerKey,
      ascOrDesc: ascOrDesc,
    })
  }

  private getColumns(): TableColumn[] {
    return [
      {
        title: "Firmenname",
        valueKey: "companyName",
        onColumnHeaderClick: () => this.onColumnHeaderClick('companyName'),
      },
      {
        title: "Vorname",
        valueKey: "firstName",
        onColumnHeaderClick: () => this.onColumnHeaderClick('firstName'),
      },
      {
        title: "Nachname",
        valueKey: "lastName",
        onColumnHeaderClick: () => this.onColumnHeaderClick('lastName'),
      },
      {
        title: "Ort",
        valueKey: "location",
        resolveValue: (client: ClientsRequestResponse_Client) => {
          return client.address.location
        },
        onColumnHeaderClick: () => this.onColumnHeaderClick('location'),
      },
      {
        title: "PLZ",
        valueKey: "postalCode",
        resolveValue: (client: ClientsRequestResponse_Client) => {
          return client.address.postalCode
        },
        onColumnHeaderClick: () => this.onColumnHeaderClick('postalCode'),
      },
      {
        title: "Straße",
        valueKey: "street",
        resolveValue: (client: ClientsRequestResponse_Client) => {
          return client.address.street
        },
        onColumnHeaderClick: () => this.onColumnHeaderClick('street'),
      },
      {
        title: "letzte Bestellung",
        valueKey: "lastOrderDate",
        resolveValue: (client: ClientsRequestResponse_Client) => {
          if (client.lastOrderOfClient != null && client.lastOrderOfClient.date != null) {
            const dateString = DateService.getShortDateString(new Date(client.lastOrderOfClient.date), null)
            return `${dateString}`
          }
          return "-"
        },
        onColumnHeaderClick: () => this.onColumnHeaderClick('lastOrderDate'),
      },
      {
        title: "letzte Pfandbestätigung",
        valueKey: "containersAtClientDate",
        resolveValue: (client: ClientsRequestResponse_Client) => {
          if (client.containersAtClient != null) {
            const dateString = DateService.getShortDateString(
              new Date(client.containersAtClient.date),
              null,
              '2-digit',
              '2-digit',
              null,
            )
            return dateString
          }
          return "-"
        },
        onColumnHeaderClick: () => this.onColumnHeaderClick('containersAtClientDate'),
      },
      {
        title: "letzte Pfandbestätigung (unterschrieben)",
        valueKey: "containersAtClientSignedDate",
        resolveValue: (client: ClientsRequestResponse_Client) => {
          if (client.lastContainersAtClientSigned != null) {
            const dateString = DateService.getShortDateString(
              new Date(client.lastContainersAtClientSigned.date),
              null,
              '2-digit',
              '2-digit',
              null,
            )
            return dateString
          }
          return "-"
        },
        onColumnHeaderClick: () => this.onColumnHeaderClick('containersAtClientSignedDate'),
      },
      {
        title: "Pfand bestehend",
        valueKey: "containersAtClientContainers",
        resolveValue: (client: ClientsRequestResponse_Client) => {
          if (client.containersAtClient != null) {
            let numOfContainers = 0
            client.containersAtClient.containers.forEach(containerAtClient => {
              numOfContainers += containerAtClient.containerQuantity
            })
            return numOfContainers.toString()
          }
          return "-"
        },
        onColumnHeaderClick: () => this.onColumnHeaderClick('containersAtClientContainers'),
      },
      ActionButtonsCol,
    ]
  }

  render() {
    return (
      <>
        <TableComponent
          title={"Liste aller Mandanten"}
          columns={this.getColumns()}
          tableData={this.viewModel.clients}
          isLoading={this.viewModel.isLoading()}
          offset={this.viewModel.getRequestOffset()}
          limit={this.viewModel.getRequestLimit()}
          totalCount={this.viewModel.allClientsCount}
          pageNumber={this.props.page}
          onPageClick={this.onPageChange.bind(this)}
          onRowDetailsClick={(rowIndex, tableDataEntry) => {
            this.onRowDetailsClick(rowIndex)
          }}
          leftButtons={
            <Button
              variant={"default"}
              onClick={() => {
                this.viewModel.onExportClick()
              }}
            >
              Exportieren
            </Button>
          }
          itemOrder={this.viewModel.getItemOrder()}
        />
      </>
    )
  }
}


export default function ClientsTableComponent(props: Props) {
  const navigation = useNavigate();
  const { page } = useParams();

  return (
    <_ClientsTableComponent
      {...props}
      navigation={navigation}
      clientFilter={{...props.clientFilter}}
      page={parseInt(page || "1")}
      ref={props.innerRef}
    />
  )
}