import APIService, {APIError} from "./APIService";
import jwtDecode from "jwt-decode";

interface BaseAuthenticationService {
  // abstract logout(): void
}

export interface UserData {
  accessToken: string
  refreshToken: string
  name: string
  preferred_username: string
  email: string
  resource_access: {
    "flaschenwash-app": {
      roles: Array<string>
    }
  }
}

export default class AuthenticationService implements BaseAuthenticationService {
  private static instance: AuthenticationService|null = null
  static getInstance(): AuthenticationService {
    if (this.instance == null) {
      this.instance = new AuthenticationService()
    }
    return this.instance
  }

  getUserDataLocalStorageKey(): string {
    return "UserData"
  }

  async loginUser(
    username: string,
    password: string,
  ): Promise<{
    success: boolean,
    error: APIError|null,
    data: {
      access_token: string,
      refresh_token: string,
    }
  }> {
    try {
      const token = await APIService.getInstance().loginRequest(
        username,
        password
      )

      return {
        success: true,
        error: null,
        data: {
          access_token: token.access_token,
          refresh_token: token.refresh_token,
        }
      }
    } catch (e) {
      let err = e as APIError
      console.log(err)
      return {
        success: false,
        error: err,
        data: {
          access_token: '',
          refresh_token: '',
        }
      }
    }
  }

  getDataFromAccessToken(accessToken: string, refreshToken: string): UserData {
    let _userData: any = {}
    const decoded = jwtDecode(accessToken) as any

    (_userData as UserData).accessToken = accessToken;
    (_userData as UserData).refreshToken = refreshToken;
    if (decoded.name) {
      (_userData as UserData).name = decoded.name;
    }
    if (decoded.preferred_username) {
      (_userData as UserData).preferred_username = decoded.preferred_username;
    }
    if (decoded.email) {
      (_userData as UserData).email = decoded.email;
    }
    if (decoded.resource_access &&
      decoded.resource_access["flaschenwash-app"] &&
      decoded.resource_access["flaschenwash-app"].roles
    ) {
      (_userData as UserData).resource_access = {
        "flaschenwash-app": {
          roles: decoded.resource_access["flaschenwash-app"].roles
        }
      }
    }

    return _userData
  }

}