import React from 'react';
import TopNavbarComponent from "./Components/Layout/TopNavbarComponent";
import SideNavbarComponent from "./Components/Layout/SideNavbarComponent";
import FooterComponent from "./Components/Layout/FooterComponent";
import HomePageComponent from "./Components/Pages/HomePageComponent";
import {Navigate, Route, Routes} from "react-router-dom";
import ClientsManagementPageComponent from "./Components/Pages/Client/ClientsManagementPageComponent";
import ClientDetailsPage from "./Components/Pages/Client/ClientDetailsPage";
import OrdersManagementPageComponent from "./Components/Pages/OrdersManagementPageComponent";
import OrderDetailsPage from "./Components/Pages/OrderDetailsPage";
import ProductsManagementPageComponent from "./Components/Products/ProductsManagementPageComponent";
import CreateOrderPage from "./Components/Pages/CreateOrderPage";
import ContainersManagementPageComponent from "./Components/Containers/ContainersManagementPageComponent";
import CustomContainerConfirmationPageComponent from "./Components/Pages/CustomContainerConfirmationPageComponent";
import ContainersAtClientPage from "./Components/Pages/ContainersAtClientPage";
import ContainersAtClientDetailPage from "./Components/Pages/ContainersAtClientDetailPage";
import ClientOrdersPage from "./Components/Pages/Client/ClientOrdersPage";
import ClientDetailsPageWrapper from "./Components/Pages/Client/ClientDetailsPageWrapper";
import CompleteOrderPage from "./Components/Pages/CompleteOrderPage";


function App() {
  return (
    <div>
      <TopNavbarComponent />

      <SideNavbarComponent />

      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        <Routes>
          <Route path="/home" element={<HomePageComponent />} />

          <Route path="/clients" element={<Navigate to={"/admin/clients/1"} />} />
          <Route path="/clients/:page" element={<ClientsManagementPageComponent />} />
          <Route path="/clients/client/:clientId" element={<ClientDetailsPageWrapper />}>
            <Route path="" element={<ClientDetailsPage />} />
            <Route path="containers-at-client" element={<Navigate to={"1"} />} />
            <Route path="containers-at-client/:containersAtClientPage" element={<ContainersAtClientPage />} />
            <Route path="containers-at-client/details/:containersAtClientID" element={<ContainersAtClientDetailPage />} />
            <Route path="change-containers-at-client" element={<CustomContainerConfirmationPageComponent />} />
            <Route path="orders" element={<Navigate to={"1"} />} />
            <Route path="orders/:page" element={<ClientOrdersPage />} />
          </Route>

          <Route path="/orders" element={<Navigate to={"/admin/orders/1"} />} />
          <Route path="/orders/:page" element={<OrdersManagementPageComponent />} />
          <Route path="/orders/create-order" element={<CreateOrderPage />} />
          <Route path="/orders/order/:orderId" element={<OrderDetailsPage />} />
          <Route path="/orders/order/:orderId/complete" element={<CompleteOrderPage />} />

          <Route path="/products" element={<Navigate to={"/admin/products/1"} />} />
          <Route path="/products/:page" element={<ProductsManagementPageComponent />} />

          <Route path="/containers" element={<Navigate to={"/admin/containers/1"} />} />
          <Route path="/containers/:page" element={<ContainersManagementPageComponent />} />
        </Routes>
      </div>
      {/* /.content-wrapper */}

      {/* Control Sidebar */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* Control sidebar content goes here */}
        <div className="p-3">
          <h5>Title</h5>
          <p>Sidebar content</p>

          <button onClick={() => {
            // @ts-ignore
            methodDoesNotExist()
          }}>Crash App (used for testing)</button>
        </div>
      </aside>
      {/* /.control-sidebar */}

      <FooterComponent />
    </div>
  );
}

export default App;
