import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import Client from "../../Model/Client";
import ContainersAtClient from "../../Model/ContainersAtClient";


export default class ContainersAtClientTableViewModel extends BaseViewModel {

  clientId: number
  simpleTable: boolean
  containersAtClient: ContainersAtClient[]
  allElementsCount: number
  page: number

  constructor(clientId: number, page: number, simpleTable: boolean) {
    super();

    this.clientId = clientId
    this.page = page
    this.simpleTable = simpleTable
    this.containersAtClient = []
    this.allElementsCount = 0
    this.page = 1
  }

  async loadContainersAtClient() {
    this.enterLoadingProcess()
    this.updateView()

    const resp = await APIService.getInstance().getContainersAtClientOfClient({
      clientId: this.clientId,
      limit: this.getRequestLimit(),
      offset: this.getRequestOffset(),
      dateOrder: "DESC"
    })
    this.containersAtClient = resp.containersAtClient.containersAtClient
    this.allElementsCount = resp.containersAtClient.count

    this.leaveLoadingProcess()
    this.updateView()
  }

  getRequestOffset(): number {
    return this.getRequestLimit() * (this.page - 1)
  }

  getRequestLimit(): number {
    if (this.simpleTable) {
      return 3
    }
    return 20
  }

}