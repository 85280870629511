import {ClientError} from "graphql-request";
import LocalStorageService from "./LocalStorageService";
import AuthenticationService, {UserData} from "./AuthenticationService";
import {GQLHTTPProvider, IHTTPProvider} from "../Interfaces/HTTPProvider";
import {
  ClientRequestResponse,
  ClientsDataProvider,
  CreateClientRequestData,
  ClientsRequestResponse,
  CreateClientRequestResponse,
  UpdateClientData,
  UpdateClientResponse, ClientsFilter, ArchiveClientData, ArchiveClientResponse, ClientsRequestData,
} from "./APIServiceInterfaces/Client";
import {LoginRequestResponse, UserDataProvider} from "./APIServiceInterfaces/Login_User";
import { OrdersDataProvider } from "./APIServiceInterfaces/Order";
import {
  archiveClientDoc,
  clientDetailsDoc,
  clientsDoc,
  createClientDoc,
  updateClientDoc
} from "./APIServiceGQLDocs/ClientGQLDocs";
import {sendLoginDoc, sendRefreshTokenDoc} from "./APIServiceGQLDocs/AuthGQLDocs";
import {
  CreateProductRequestData,
  CreateProductRequestResponse,
  ProductsDataProvider,
  ProductsRequestResponse
} from "./APIServiceInterfaces/Product";
import {createProductDoc, getProductsDoc} from "./APIServiceGQLDocs/ProductGQLDocs";
import {
  ContainersRequestResponse,
  CreateContainerRequestData,
  CreateContainerRequestResponse
} from "./APIServiceInterfaces/Container";
import {createContainerDoc, getContainersDoc} from "./APIServiceGQLDocs/ContainerGQLDocs";
import {
  ContainersAtClientProvider,
  CreateContainersAtClientData,
  CreateContainersAtClientResp, GetAllContainersAtClientsResp, GetContainersAtClientData,
  GetContainersAtClientOfClientData,
  GetContainersAtClientOfClientResp,
  GetContainersAtClientResp
} from "./APIServiceInterfaces/ContainersAtClient";
import {
  CreateContainersAtClientDoc, GetAllContainersAtClientsDoc, GetContainersAtClientDoc, GetContainersAtClientOfClientDoc
} from "./APIServiceGQLDocs/ContainersAtClientGQLDocs";
import {getOrderStatusesDoc} from "./APIServiceGQLDocs/OrderStatusGQLDocs";
import {
  getOrderDetailDoc,
  OrderRequestResponse,
  OrderStatusRequestResponse
} from "./APIServiceGQLDocs/OrderDocs/GetOrderGQLDoc";
import {getOrdersDoc, OrdersRequestReqData, OrdersRequestResponse} from "./APIServiceGQLDocs/OrderDocs/GetOrdersGQLDoc";
import {
  createOrderDoc, CreateOrderRequestData, CreateOrderRequestResponse
} from "./APIServiceGQLDocs/OrderDocs/CreateOrderGQLDoc";
import {
  updateOrderDoc,UpdateOrderRequestData, UpdateOrderRequestResponse
} from "./APIServiceGQLDocs/OrderDocs/UpdateOrderGQLDoc";
import {
  MarkOrderAsDoneData, markOrderAsDoneDoc, MarkOrderAsDoneResponse
} from "./APIServiceGQLDocs/OrderDocs/MarkOrderAsDoneGQLDoc";


export class APIError extends Error {
  statusCode: number

  constructor(
    statusCode: number,
    message: string
  ) {
    super(message);

    this.statusCode = statusCode
  }
}


interface RequestOptions {
  headers?: any,
  overrideHeaders?: boolean,
  allowedToSendRefreshTokenRequest?: boolean,
}


export default class APIService implements UserDataProvider, ClientsDataProvider, OrdersDataProvider, ProductsDataProvider, ContainersAtClientProvider {

  private httpProvider: IHTTPProvider
  private static instance: APIService|null = null
  static getInstance(): APIService {
    if (this.instance == null) {
      this.instance = new APIService(
        new GQLHTTPProvider()
      )
    }
    return this.instance
  }

  private waitingForRefreshTokenResponse: boolean = false
  private refreshTokenRequest: Promise<any>|null = null
  private refreshTokenRequestLoading: boolean = false


  constructor(
    httpProvider: IHTTPProvider
  ) {
    this.httpProvider = httpProvider
  }

  private setUserDataUnsafely(userData: { accessToken: string, refreshToken: string }) {
    const key = AuthenticationService.getInstance().getUserDataLocalStorageKey()
    let _userData = AuthenticationService.getInstance().getDataFromAccessToken(
      userData.accessToken,
      userData.refreshToken
    )
    LocalStorageService.setItem(key, _userData as any)
  }

  private getUserDataUnsafely(): UserData|null {
    const key = AuthenticationService.getInstance().getUserDataLocalStorageKey()
    const value = LocalStorageService.getItem(key)
    if (value) {
      return value as UserData
    }
    return null
  }

  private deleteUserDataUnsafely() {
    const key = AuthenticationService.getInstance().getUserDataLocalStorageKey()
    LocalStorageService.removeItem(key)
  }

  private async performRequest(
    qlDoc: any,
    variables: any,
    options: RequestOptions = {}
  ): Promise<any> {
    try {
      // set headers
      let headers: any = {}
      if (options.overrideHeaders === true) {
        headers = options.headers || {}
      } else {
        const accessToken = this.getUserDataUnsafely()?.accessToken
        if (accessToken) {
          headers.authorization = "Bearer " + accessToken
        }
      }
      // noinspection UnnecessaryLocalVariableJS
      const response = await this.httpProvider.sendRequest(
        qlDoc,
        variables,
        headers
      )
      return response;
    } catch (e) {
      if (!(e instanceof ClientError)) {
        console.error("other error", e)
        throw new APIError(200, "other error")
      }

      // check if unauthorized error
      if (
        e.response.errors &&
        e.response.errors.length > 0 &&
        (e.response.errors[0] as any).statusCode === 401 &&
        (e.response.errors[0] as any).message === "UNAUTHORIZED"
      ) {

        if (options.allowedToSendRefreshTokenRequest === false) {
          // coming from refresh token request
          throw new APIError(200, "no auth error")
        }

        // get new refresh token
        if (!this.refreshTokenRequestLoading) {
          this.refreshTokenRequestLoading = true
          try {
            console.log("try to send refresh token")
            this.refreshTokenRequest = this.refreshToken()
            const refreshResp = await this.refreshTokenRequest
            if (
              refreshResp.access_token &&
              refreshResp.refresh_token
            ) {
              // set new token and retry request
              this.setUserDataUnsafely({
                accessToken: refreshResp.access_token,
                refreshToken: refreshResp.refresh_token
              })

              // resend request with new token
              options.allowedToSendRefreshTokenRequest = false
              this.refreshTokenRequestLoading = false
              return this.performRequest(
                qlDoc,
                variables,
                options,
              )
            }
          } catch (e) {
            console.error("refresh token request failed", e)
          }

          // log out
          this.deleteUserDataUnsafely()
          window.location.reload()
        } else {
          console.log("do not send refresh token request")

          if (this.refreshTokenRequest) {
            return this.refreshTokenRequest
              .then(_ => {
                return this.performRequest(
                  qlDoc,
                  variables,
                  options,
                )
              })
              .catch(e => {
                console.error("refreshTokenRequest failed", "error", e)
              })
          }
        }
      }

      // should not happen
      throw new APIError(200, "no auth error")
    }
  }

  /**
   * @throws APIError
   */
  async loginRequest(username: string, password: string): Promise<LoginRequestResponse> {
    const resp = await this.performRequest(sendLoginDoc(),
      {
        username: username,
        password: password
      }
    );
    if (resp == null || resp.login == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      access_token: resp.login.access_token,
      refresh_token: resp.login.refresh_token,
    };
  }

  /**
   * @throws APIError
   */
  async refreshToken(): Promise<LoginRequestResponse> {
    const refreshToken = this.getUserDataUnsafely()?.refreshToken
    if (!refreshToken) {
      return Promise.reject();
    }

    const resp = await this.performRequest(sendRefreshTokenDoc(),
      {
        refresh_token: refreshToken,
      },
      {
        headers: {
          refresh_token: refreshToken,
        },
        overrideHeaders: true,
        allowedToSendRefreshTokenRequest: false,
      }
    );
    if (resp == null || resp.refreshToken == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      access_token: resp.refreshToken.access_token,
      refresh_token: resp.refreshToken.refresh_token,
    };
  }

  /**
   * @throws APIError
   */
  async loadClientsRequest(
    data: ClientsRequestData,
    options: {
      withAddress?: boolean
    } = {}
  ): Promise<ClientsRequestResponse> {
    const resp = await this.performRequest(clientsDoc(options), data);
    if (resp == null || resp.clients == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      clients: resp.clients.clients,
      count: resp.clients.count,
    };
  }

  async loadClient(
    clientId: number,
  ): Promise<ClientRequestResponse> {
    const resp = await this.performRequest(clientDetailsDoc(),
      {
        id: clientId,
      }
    );
    if (resp == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      client: resp.client,
    };
  }

  /**
   * @throws APIError
   */
  async createClientRequest(data: CreateClientRequestData): Promise<CreateClientRequestResponse> {
    const resp = await this.performRequest(createClientDoc(),
      data
    );
    if (resp == null || resp.createClient == null) {
      throw new APIError(200, "request failed")
    }

    return {
      client: resp.createClient,
    };
  }

  async updateClientRequest(data: UpdateClientData): Promise<UpdateClientResponse> {
    const resp = await this.performRequest(updateClientDoc(),
      data
    );
    if (resp == null || resp.updateClient == null) {
      throw new APIError(200, "request failed")
    }

    return {
      client: resp.updateClient,
    };
  }

  /**
   * @throws APIError
   */
  async loadOrders(data: OrdersRequestReqData): Promise<OrdersRequestResponse> {
    const resp = await this.performRequest(getOrdersDoc(), data);
    if (resp == null || resp.orders == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      orders: resp.orders.orders,
      count: resp.orders.count,
    };
  }

  /**
   * @throws APIError
   */
  async loadOrder(orderId: number): Promise<OrderRequestResponse> {
    const resp = await this.performRequest(getOrderDetailDoc(),
      {
        id: orderId,
      }
    );
    if (resp == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      order: resp.order,
    };
  }

  /**
   * @throws APIError
   */
  async loadOrderStatusesRequest(): Promise<OrderStatusRequestResponse> {
    const resp = await this.performRequest(getOrderStatusesDoc(),
      {
      }
    );
    if (resp == null || resp.orderStatuses == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      orderStatuses: resp.orderStatuses.orderStatuses,
      count: resp.orderStatuses.count,
    };
  }

  /**
   * @throws APIError
   */
  async createOrderRequest(data: CreateOrderRequestData): Promise<CreateOrderRequestResponse> {
    const resp = await this.performRequest(createOrderDoc(),
      {
        clientId: data.clientId,
        orderStatusId: data.orderStatusId,
        date: data.date && data.date.toString(),
        notes: data.notes,
        products: data.products,
        containers: data.containers,
      }
    );
    if (resp == null || resp.createOrder == null) {
      throw new APIError(200, "request failed")
    }

    return {
      order: resp.createOrder,
    };
  }

  /**
   * @throws APIError
   */
  async updateOrderRequest(data: UpdateOrderRequestData): Promise<UpdateOrderRequestResponse> {
    const resp = await this.performRequest(updateOrderDoc(),
      {
        id: data.id,
        clientId: data.clientId,
        orderStatusId: data.orderStatusId,
        date: data.date && data.date.toString(),
        notes: data.notes,
        products: data.products,
        containers: data.containers,
      }
    );
    if (resp == null || resp.updateOrder == null) {
      throw new APIError(200, "request failed")
    }

    return {
      order: resp.updateOrder,
    };
  }

  /**
   * @throws APIError
   */
  async loadProducts(offset: number, limit: number): Promise<ProductsRequestResponse> {
    const resp = await this.performRequest(getProductsDoc(),
      {
        offset: offset,
        limit: limit,
      }
    );
    if (resp == null || resp.products == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      products: resp.products.products,
      count: resp.products.count,
    };
  }

  /**
   * @throws APIError
   */
  async createProduct(data: CreateProductRequestData): Promise<CreateProductRequestResponse> {
    const resp = await this.performRequest(createProductDoc(),
      {
        name: data.name,
        basePrice: data.basePrice,
        taxRate: data.taxRate,
      }
    );
    if (resp == null || resp.createProduct == null) {
      throw new APIError(200, "request failed")
    }

    return {
      product: resp.createProduct,
    };
  }

  /**
   * @throws APIError
   */
  async loadContainers(offset: number, limit: number): Promise<ContainersRequestResponse> {
    const resp = await this.performRequest(getContainersDoc(),
      {
        offset: offset,
        limit: limit,
      }
    );
    if (resp == null || resp.containers == null) {
      return Promise.reject(new APIError(200, "request failed"))
    }

    return {
      containers: resp.containers.containers,
      count: resp.containers.count,
    };
  }

  /**
   * @throws APIError
   */
  async createContainer(data: CreateContainerRequestData): Promise<CreateContainerRequestResponse> {
    const resp = await this.performRequest(createContainerDoc(), data);
    if (resp == null || resp.createContainer == null) {
      throw new APIError(200, "request failed")
    }

    return {
      container: resp.createContainer,
    };
  }

  /**
   * @throws APIError
   */
  async markOrderAsDone(data: MarkOrderAsDoneData): Promise<MarkOrderAsDoneResponse> {
    const resp = await this.performRequest(markOrderAsDoneDoc(), data);
    if (resp == null || resp.markOrderAsDone == null) {
      throw new APIError(200, "request failed")
    }

    return {
      order: resp.markOrderAsDone,
    };
  }

  async getContainersAtClient(data: GetContainersAtClientData): Promise<GetContainersAtClientResp> {
    const resp = await this.performRequest(GetContainersAtClientDoc(), data);
    if (resp == null || resp.containersAtClient == null) {
      throw new APIError(200, "request failed")
    }

    return resp.containersAtClient;
  }

  async getContainersAtClientOfClient(data: GetContainersAtClientOfClientData): Promise<GetContainersAtClientOfClientResp> {
    const resp = await this.performRequest(GetContainersAtClientOfClientDoc(), data);
    if (resp == null || resp.containersAtClientOfClient == null) {
      throw new APIError(200, "request failed")
    }

    return {
      containersAtClient: resp.containersAtClientOfClient,
    };
  }

  async createContainersAtClient(data: CreateContainersAtClientData): Promise<CreateContainersAtClientResp> {
    const resp = await this.performRequest(CreateContainersAtClientDoc(), data);
    if (resp == null || resp.createContainersAtClient == null) {
      throw new APIError(200, "request failed")
    }

    return {
      containersAtClient: resp.createContainersAtClient,
    };
  }

  async getAllContainersAtClients(): Promise<GetAllContainersAtClientsResp> {
    const resp = await this.performRequest(GetAllContainersAtClientsDoc(), null);
    if (resp == null || resp.allContainersAtClients == null) {
      throw new APIError(200, "request failed")
    }

    return {
      allContainersAtClients: resp.allContainersAtClients,
    };
  }

  openClientsExportUrl() {
    let exportUrl = GQLHTTPProvider.getAPIEndPoint() + "/export/client"
    exportUrl += `?token=${this.getUserDataUnsafely()?.accessToken}`
    window.open(exportUrl, "_blank")
  }

  async archiveClient(clientId: number): Promise<ArchiveClientResponse> {
    const data: ArchiveClientData = {
      id: clientId,
    }
    const resp = await this.performRequest(archiveClientDoc(), data);
    if (resp == null || resp.archiveClient == null) {
      throw new APIError(200, "request failed")
    }

    return resp.archiveClient;
  }

}