import React from "react";
import BaseVMComponent from "../BaseVMComponent";
import OrdersManagementPageViewModel from "../../ViewModels/Orders/OrdersManagementPageViewModel";
import AllOrdersTableComponent from "../Orders/AllOrdersTableComponent";
import {NavLink, useNavigate} from "react-router-dom";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import {Form, FormCheck, FormGroup, FormLabel} from "react-bootstrap";


interface State {
}
class OrdersManagementPageComponent extends BaseVMComponent<any, State, any, OrdersManagementPageViewModel> {

  private readonly tableRef: any
  state: State = {
    showDone: false,
  }


  constructor(props: any) {
    super(props);

    this.tableRef = React.createRef();
  }

  protected initViewModel(): void {
    this.viewModel = new OrdersManagementPageViewModel()
  }

  private onSuccessfullySaved() {
    (this.tableRef.current as any).reloadOrders()
  }

  render(): JSX.Element {
    return (
      <div className="content-header">
        <div className="container-fluid">
          <div className="row m-2">
            <div className="col-md-12">
              <div className="App">
                <header className="App-header">
                  <div className={"row"}>
                    <div className={"col-md-8"}>
                      <h2>Auftragsverwaltung</h2>
                    </div>
                    <div className={"col-md-4 d-flex justify-content-end"}>
                      <NavLink
                        to={"/admin/orders/create-order"}
                      >
                        <button
                          className={"btn btn-primary"}
                        >
                          Neuen Auftrag erstellen
                        </button>
                      </NavLink>
                    </div>
                  </div>

                  <div className="mt-4">
                    <AllOrdersTableComponent
                      innerRef={this.tableRef}
                    />
                  </div>
                </header>
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
    )
  }
}

export default OrdersManagementPageComponent;