const unknownErrorString = "Ein Fehler ist aufgetreten"

const Errors: {[key: string]: { text: string }} = {
  FALSE_PARAMETERS: {
    text: 'Falsche Parameter gesendet',
  },
  INTERNAL_SERVER_ERROR: {
    text: 'Ein Server-Fehler ist aufgetreten',
  },
  JSON_DECODE_ERROR: {
    text: 'Ein Decoding-Fehler ist aufgetreten',
  },
  UNAUTHORIZED: {
    text: 'Sie sind nicht berechtigt diese Resource zu laden',
  },
  FALSE_LOGIN_CREDENTIALS: {
    text: 'Falsche Login-Daten gesendet',
  },
}

function getErrorTextByError(error?: string): string {
  if (error != null && Errors[error] != null) {
    return Errors[error].text
  }

  return unknownErrorString
}


export {
  unknownErrorString,
  Errors,
  getErrorTextByError,
}