import React, {Fragment} from "react";
import BaseVMComponent from "../BaseVMComponent";
import {NavigateFunction, NavLink, useNavigate, useParams} from "react-router-dom";
import PageWrapper from "../Shared/PageWrapper";
import CompleteOrderPageViewModel from "../../ViewModels/Pages/CompleteOrderPageViewModel";
import OrderBill from "../Orders/CompleteOrder/OrderBill";
import CompleteOrder from "../Orders/CompleteOrder/CompleteOrder";
import ConfirmOrderContainers from "../Orders/CompleteOrder/ConfirmOrderContainers";
import BreadCrumb from "../Shared/BreadCrumb";
import OrderContainerDetailsRow from "../Shared/OrderContainerDetailsRow";


interface _Props extends Props {
  navigation: NavigateFunction,
}
interface _State {
  activeStep: 'returnContainers' | 'completeOrder' | 'confirmContainers',
}
class _CompleteOrderPage extends BaseVMComponent<_Props, _State, any, CompleteOrderPageViewModel> {

  constructor(props: _Props) {
    super(props);

    this.state = {
      activeStep: 'returnContainers',
    }
  }

  protected initViewModel(): void {
    this.viewModel = new CompleteOrderPageViewModel(
      this.props.orderId
    )
  }

  private getBreadcrumbs(): JSX.Element[] {
    let elements: JSX.Element[] = [
      <li className="breadcrumb-item">
        <NavLink to={`/admin/orders`}>
          Aufträge
        </NavLink>
      </li>,
      <li className="breadcrumb-item">
        <NavLink to={`/admin/orders/order/${this.props.orderId}`}>
          Auftragsdetails
        </NavLink>
      </li>
    ]

    if (
      this.state.activeStep === 'returnContainers'
      || this.state.activeStep === 'completeOrder'
      || this.state.activeStep === 'confirmContainers'
    ) {
      elements.push(
        <BreadCrumb
          active={this.state.activeStep === 'returnContainers'}
          contentText={"Auftrag abschließen - Auftragsumfang"}
          onClick={() => this.setState({activeStep: 'returnContainers'})}
        />
      )
    }

    if (
      this.state.activeStep === 'completeOrder'
      || this.state.activeStep === 'confirmContainers'
    ) {
      elements.push(
        <BreadCrumb
          active={this.state.activeStep === 'completeOrder'}
          contentText={"Auftrag abschließen"}
          onClick={() => this.setState({activeStep: 'completeOrder'})}
        />
      )
    }

    if (
      this.state.activeStep === 'confirmContainers'
    ) {
      elements.push(
        <BreadCrumb
          active={true}
          contentText={"Leergut bestätigen"}
        />
      )
    }

    return elements
  }

  render(): JSX.Element {
    return (
      <PageWrapper
        header={
          <div className={"row mb-3"}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                { this.getBreadcrumbs() }
              </ol>
            </nav>
          </div>
        }
      >
        <div>
          <h2>Auftrag abschließen - Auftragsumfang</h2>

          <div className={"mt-4"}>
            { !this.viewModel.isLoading() && this.viewModel.order != null &&
              <Fragment>
                { this.state.activeStep === 'returnContainers' &&
                  <OrderBill
                    orderId={this.props.orderId}
                    order={this.viewModel.order}
                    containersAtClient={this.viewModel.order.client.containersAtClient}
                    containersToReturn={this.viewModel.containersToReturn}
                    onGoAheadClick={(containersToReturn) => {
                      this.viewModel.containersToReturn = containersToReturn
                      this.setState({
                        activeStep: 'completeOrder',
                      })
                    }}
                  />
                }

                { this.state.activeStep === 'completeOrder' &&
                  <CompleteOrder
                    order={this.viewModel.order}
                    containersToConfirm={this.viewModel.getContainersToConfirm()}
                    onContinueWithoutConfirmationClick={() => {
                      this.props.navigation(`/admin/orders/order/${this.props.orderId}`)
                    }}
                    onGoToConfirmationClick={() => {
                      this.setState({
                        activeStep: 'confirmContainers',
                      })
                    }}
                  />
                }

                { this.state.activeStep === 'confirmContainers' && this.viewModel.containersToReturn != null &&
                  <ConfirmOrderContainers
                    order={this.viewModel.order}
                    containersToConfirm={this.viewModel.getContainersToConfirm()}
                    onCompleteClick={() => {
                      this.props.navigation(`/admin/orders/order/${this.props.orderId}`)
                    }}
                  />
                }
              </Fragment>
            }
          </div>
        </div>
      </PageWrapper>
    )
  }
}


interface Props {
  orderId: number,
}
const CompleteOrderPage: any = (props: Props) => {
  const navigation = useNavigate();
  const { orderId } = useParams();

  return (
    <_CompleteOrderPage
      {...props}
      orderId={parseInt(orderId!)}
      navigation={navigation}
    />
  )
}


export default CompleteOrderPage;