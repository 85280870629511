import BaseVMComponent from "../BaseVMComponent";
import AuthenticationService from "../../Services/AuthenticationService";
import React, {StyleHTMLAttributes} from "react";
import {Link} from "react-router-dom";
import LoginPageViewModel from "../../ViewModels/LoginPageViewModel";
import {SetUserDataFunc, useAuth} from "../../Context/AuthContext";
import {Errors, getErrorTextByError, unknownErrorString} from "../../global/Error";


interface LoginPageComponentProps {
  setUserData: SetUserDataFunc,
}
interface LoginPageComponentState {
  username: string,
  password: string,
  loginError: {
    visible: boolean,
    text: string|null,
  }
}
class _LoginPageComponent extends BaseVMComponent<
  LoginPageComponentProps,
  LoginPageComponentState,
  any,
  LoginPageViewModel
> {

  constructor(props: LoginPageComponentProps) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loginError: {
        visible: false,
        text: null,
      }
    }
  }

  componentDidMount() {
    document.getElementsByTagName("body")[0].classList.add("login-page")
  }

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].classList.remove("login-page")
  }

  protected initViewModel(): void {
    this.viewModel = new LoginPageViewModel()
  }

  private onLoginClick() {
    this.setState({
      loginError: {
        visible: false,
        text: null,
      }
    })

    this.viewModel.onLoginClick(
      this.state.username,
      this.state.password,
      this.props.setUserData
    )
      .then(res => {
        if (!res.success) {
          this.setState({
            loginError: {
              visible: true,
              text: getErrorTextByError(res.error?.message),
            }
          })
        }
      })
  }

  private submitButtonIsDisabled(): boolean {
    return !this.state.username || !this.state.password
  }

  render() {
    return (
      <div className="login-box" style={styles.loginBox}>
        <div className="login-logo">
          <b>RBQ</b>-CRM
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Login für <b>Manager</b> und <b>Zusteller</b></p>

            <form onSubmit={event => event.preventDefault()}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  onChange={event => {
                    if (!event) {
                      return
                    }

                    this.setState({
                      username: event.target.value
                    })
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={event => {
                    if (!event) {
                      return
                    }

                    this.setState({
                      password: event.target.value
                    })
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={this.submitButtonIsDisabled()}
                    onClick={this.onLoginClick.bind(this)}
                  >
                    Anmelden
                  </button>
                </div>
              </div>

              { this.state.loginError.visible &&
                <div className="alert alert-danger mt-4" role="alert">
                  {this.state.loginError.text || unknownErrorString}
                </div>
              }
            </form>
          </div>
        </div>
      </div>
    );
  }

}


const styles: {[key: string]: {[key: string]: string}} = {
  loginBox: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }
};

export default function LoginPageComponent() {
  const { setUserData } = useAuth();

  return (
    <_LoginPageComponent
      setUserData={setUserData}
    />
  )
}