import React, {Component, CSSProperties, Fragment} from "react";


export interface InputComponentProps {
  labelTitle: string,
  hideLabel?: boolean,
  type: "text"|"email"|"tel"|"number"|"textarea",
  numberProps?: {
    min?: number,
    max?: number,
  }
  value: string | null,
  onValueChange?: (value: string) => void,
  onClick?: (event: MouseEvent) => void,
  onBlur?: (value: string) => void,
  onClearIconClick?: () => void,
  showClearIcon?: boolean,
  disabled?: boolean,
  required?: boolean,
  inlined?: boolean,
  style?: CSSProperties,
  labelStyle?: CSSProperties,
  inputStyle?: CSSProperties,
  autoComplete?: "off"|"on", // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  stepSize?: number,
}
interface State {
  hasError: boolean
}
export class InputComponent extends Component<InputComponentProps, State> {
  readonly colSizeLabel = 4

  state: State = {
    hasError: false,
  }


  private getFormGroupClassName(): string {
    let classNames: string[] = ["form-group"]
    if (this.props.inlined) {
      classNames.push("row")
    }
    return classNames.join(" ")
  }

  private getLabelClassName(): string {
    let classNames: string[] = []
    if (this.props.inlined) {
      classNames.push(`col-${this.colSizeLabel}`)
    }
    return classNames.join(" ")
  }

  private getInputClassName(): string {
    let classNames: string[] = ["form-control"]
    if (this.props.inlined) {
      if (!this.props.hideLabel) {
        classNames.push(`col-${12 - this.colSizeLabel}`)
      } else {
        classNames.push(`col-12`)
      }
    }
    return classNames.join(" ")
  }

  private getInputId(): string {
    return this.props.labelTitle + "-" + this.props.type
  }

  private getLabelTitle(): JSX.Element {
    let labelTitle = this.props.labelTitle

    if (!this.props.required || this.props.disabled) {
      return (
        <Fragment>
          {labelTitle}
        </Fragment>
      )
    }

    return (
      <Fragment>
        {labelTitle}
        <span style={style.requiredStar}>*</span> <span style={style.requiredString}>(Pflichtfeld)</span>
      </Fragment>
    )
  }

  private getInputStyle(): any {
    let style: CSSProperties = {...this.props.inputStyle}
    if (this.state.hasError) {
      style.border = "1px solid red"
    }
    return style
  }

  private getTextAreaInputStyle(): any {
    let style: CSSProperties = {...this.props.inputStyle}
    style.resize = "none"
    return style
  }

  private getInputField() {
    let inputFieldEl: JSX.Element = (
      <input
        id={this.getInputId()}
        className={this.getInputClassName()}
        type={this.props.type}
        placeholder={this.props.labelTitle}
        value={this.props.value || ""}
        disabled={this.props.disabled}
        required={this.props.required}
        style={this.getInputStyle()}
        autoComplete={this.props.autoComplete}
        onChange={(event) => {
          this.setState({
            hasError: false
          })

          if (this.props.onValueChange != null) {
            this.props.onValueChange(event.target.value)
          }
        }}
        onClick={(event) => {
          if (this.props.onClick != null) {
            this.props.onClick(event as any)
          }
        }}
        onBlur={(event) => {
          if (this.props.onBlur != null) {
            this.props.onBlur(event.target.value)
          }
        }}
        step={this.props.stepSize}
        onInvalid={() => {
          this.setState({
            hasError: true
          })
        }}
        min={this.props.numberProps?.min}
        max={this.props.numberProps?.max}
      />
    )

    if (this.props.type === "textarea") {
      inputFieldEl = (
        <textarea
          id={this.getInputId()}
          className={this.getInputClassName()}
          placeholder={this.props.labelTitle}
          value={this.props.value || ""}
          disabled={this.props.disabled}
          required={this.props.required}
          style={this.getTextAreaInputStyle()}
          autoComplete={this.props.autoComplete}
          onChange={(event) => {
            if (this.props.onValueChange != null) {
              this.props.onValueChange(event.target.value)
            }
          }}
          onBlur={(event) => {
            if (this.props.onBlur != null) {
              this.props.onBlur(event.target.value)
            }
          }}
          rows={5}
        ></textarea>
      )
    }

    let clearButtonStyle: CSSProperties = {...style.clearButtonStyle}
    if (this.props.disabled !== true) {
      clearButtonStyle.cursor = "pointer"
    }

    return (
      <div style={{ position: "relative" }}>
        {inputFieldEl}

        { this.props.disabled !== true && this.props.showClearIcon &&
          <div
            style={clearButtonStyle}
            onClick={() => {
              if (this.props.disabled !== true && this.props.onClearIconClick) {
                this.props.onClearIconClick()
              }
            }}
          >
            <img
              src={"/assets/clear-svgrepo.svg"}
              alt={"clear"}
              style={style.clearButtonIcon}
            />
          </div>
        }
      </div>
    )
  }

  render() {
    return (
      <div
        className={this.getFormGroupClassName()}
        style={this.props.style}
      >
        { !this.props.hideLabel &&
          <label
            className={this.getLabelClassName()}
            style={this.props.labelStyle}
            htmlFor={this.getInputId()}
          >
            {this.getLabelTitle()}
          </label>
        }

        { this.getInputField() }
      </div>
    );
  }
}


const style: {[key: string]: CSSProperties} = {
  requiredStar: {
    color: "red",
    fontWeight: "normal",
  },
  requiredString: {
    color: "gray",
    fontWeight: "normal",
  },
  clearButtonStyle: {
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
    display: "flex",
  },
  clearButtonIcon: {
    height: 20,
  },
}