import BaseViewModel from "../ViewModels/BaseViewModel";
import React from "react";


export default abstract class BaseVMComponent<P, S, SS, TVM extends BaseViewModel> extends React.Component<P, S, SS> {

  protected viewModel!: TVM


  constructor(props: P) {
    super(props);

    this.setupViewModel()
  }

  componentDidMount() {
    this.viewModel.viewDidMount()
  }

  private setupViewModel() {
    this.initViewModel()
    if (this.viewModel == null) {
      throw "View Model is not setup"
    }

    this.viewModel.setUpdateViewCallback(this.onUpdateView.bind(this))
  }

  protected onUpdateView() {
    this.forceUpdate()
  }

  protected abstract initViewModel(): void

}