import BaseFormViewModel from "../BaseFormViewModel";
import {CreateContainerRequestData} from "../../Services/APIServiceInterfaces/Container";


export default class ContainerFormViewModel extends BaseFormViewModel<CreateContainerRequestData> {

  private name: string|null = null
  private nettoPrice: number|null = null
  private taxRate: number|null = null


  viewDidMount() {
    super.viewDidMount();
  }

  setName(value: string) {
    this.name = value
    this.updateView()
  }

  setNettoPrice(value: string) {
    this.nettoPrice = parseFloat(value)
    this.updateView()
  }

  setTaxRate(value: string) {
    this.taxRate = parseFloat(value)
    this.updateView()
  }

  getName(): string {
    if (this.name) {
      return this.name
    }
    return ""
  }

  getNettoPrice(): string {
    if (this.nettoPrice != null) {
      return this.nettoPrice.toString()
    }
    return ""
  }

  getTaxRate(): string {
    if (this.taxRate != null) {
      return this.taxRate.toString()
    }
    return ""
  }

  getBruttoPrice(): string {
    if (this.nettoPrice != null && this.taxRate != null) {
      return (this.nettoPrice * (1 + this.taxRate / 100)).toFixed(2)
    }
    return "0"
  }

  formDataIsValid(): boolean {
    if (
      !this.name ||
      this.nettoPrice == null ||
      this.taxRate == null
    ) {
      return false
    }

    return true
  }

  getCreateRequestData(): CreateContainerRequestData {
    return {
      name: this.name!,
      basePrice: this.nettoPrice!,
      taxRate: this.taxRate! / 100,
    }
  }

}