import {Component, CSSProperties} from "react";
import FloatService from "../../Services/FloatService";
import OrderProduct from "../../Model/OrderProduct";
import OrderContainer from "../../Model/OrderContainer";
import {AddContainersContainer} from "../../ViewModels/Containers/AddContainersFormViewModel";


interface Props {
  orderProducts: OrderProduct[],
  orderContainers: OrderContainer[],
  returnedContainers: AddContainersContainer[],
}
interface State {

}
export default class OrderBillCalculation extends Component<Props, State> {

  render() {
    let rows: CalcRowProps[] = []

    // price of products
    let totalPrice = 0
    rows.push({
      title: "Preis der Lieferung",
      value: "",
      isTitleRow: true,
    })
    this.props.orderProducts.forEach(product => {
      // ignore 0 quantity
      if (product.productQuantity === 0) {
        return
      }

      let singlePrice: number = product.isFreeProduct ? 0 : product.productBruttoPrice
      let title: string = product.productQuantity + "x " + product.productName + " à € " + FloatService.toGermanCurrencyValueString(singlePrice)
      let price: number = singlePrice * product.productQuantity

      totalPrice += price

      rows.push({
        title: title,
        value: "€ " + FloatService.toGermanCurrencyValueString(price),
      })
    })
    rows.push({
      title: "Preis der Lieferung ohne Pfand",
      value: "€ " + FloatService.toGermanCurrencyValueString(totalPrice),
      isSumRow: true,
    })

    // price of containers
    rows.push({
      title: "Pfand",
      value: "",
      isTitleRow: true,
    })
    this.props.orderContainers.forEach(container => {
      // ignore 0 quantity
      if (container.containerQuantity === 0) {
        return
      }

      let singlePrice: number = container.containerBruttoPrice
      let title: string = container.containerQuantity + "x " + container.containerName + " à € " + FloatService.toGermanCurrencyValueString(singlePrice)
      let price: number = singlePrice * container.containerQuantity

      totalPrice += price

      rows.push({
        title: title,
        value: "€ " + FloatService.toGermanCurrencyValueString(price),
      })
    })
    rows.push({
      title: "Preis der Lieferung mit Pfand",
      value: "€ " + FloatService.toGermanCurrencyValueString(totalPrice),
      isSumRow: true,
    })

    // return of containers
    rows.push({
      title: "Pfandrückgabe",
      value: "",
      isTitleRow: true,
    })
    this.props.returnedContainers.forEach(container => {
      if (!container.valid || container.orderContainersInput.quantity === 0) {
        return
      }
      let singlePrice: number = container.orderContainersInput.bruttoPrice
      let title: string = container.orderContainersInput.quantity + "x " + container.container?.name + " à € " + FloatService.toGermanCurrencyValueString(singlePrice)
      let price: number = singlePrice * container.orderContainersInput.quantity

      totalPrice -= price

      rows.push({
        title: title,
        value: "- € " + FloatService.toGermanCurrencyValueString(price),
      })
    })
    rows.push({
      title: "Gesamtpreis",
      value: "€ " + FloatService.toGermanCurrencyValueString(totalPrice),
      isAbsoluteSumRow: true,
    })

    return (
      <div className={"order-bill-calculation"}>
        <Calc
          rows={rows}
        />
      </div>
    );
  }
}


interface CalcProps {
  rows: CalcRowProps[],
}
class Calc extends Component<CalcProps, {}> {
  render() {
    let rowElements: JSX.Element[] = []
    this.props.rows.forEach(row => {
      rowElements.push(
        <CalcRow
          title={row.title}
          isTitleRow={row.isTitleRow}
          isSumRow={row.isSumRow}
          isAbsoluteSumRow={row.isAbsoluteSumRow}
          value={row.value}
        />
      )
    })

    return (
      <div className={"calc"}>
        {rowElements}
      </div>
    );
  }
}

interface CalcRowProps {
  title: string,
  value: string,
  isTitleRow?: boolean,
  isSumRow?: boolean,
  isAbsoluteSumRow?: boolean,
}
class CalcRow extends Component<CalcRowProps, {}> {
  render() {
    return (
      <div
        style={styles.grid(this.props.isTitleRow, this.props.isSumRow || this.props.isAbsoluteSumRow)}
      >
        <div style={styles.gridCol}>
          <span
            style={styles.rowTitle(this.props.isTitleRow, this.props.isSumRow, this.props.isAbsoluteSumRow)}
          >{this.props.title}</span>
        </div>
        <div
          style={styles.gridCol}
        >
          <span
            style={styles.rowValue(this.props.isTitleRow, this.props.isSumRow, this.props.isAbsoluteSumRow)}
          >{this.props.value}</span>
        </div>
      </div>
    );
  }
}


const styles = {
  grid: (isTitleRow: boolean = false, isSumRow: boolean = false): CSSProperties => {
    let styles: CSSProperties = {
      display: "grid",
      gridTemplateColumns: "50% 50%",
      marginLeft: 10,
    }
    if (isTitleRow) {
      styles.marginLeft = 0
      styles.marginTop = 10
    } else if (isSumRow) {
      styles.borderTop = "1px solid black"
      styles.marginTop = "0.5rem"
      styles.paddingTop = "0.5rem"
    }
    return styles
  },
  gridCol: {
  } as CSSProperties,
  calcTitle: {
    fontWeight: "bold",
  } as CSSProperties,
  rowTitle: (isTitleRow: boolean = false, isSumRow: boolean = false, isAbsoluteSumRow: boolean = false) => {
    let styles: CSSProperties = {
      display: "block",
      fontSize: "1.1rem",
    }
    if (isTitleRow || isAbsoluteSumRow) {
      styles.fontWeight = "bold"
    } else if (isSumRow) {
      styles.fontStyle = "italic"
    }
    return styles
  },
  rowValue: (isTitleRow: boolean = false, isSumRow: boolean = false, isAbsoluteSumRow: boolean = false) => {
    let styles: CSSProperties = {
      display: "block",
      textAlign: "right",
      fontSize: "1.1rem",
    }
    if (isTitleRow || isAbsoluteSumRow) {
      styles.fontWeight = "bold"
    } else if (isSumRow) {
      styles.fontStyle = "italic"
    }
    return styles
  },
}