import React from "react";
import BaseVMComponent from "../BaseVMComponent";
import {NavLink, useParams} from "react-router-dom";
import ContainersAtClientDetailPageViewModel from "../../ViewModels/Pages/ContainersAtClientDetailPageViewModel";
import LoadingComponent from "../Shared/LoadingComponent";
import ContainersAtClientComponent from "../Shared/ContainersAtClientComponent";
import {GQLHTTPProvider} from "../../Interfaces/HTTPProvider";


interface Props {
  clientId: number,
  containersAtClientID: number,
}
interface State {
}
class _ContainersAtClientDetailPage extends BaseVMComponent<Props, State, any, ContainersAtClientDetailPageViewModel> {

  constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ContainersAtClientDetailPageViewModel(
      this.props.clientId,
      this.props.containersAtClientID
    )
  }

  render(): JSX.Element {
    return (
      <div className={"mt-4"}>
        <div className={"mb-3"}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to={`/admin/clients/client/${this.props.clientId}`}>
                  Mandantendetails
                </NavLink>
              </li>
              <li className="breadcrumb-item">
                <NavLink to={`/admin/clients/client/${this.props.clientId}/containers-at-client`}>
                  Letzte Pfandbestätigungen
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Gebinde bei Kunde (ID: {this.props.containersAtClientID})</li>
            </ol>
          </nav>
        </div>

        <div className={"row"}>
          <div className={"col-12"}>
            <h3>Gebinde bei Kunde (ID: {this.props.containersAtClientID})</h3>
          </div>
        </div>

        <div className="mt-4">
          <LoadingComponent shown={this.viewModel.loadingIt !== 0} />

          { !this.viewModel.loadingIt && this.viewModel.containersAtClient != null &&
            <div className={"row"}>
              <div className={"col-md-6"}>
                <h4>Gebinde bei Kunde</h4>
                <ContainersAtClientComponent
                  containersAtClient={this.viewModel.containersAtClient}
                />
              </div>

              <div className={"col-md-6"}>
                <h4>Signatur</h4>
                {this.viewModel.containersAtClient.signatureSrcURL == null &&
                  <p style={{ opacity: 0.66 }}>Keine Pfandbestätigung</p>
                }
                {this.viewModel.containersAtClient.signatureSrcURL != null &&
                  <img src={GQLHTTPProvider.getAPIEndPoint() + "/" + this.viewModel.containersAtClient.signatureSrcURL} />
                }
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}


const ContainersAtClientDetailPage: any = (props: Props) => {
  const { clientId, containersAtClientID } = useParams();

  return (
    <_ContainersAtClientDetailPage
      {...props}
      clientId={parseInt(clientId!)}
      containersAtClientID={parseInt(containersAtClientID!)}
    />
  )
}


export default ContainersAtClientDetailPage;