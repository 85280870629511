import React from "react";
import ConfigService from "../../Services/ConfigService";

function FooterComponent(): JSX.Element {

  const appVersion = ConfigService.getAppVersion()


  return (
    <footer className="main-footer">
      {/* To the right */}
      <div className="float-right d-none d-sm-inline">
        Version: {appVersion}
      </div>
      {/* Default to the left */}
      <strong>Copyright &copy; 2023 Rosenbergquelle Betriebsges.m.b.H.</strong> All rights reserved.
    </footer>
  )
}

export default FooterComponent;