import React, {Component} from "react";
import {DropDownOption} from "../../Shared/DropDownComponent";
import Container from "../../../Model/Container";
import ContainerInputRow from "../../Shared/ContainerInputRow";


interface Props {
  selectableContainers: Container[],
  onContainerSelectionChange: (product: Container) => void,
  selectedContainer: Container | null,
  quantity: number,
  onQuantityChange: (quantity: number) => void,
  price: number,
  onPriceChange: (price: number) => void,
  inputDisabled: boolean,
}
interface State {

}
export default class AddNewOrderContainerInputRow extends Component<Props, State> {
  private getContainerOptions(): DropDownOption[] {
    let options: DropDownOption[] = []
    if (this.props.selectableContainers) {
      this.props.selectableContainers.forEach(container => {
        options.push({
          label: container.name,
          value: container.id,
        })
      })
    }
    return options
  }

  private additionInputsDisabled(): boolean {
    return this.props.inputDisabled || this.props.quantity === 0
  }

  render() {
    const container = this.props.selectedContainer

    return (
      <ContainerInputRow
        id={""}
        containerSelect={{
          idValue: container?.id || null,
          options: this.getContainerOptions(),
          onValueChange: (value) => {
            let container = this.props.selectableContainers.find(el => el.id === value)
            this.props.onContainerSelectionChange(container!)
          },
          inputDisabled: this.props.inputDisabled,
        }}
        quantityInput={{
          value: this.props.quantity,
          onChange: (value) => {
            this.props.onQuantityChange(value)
          },
          inputDisabled: this.props.inputDisabled,
        }}
        priceInput={{
          value: this.props.price,
          onChange: (value) => {
            this.props.onPriceChange(value)
          },
          inputDisabled: this.additionInputsDisabled(),
        }}
        normalPrice={{
          value: this.props.selectedContainer?.bruttoPrice,
        }}
      />
    )
  }
}