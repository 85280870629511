import {Component} from "react";


interface Props {
  header: JSX.Element,
  children: JSX.Element,
}
export default class PageWrapper extends Component<Props, {}> {

  render() {
    return (
      <div className="content-header">
        <div className="container-fluid">
          <div className="row m-2">
            <div className="col-sm-12">
              <div className="App">
                <header className="App-header">
                  {this.props.header}
                </header>

                <div className="mt-4">
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}
