import APIService from "../../Services/APIService";
import Container from "../../Model/Container";
import {OrderContainersInput} from "../../Services/APIServiceGQLDocs/OrderContainersDocs";
import BaseViewModel from "../BaseViewModel";


export interface AddContainersContainer {
  orderContainersInput: OrderContainersInput,
  container: Container | null,
  valid: boolean,
}
export default class AddContainersFormViewModel extends BaseViewModel {

  private containers: Container[] = []
  private newOrderContainersToAdd: AddContainersContainer[] = []


  constructor(
    initialContainers?: AddContainersContainer[],
  ) {
    super();

    if (initialContainers != null) {
      this.newOrderContainersToAdd = initialContainers
    }
  }

  viewDidMount() {
    super.viewDidMount();

    this.loadContainers()
  }

  private async loadContainers(): Promise<void> {
    const resp = await APIService.getInstance().loadContainers(
      0,
      50,
    )
    this.containers = resp.containers
    this.containers.forEach(container => {
      if (this.newOrderContainersToAdd.find(el => el.orderContainersInput.containerId === container.id) != null) {
        return
      }

      this.newOrderContainersToAdd.push({
        orderContainersInput: {
          containerId: container.id,
          quantity: 0,
          bruttoPrice: container.bruttoPrice,
        },
        valid: true,
        container: container,
      })
    })

    this.updateView()
  }

  addNewOrderContainersToAdd() {
    this.newOrderContainersToAdd.push({
      orderContainersInput: {
        containerId: "",
        quantity: 0,
        bruttoPrice: 0,
      },
      valid: false,
      container: null,
    })
    this.updateView()
  }

  getContainers(): Container[] {
    return this.containers
  }

  getNewOrderContainersToAdd(): AddContainersContainer[] {
    return this.newOrderContainersToAdd
  }

  setContainerIdOfNewOrderProductsToAdd(index: number, container: Container) {
    this.newOrderContainersToAdd[index].orderContainersInput.containerId = container.id
    this.newOrderContainersToAdd[index].orderContainersInput.bruttoPrice = container.bruttoPrice
    this.newOrderContainersToAdd[index].container = container
    this.newOrderContainersToAdd[index].valid = true
    this.updateView()
  }

  setQuantityOfNewOrderContainersToAdd(index: number, quantity: number) {
    this.newOrderContainersToAdd[index].orderContainersInput.quantity = quantity
    this.updateView()
  }

  setPriceOfNewOrderContainersToAdd(index: number, price: number) {
    this.newOrderContainersToAdd[index].orderContainersInput.bruttoPrice = price
    this.updateView()
  }

  getQuantityOfNewOrderContainersToAdd(index: number): number {
    return this.newOrderContainersToAdd[index].orderContainersInput.quantity
  }

  getPriceOfNewOrderContainersToAdd(index: number): number {
    return this.newOrderContainersToAdd[index].orderContainersInput.bruttoPrice
  }

}