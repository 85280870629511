import BaseViewModel from "./BaseViewModel";
import AuthenticationService from "../Services/AuthenticationService";
import {SetUserDataFunc} from "../Context/AuthContext";

export default class LoginPageViewModel extends BaseViewModel {

  async onLoginClick(
    username: string,
    password: string,
    setUserData: SetUserDataFunc
  ) {
    const res = await AuthenticationService.getInstance().loginUser(
      username,
      password
    )
    if (res.success) {
      // set data to store
      setUserData({
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      })
    }

    return res
  }

}