
export default class LocalStorageService {

  static setItem(keyName: string, value: string) {
    window.localStorage.setItem(keyName, JSON.stringify(value));
  }

  static getItem(keyName: string): any|null {
    const value = window.localStorage.getItem(keyName);
    if (value) {
      try {
        const json = JSON.parse(value);
        return json
      } catch (e) {
        console.error("Error while json.parse", e)
      }
    }
    return null
  }

  static removeItem(keyName: string) {
    window.localStorage.removeItem(keyName)
  }

}
