import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import Client from "../../Model/Client";
import {ClientItemOrder} from "../../Components/Clients/ClientsTableComponent";
import {ClientFilterData} from "../../Components/Pages/Client/ClientsManagement/ClientFilterFormModal";
import lodash from "lodash";


export default class ClientsTableComponentViewModel extends BaseViewModel {

  clients: Client[]
  allClientsCount: number
  page: number
  private clientFilter: ClientFilterData
  private itemOrder: ClientItemOrder
  private isSetup: boolean = false


  constructor(
    clientFilter: ClientFilterData,
  ) {
    super();

    this.clients = []
    this.allClientsCount = 0
    this.page = 1
    this.clientFilter = clientFilter

    this.itemOrder = {
      colName: "lastName",
      ascOrDesc: "asc",
    }
  }

  viewDidMount() {
    super.viewDidMount();

    this.isSetup = true
  }

  isLoading(): boolean {
    if (!this.isSetup) {
      return true
    }
    return super.isLoading();
  }

  private _loadClientsDebounced = lodash.debounce(() => {
    this._loadClients()
  }, 300)

  setClientFilter(clientFilter: ClientFilterData) {
    this.clientFilter = clientFilter
    this.loadClientsDebounced()
  }

  setPage(page: number) {
    this.page = page
  }

  getItemOrder(): ClientItemOrder {
    return this.itemOrder
  }

  setItemOrder(itemOrder: ClientItemOrder) {
    this.itemOrder = {
      colName: itemOrder.colName,
      ascOrDesc: itemOrder.ascOrDesc,
    }

    this.loadClientsDebounced()
  }

  private async _loadClients() {
    this.enterLoadingProcess()
    this.updateView()

    const resp = await APIService.getInstance().loadClientsRequest({
      offset: this.getRequestOffset(),
      limit: this.getRequestLimit(),
      name: this.clientFilter.name || undefined,
      location: this.clientFilter.location || undefined,
      postalCode: this.clientFilter.postalCode || undefined,
      street: this.clientFilter.street || undefined,
      lastContainerConfirmationFrom: this.clientFilter.lastContainerConfirmationFrom || undefined,
      lastContainerConfirmationTo: this.clientFilter.lastContainerConfirmationTo || undefined,
      lastOrderFrom: this.clientFilter.lastOrderFrom || undefined,
      lastOrderTo: this.clientFilter.lastOrderTo || undefined,
      containersAtClientFrom: this.clientFilter.containersAtClientFrom || undefined,
      containersAtClientTo: this.clientFilter.containersAtClientTo || undefined,
      itemOrder: [this.getItemOrder()],
    }, {
      withAddress: true,
    })
    this.clients = resp.clients
    this.allClientsCount = resp.count

    this.leaveLoadingProcess()
    this.updateView()
  }

  async loadClientsDebounced() {
    this._loadClientsDebounced()
  }

  getRequestOffset(): number {
    return this.getRequestLimit() * (this.page - 1)
  }

  getRequestLimit(): number {
    return 20
  }

  onExportClick() {
    APIService.getInstance().openClientsExportUrl()
  }

}