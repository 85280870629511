

export default class ConfigService {

  static readonly AppVersion: string = "0.1.0"

  static getAppVersion(): string {
    return ConfigService.AppVersion;
  }

  static getAPIEndpoint(): string|null {
    if (
      document.getElementById("root") != null &&
      document.getElementById("root")!.dataset.apiUrl
    ) {
      let apiUrl: string = document.getElementById("root")!.dataset.apiUrl!
      if (apiUrl.endsWith("/")) {
        // remove / at end
        return apiUrl.substring(0, apiUrl.length - 1)
      } else {
        return apiUrl
      }
    }

    return null
  }

}