/* eslint-disable no-script-url */
import React, {Component} from "react";


interface Props  {
  contentText: string,
  active: boolean,
  onClick?: () => void,
}
export default class BreadCrumb extends Component<Props, {}> {

  private getClassNames(): string {
    return `breadcrumb-item ${this.props.active ? 'active' : ''}`
  }

  private getContent(): JSX.Element {
    const contentText = this.props.contentText

    if (this.props.active) {
      return (
        <>
          {contentText}
        </>
      )
    }

    return (
      <a
        href="javascript:void(0);"
        onClick={() => {
          if (this.props.onClick != null) {
            this.props.onClick()
          }
        }}
      >
        {contentText}
      </a>
    )
  }

  render() {
    return (
      <li className={this.getClassNames()}>
        {this.getContent()}
      </li>
    );
  }
}