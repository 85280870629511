import {gql} from "graphql-request";
import {IOrderDoc, Order} from "./interfaces/IOrderGQLDoc";
import OrderStatus from "../../../Model/OrderStatus";


export declare type OrderStatusRequestResponse = {
  orderStatuses: OrderStatus[],
  count: number,
}

export declare type OrderRequestResponse = {
  order: Order,
}

export const getOrderDetailDoc = () => {
  return gql`
    query order(
      $id: Int!
    ) {
      order(
        id: $id
      ) {
        ` +
    IOrderDoc()
    + `
      }
    } 
  `;
};

