import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import {AddContainersContainer} from "../Containers/AddContainersFormViewModel";
import {ContainerConfirmationContainer} from "../ContainerConfirmationFormViewModel";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";


export default class CompleteOrderPageViewModel extends BaseViewModel {

  orderId: number
  order: Order | null = null
  containersToReturn: AddContainersContainer[] | null = null


  constructor(
    orderId: number,
  ) {
    super();

    this.orderId = orderId
    this.loadOrder()
  }

  async loadOrder(): Promise<void> {
    this.enterLoadingProcess()
    this.updateView()

    const resp = await APIService.getInstance().loadOrder(
      this.orderId,
    )
    this.order = resp.order

    this.leaveLoadingProcess()
    this.updateView()
  }

  getContainersToConfirm(): ContainerConfirmationContainer[] {
    let containerConfirmationContainers: ContainerConfirmationContainer[] = []
    this.order?.orderContainers.forEach(el => {
      containerConfirmationContainers.push({
        containerId: el.containerId.toString(),
        containerInputData: {
          name: el.containerName,
          quantity: el.containerQuantity,
          bruttoPrice: el.containerBruttoPrice,
        }
      })
    })

    this.order?.client.containersAtClient?.containers.forEach(containerAtClient => {
      // check if some get returned
      const foundReturnContainer = this.containersToReturn?.find(returnContainer => {
        if (returnContainer.container == null) {
          return false
        }
        return containerAtClient.containerId.toString() === returnContainer.container.id
      })
      console.log("debug", "foundReturnContainer", foundReturnContainer)

      if (containerAtClient.containerQuantity === 0) {
        return
      }

      if (foundReturnContainer == null) {
        // no container is returned
        containerConfirmationContainers.push({
          containerId: containerAtClient.containerId.toString(),
          containerInputData: {
            name: containerAtClient.containerName,
            quantity: containerAtClient.containerQuantity,
            bruttoPrice: containerAtClient.containerBruttoPrice,
          }
        })
      } else {
        // some containers are returned
        const numberOfContainersClientKeeps = containerAtClient.containerQuantity - foundReturnContainer.orderContainersInput.quantity
        if (numberOfContainersClientKeeps === 0) {
          return
        }

        containerConfirmationContainers.push({
          containerId: containerAtClient.containerId.toString(),
          containerInputData: {
            name: containerAtClient.containerName,
            quantity: numberOfContainersClientKeeps,
            bruttoPrice: containerAtClient.containerBruttoPrice,
          }
        })
      }
    })

    // sum up container quantity of containers with same id and price
    const _containerConfirmationContainers = [...containerConfirmationContainers]
    containerConfirmationContainers = []
    for (let i = 0; i < _containerConfirmationContainers.length; i++){
      const el = _containerConfirmationContainers[i];
      const foundIndex = containerConfirmationContainers.findIndex(con => {
        return el.containerId === con.containerId && el.containerInputData.bruttoPrice === con.containerInputData.bruttoPrice
      })

      if (foundIndex >= 0) {
        containerConfirmationContainers[foundIndex].containerInputData.quantity += el.containerInputData.quantity
      } else {
        containerConfirmationContainers.push(el)
      }
    }
    console.log("debug", "containerConfirmationContainers", containerConfirmationContainers)

    return containerConfirmationContainers
  }

}