import BaseVMComponent from "../BaseVMComponent";
import React from "react";
import {ModalTitle} from "react-bootstrap";
import CreateContainerModalViewModel from "../../ViewModels/Containers/CreateContainerModalViewModel";
import ContainerModal from "./ContainerModal";


interface Props {
  onModalClose: () => void,
  onSuccessfullySaved: () => void,
}
interface State {
}
export default class CreateContainerModal extends BaseVMComponent<Props, State, any, CreateContainerModalViewModel> {
  static defaultProps = {
  }

  constructor(props: any) {
    super(props);
  }

  protected initViewModel(): void {
    this.viewModel = new CreateContainerModalViewModel()
  }

  render() {
    return (
      <>
        <ContainerModal
          title={
            <ModalTitle>Neue Gebindeart erstellen</ModalTitle>
          }
          onModalClose={() => this.props.onModalClose()}
          onSuccessfullySaved={() => this.props.onSuccessfullySaved()}
          sendSaveRequest={this.viewModel.sendSaveRequest}
        />
      </>
    );
  }
}