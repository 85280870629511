import {gql} from "graphql-request";


export interface OrderContainersInput {
  containerId: string
  quantity: number
  bruttoPrice: number
}

export const OrderContainersDocs = () => {
  return gql`
    orderContainers {
      id
      orderId
      containerId
      containerName
      containerQuantity
      containerNettoPrice
      containerBruttoPrice
    }
  `
}
