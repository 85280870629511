import React, {Component, Fragment} from "react";
import {InputComponent, InputComponentProps} from "./InputComponent";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import {DateService} from "../../Services/DateService";


interface Props {
  labelTitle: string,
  value: Date|null,
  onChange: (value: Date|null) => void,
  disabled?: boolean,
  inputProps?: Partial<InputComponentProps>,
}
interface State {
  calendarVisible: boolean,
  isEditing: boolean,
  inputValueWhileEditing: string|null,
}
export class DateInputComponent extends Component<Props, State> {
  state: State = {
    calendarVisible: false,
    isEditing: false,
    inputValueWhileEditing: null,
  }
  private clickAwayFunc: any = null


  private onInputElementClick(event: MouseEvent) {
    this.showCalendar()

    event.preventDefault()
  }

  private onClickAway(event: MouseEvent) {
    let targetEl = event.target
    while (targetEl != null && (targetEl as HTMLElement).id !== "date-input") {
      targetEl = (targetEl as HTMLElement).parentElement
    }

    if (targetEl != null) {
      // is calendar element
      return
    }

    this.hideCalendar()

    event.preventDefault()
  }

  private getDateInputValue(): string {
    if (this.state.isEditing && this.state.inputValueWhileEditing) {
      return this.state.inputValueWhileEditing
    }

    if (this.props.value == null) {
      return ""
    }
    return DateService.getShortDateString(this.props.value)
  }

  private showCalendar() {
    if (this.state.calendarVisible) {
      return
    }

    this.setState({
      calendarVisible: true,
    })
    // setup clickaway listener
    this.clickAwayFunc = this.onClickAway.bind(this)
    document.body.addEventListener("click", this.clickAwayFunc)
  }

  private hideCalendar() {
    this.setState({
      calendarVisible: false,
    })
    // remove event listener
    document.body.removeEventListener("click", this.clickAwayFunc)
  }

  private onDateValueChange(date: Date | null) {
    this.props.onChange(date)
  }

  private getSelectedDateValue(): Date | null {
    return this.props.value
  }

  private onCalendarDateChange(value: Date) {
    this.onDateValueChange(value)
    this.hideCalendar()
  }

  private onInputValueChange(value: string) {
    this.setState({
      isEditing: true,
      inputValueWhileEditing: value,
    })
  }

  private onInputBlur(value: string) {
    if (!this.state.isEditing) {
      return
    }

    this.setState({
      isEditing: false,
      inputValueWhileEditing: null,
    })
    // check if value date
    let date = DateService.getDateFromShortDateString(value)
    if (date !== "Invalid Date") {
      this.onDateValueChange(date)
    } else {
      this.onDateValueChange(null)
    }
  }

  private getInputComponent(): JSX.Element {
    let props = this.props.inputProps
    if (!props) {
      props = {
        labelTitle: "",
        type: "text",
        value: "",
      }
    }
    props.labelTitle = this.props.labelTitle
    props.type = "text"
    props.value = this.getDateInputValue()
    props.onValueChange = (value) => this.onInputValueChange(value)
    props.onBlur = (value) => this.onInputBlur(value)
    props.onClick = (event) => this.onInputElementClick(event as any)
    props.showClearIcon = true
    props.onClearIconClick = () => this.onDateValueChange(null)
    if (this.props.disabled != null) {
      props.disabled = this.props.disabled
    }

    return (
      <InputComponent
        {...props as any}
      />
    )
  }

  render() {
    return (
      <div
        id={"date-input"}
      >
        { this.getInputComponent() }

        { (this.props.disabled !== true && this.state.calendarVisible) &&
          <div
            style={styles.calendarElement}
          >
            <Calendar
              value={this.getSelectedDateValue()}
              onChange={(value: Date) => this.onCalendarDateChange(value)}
            />
          </div>
        }
      </div>
    );
  }
}


const styles: any = {
  calendarElement: {
    position: "absolute",
    zIndex: 10,
  }
}