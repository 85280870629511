import BaseVMComponent from "../BaseVMComponent";
import CreateClientButtonViewModel from "../../ViewModels/CreateClientButtonViewModel";
import React, {FormEvent} from "react";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {getErrorTextByError, unknownErrorString} from "../../global/Error";
import CreateClientModal from "../Clients/CreateClientModal";

interface Props {
  onSuccessfullySaved: () => void,
  buttonLabel: string,
  className: string,
}
interface State {
  createModalOpen: boolean,
}
export default class CreateClientButton extends BaseVMComponent<Props, State, any, CreateClientButtonViewModel> {
  static defaultProps = {
    onSuccessfullySaved: () => {},
    buttonLabel: "Neuen Mandanten erstellen",
    className: "",
  }

  constructor(props: any) {
    super(props);

    this.state = {
      createModalOpen: false,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new CreateClientButtonViewModel()
  }

  private onOpenModalButtonClick() {
    this.setState({
      createModalOpen: true,
    })
  }

  render() {
    return (
      <>
        <button
          className={"btn btn-primary " + this.props.className}
          onClick={this.onOpenModalButtonClick.bind(this)}
          type={"button"}
        >
          {this.props.buttonLabel}
        </button>

        { this.state.createModalOpen &&
          <CreateClientModal
            onModalClose={() => {
              this.setState({
                createModalOpen: false,
              })
            }}
            onSuccessfullySaved={() => {
              this.props.onSuccessfullySaved()
            }}
          />
        }
      </>
    );
  }
}