export default class FloatService {

  static toGermanCurrencyValueString(value: number): string {
    return FloatService.toStringWithFixedFractionDigits(value, 2).replace(".", ",")
  }

  static toStringWithFixedFractionDigits(value: number, fractionDigits: number): string {
    return value.toFixed(fractionDigits)
  }

}