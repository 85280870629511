import { Navigate } from "react-router-dom";
import {useAuth} from "../Context/AuthContext";


interface UnauthenticatedRouteComponentProps {
  children: any
}
export const UnauthenticatedRouteComponent = (props: UnauthenticatedRouteComponentProps) => {
  const { userIsLoggedIn } = useAuth();

  if (userIsLoggedIn()) {
    // user is authenticated
    console.warn("User is logged in -> go to admin")
    return <Navigate to="/admin" />;
  }

  return props.children;
};
