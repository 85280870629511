

declare type UpdateViewCallback = () => void

export default abstract class BaseViewModel {

  private updateViewCallback: UpdateViewCallback = () => {}
  private loadingCounter: number = 0


  constructor() {
  }

  viewDidMount() {}

  setUpdateViewCallback(updateViewCallback: UpdateViewCallback) {
    this.updateViewCallback = updateViewCallback
  }

  updateView() {
    this.updateViewCallback()
  }

  protected enterLoadingProcess() {
    this.loadingCounter += 1
  }

  protected leaveLoadingProcess() {
    this.loadingCounter -= 1
  }

  isLoading(): boolean {
    return this.loadingCounter !== 0
  }

}