import React, {Component} from "react";
import Product from "../../Model/Product";
import {DropDownOption} from "./DropDownComponent";
import ProductInputRow from "./ProductInputRow";


interface Props {
  id: string,
  selectableProducts: Product[],
  onProductSelectionChange: (product: Product) => void,
  selectedProduct: Product | null,
  quantity: number,
  onQuantityChange: (quantity: number) => void,
  price: number,
  onPriceChange: (price: number) => void,
  onIsFreeProductChange: (checked: boolean) => void,
  isFreeProduct: boolean,
  inputDisabled: boolean,
}
interface State {

}
export default class AddNewOrderProductInputRow extends Component<Props, State> {
  private getProductOptions(): DropDownOption[] {
    let options: DropDownOption[] = []
    if (this.props.selectableProducts) {
      this.props.selectableProducts.forEach(product => {
        options.push({
          label: product.productName,
          value: product.id,
        })
      })
    }
    return options
  }

  private additionInputsDisabled(): boolean {
    return this.props.inputDisabled || this.props.quantity === 0
  }

  render() {
    const product = this.props.selectedProduct

    return (
      <ProductInputRow
        id={this.props.id}
        productSelect={{
          idValue: product?.id || null,
          options: this.getProductOptions(),
          onValueChange: (value) => {
            let product = this.props.selectableProducts.find(el => el.id === value)
            this.props.onProductSelectionChange(product!)
          },
          inputDisabled: this.props.inputDisabled,
        }}
        quantityInput={{
          value: this.props.quantity,
          onChange: (value) => {
            this.props.onQuantityChange(value)
          },
          inputDisabled: this.props.inputDisabled,
        }}
        priceInput={{
          value: this.props.price,
          onChange: (value) => {
            this.props.onPriceChange(value)
          },
          inputDisabled: this.additionInputsDisabled(),
        }}
        isFreeCheckbox={{
          checked: this.props.isFreeProduct,
          onChange: (checked) => {
            this.props.onIsFreeProductChange(checked)
          },
          inputDisabled: this.additionInputsDisabled(),
        }}
        normalPrice={{
          value: this.props.selectedProduct?.productBruttoPrice,
        }}
      />
    )
  }
}