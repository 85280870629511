import {gql} from "graphql-request";

export const ContainersAtClientDoc = () => {
  return gql`
    id
    date
    additionalInfo
    signatureSrcURL
  
    containers {
      ${ContainersAtClientContainersDoc()}
    }
  `
}

const ContainersAtClientContainersDoc = () => {
  return gql`
    id
    containerId
    containerName
    containerQuantity
    containerNettoPrice
    containerBruttoPrice
    containerTaxRate
  `
}

export const GetContainersAtClientDoc = () => {
  return gql`
    query containersAtClient(
      $id: ID!
    ) {
      containersAtClient(
        id: $id
      ) {
        ${ContainersAtClientDoc()}
      }
    }
  `
}

export const GetContainersAtClientOfClientDoc = () => {
  return gql`
    query containersAtClientOfClient(
      $clientId: ID!
      $limit: Int!
      $offset: Int!
      $dateOrder: String
    ) {
      containersAtClientOfClient(
        clientId: $clientId
        limit: $limit
        offset: $offset
        dateOrder: $dateOrder
      ) {
        containersAtClient {
          ${ContainersAtClientDoc()}
        }
        count
      }
    }
  `
}

export const GetAllContainersAtClientsDoc = () => {
  return gql`
    query allContainersAtClients {
      allContainersAtClients {
        allContainersAtClients {
          containerId
          containerName
          quantity
        }
      }
    }
  `
}

export const CreateContainersAtClientDoc = () => {
  return gql`
    mutation createContainersAtClient(
      $clientId: ID!
      $additionalInfo: String
      $signatureAsBase64: String
      $containers: [OrderContainersInput!]!
    ) {
      createContainersAtClient(
        clientId: $clientId
        additionalInfo: $additionalInfo
        signatureAsBase64: $signatureAsBase64
        containers: $containers
      ) {
        ${ContainersAtClientDoc()}
      }
    }
  `
}

