import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import {CreateProductRequestData} from "../../Services/APIServiceInterfaces/Product";


export default class CreateProductModalViewModel extends BaseViewModel {

  sendSaveRequest(data: CreateProductRequestData): Promise<any> {
    return APIService.getInstance().createProduct(data)
  }

}