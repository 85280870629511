import React, {Fragment} from "react";
import BaseVMComponent from "../../BaseVMComponent";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import ClientForm from "../../Clients/ClientForm";
import ClientDetailsPageViewModel from "../../../ViewModels/Pages/Client/ClientDetailsPageViewModel";
import LoadingComponent from "../../Shared/LoadingComponent";
import {Button} from "react-bootstrap";
import ContainersAtClientComponent from "../../Shared/ContainersAtClientComponent";
import ContainersAtClientTable from "../../Clients/ContainersAtClientTable";
import ClientOrdersTableComponent from "../../Orders/ClientOrdersTable/ClientOrdersTableComponent";


interface Props {
  clientId: number,
}
interface State {
  inEditMode: boolean,
}
class _ClientDetailsPage extends BaseVMComponent<Props, State, any, ClientDetailsPageViewModel> {

  constructor(props: Props) {
    super(props);

    this.state = {
      inEditMode: false,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ClientDetailsPageViewModel(
      this.props.clientId
    )
  }

  async componentDidMount() {
    this.viewModel.loadClient()
  }

  private getContainersAtClientElements(): JSX.Element {
    let content: JSX.Element | null = null
    if (this.viewModel.client == null) {
      content = (
        <div style={{
          color: "gray",
        }}>
          kein Mandant ausgewählt
        </div>
      )
    }

    content = (
      <>
        <ContainersAtClientComponent
          containersAtClient={this.viewModel.client?.containersAtClient || null}
        />
      </>
    )

    return (
      <Fragment>
        <h4>Gebinde bei Kunde</h4>
        {content}

        <NavLink to={`/admin/clients/client/${this.props.clientId}/change-containers-at-client`}>
          <Button
            variant={"secondary"}
            type={"button"}
            className={"mt-2"}
          >
            Gebindestand ändern
          </Button>
        </NavLink>
      </Fragment>
    )
  }

  render(): JSX.Element {
    return (
      <div>
        <div className={"row mt-4"}>
          <div className={"col-8"}>
            <h3>Mandantendetails</h3>
          </div>

          <div className={"col-4 d-flex justify-content-end"}>
            { !this.state.inEditMode &&
              <button
                className={"btn btn-outline-primary"}
                onClick={() => {
                  this.setState({
                    inEditMode: true,
                  })
                }}
              >
                Bearbeiten
              </button>
            }
            { this.state.inEditMode &&
              <Fragment>
                <button
                  className={"btn btn-outline-danger mr-2"}
                  onClick={async () => {
                    // eslint-disable-next-line no-restricted-globals
                    const confirmed = confirm("Mandant wirklich archivieren?")
                    if (confirmed) {
                      await this.viewModel.archiveClient()
                      this.setState({
                        inEditMode: false,
                      })
                    }
                  }}
                >
                  Archivieren
                </button>

                <button
                  className={"btn btn-outline-secondary"}
                  onClick={() => {
                    this.setState({
                      inEditMode: false,
                    })
                  }}
                >
                  Schließen
                </button>
              </Fragment>
            }
          </div>
        </div>

        <div className="mt-4">
          <LoadingComponent
            shown={this.viewModel.isLoading()}
          />

          { !this.viewModel.isLoading() && this.viewModel.client?.archived === true &&
            <div className="alert alert-danger mt-5 mb-5 text-center" role="alert">
              Mandant ist archiviert
            </div>
          }

          <div className="row mb-2">
            <div className="col-md-6">
              { !this.viewModel.isLoading() &&
                <>
                  <ClientForm
                    clientData={this.viewModel.client}
                    inputDisabled={!this.state.inEditMode}
                    onSuccessfullySaved={() => {
                      this.viewModel.loadClient()
                    }}
                    sendSaveRequest={(data) => {
                      const _data: any = {...data}
                      _data.id = this.props.clientId
                      return this.viewModel.sendSaveRequest(_data)
                        .then(() => {
                          this.setState({
                            inEditMode: false,
                          })
                        })
                    }}
                    buttons={
                      <>
                        { this.state.inEditMode &&
                            <Button
                              variant="primary"
                              type={"submit"}
                            >
                              Speichern
                            </Button>
                        }
                      </>
                    }
                  />
                </>
              }
            </div>
            <div className="col-md-6">
              <div className={"mb-4"}>
                {this.getContainersAtClientElements()}
              </div>

              <div className={"last-containers mt-4 mb-4"}>
                <h4>Letzte Pfandbestätigungen</h4>
                <ContainersAtClientTable
                  clientId={this.props.clientId}
                  simpleTable={true}
                />
                <NavLink to={`/admin/clients/client/${this.props.clientId}/containers-at-client`}>
                  <span className="btn btn-secondary">Alle anzeigen</span>
                </NavLink>
              </div>

              <div className={"last-orders mt-4 mb-4"}>
                <h4>Letzte Aufträge</h4>
                <ClientOrdersTableComponent
                  clientId={this.props.clientId}
                  page={1}
                  showSimpleTable={true}
                />
                <NavLink to={`orders`}>
                  <span className="btn btn-secondary">Alle anzeigen</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const ClientDetailsPage: any = (props: Props) => {
  const navigation = useNavigate();
  const { clientId } = useParams();

  return (
    <_ClientDetailsPage
      {...props}
      clientId={parseInt(clientId!)}
      // navigation={navigation}
      // page={parseInt(page || "1")}
    />
  )
}


export default ClientDetailsPage;