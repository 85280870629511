import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import {CreateOrderRequestData} from "../../Services/APIServiceGQLDocs/OrderDocs/CreateOrderGQLDoc";


export default class CreateOrderPageViewModel extends BaseViewModel {

  sendSaveRequest(data: CreateOrderRequestData): Promise<any> {
    return APIService.getInstance().createOrderRequest(data)
  }

}