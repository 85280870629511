import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import {CreateContainerRequestData} from "../../Services/APIServiceInterfaces/Container";


export default class CreateContainerModalViewModel extends BaseViewModel {

  sendSaveRequest(data: CreateContainerRequestData): Promise<any> {
    return APIService.getInstance().createContainer(data)
  }

}