import { useState } from "react";
import LocalStorageService from "../Services/LocalStorageService";

export const useLocalStorage = (keyName: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    return LocalStorageService.getItem(keyName)
  });

  const setValue = (newValue: string|null) => {
    if (newValue == null) {
      LocalStorageService.removeItem(keyName)
    } else {
      LocalStorageService.setItem(keyName, newValue)
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
