import React from "react";
import OrderProductsList from "../../Shared/OrderProductsList";
import OrderContainersList from "../../Shared/OrderContainersList";
import AddContainersForm from "../../Containers/AddContainersForm";
import OrderBillCalculation from "../../Shared/OrderBillCalculation";
import {Button} from "react-bootstrap";
import BaseVMComponent from "../../BaseVMComponent";
import OrderBillViewModel from "../../../ViewModels/Orders/CompleteOrder/OrderBillViewModel";
import {AddContainersContainer} from "../../../ViewModels/Containers/AddContainersFormViewModel";
import ContainersAtClientComponent from "../../Shared/ContainersAtClientComponent";
import ContainersAtClient from "../../../Model/ContainersAtClient";
import {Order} from "../../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";


interface Props {
  orderId: number,
  onGoAheadClick: (containersToReturn: AddContainersContainer[]) => void,
  order: Order,
  containersAtClient: ContainersAtClient | null,
  containersToReturn: AddContainersContainer[] | null,
}
export default class OrderBill extends BaseVMComponent<Props, {}, any, OrderBillViewModel> {

  constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  protected initViewModel(): void {
    this.viewModel = new OrderBillViewModel(
      this.props.orderId,
      this.props.order,
      this.props.containersAtClient,
      this.props.containersToReturn,
    )
  }

  render() {
    return (
      <div className="">
        <div className={"row mt-3"}>
          <div className={"col-md-6"}>
            <div className={""}>
              <h3>Auftragsumfang</h3>
              <OrderProductsList
                orderProducts={this.viewModel.order!.orderProducts}
              />
            </div>

            <div className={"mt-3 mb-4"}>
              <h3>Gebinde der Bestellung</h3>
              <OrderContainersList
                orderContainers={this.viewModel.order!.orderContainers}
              />
            </div>

            <hr />

            <div className={"mt-4"}>
              <h3>Gebinde bei Kunde</h3>
              <ContainersAtClientComponent
                containersAtClient={this.viewModel.containersAtClient}
              />
            </div>

            <div className={"mt-3"}>
              <h4>Gebinderückgabe Kunde</h4>
              {this.viewModel.hasContainersAtClient() &&
                <p>Gebindestand vom <b>{this.viewModel.getContainersAtClientDate()}</b></p>
              }
              <AddContainersForm
                initialContainers={this.viewModel.getContainersToReturn()}
                onNewOrderContainersChange={(orderContainers) => {
                  this.viewModel.setContainersToReturn(orderContainers)
                }}
              />
            </div>
          </div>


          <div className={"col-md-6"}>
            <div className={""}>
              <h3>Auftragspreis</h3>
              <OrderBillCalculation
                orderProducts={this.viewModel.order!.orderProducts}
                orderContainers={this.viewModel.order!.orderContainers}
                returnedContainers={this.viewModel.getContainersToReturn()}
              />
            </div>
          </div>
        </div>

        <div className={"mt-5 d-flex justify-content-end align-items-end"}>
          <Button
            variant="success"
            type={"button"}
            onClick={() => {
              this.props.onGoAheadClick(this.viewModel.getContainersToReturn())
            }}
          >
            Weiter
          </Button>
        </div>
      </div>
    )
  }
}
