

export default class GoogleMapsService {

  static baseURL = "https://www.google.com/maps/search/?api=1&query="

  static createGoogleMapsLink(
    street: string,
    location: string,
    postalCode: string,
  ): string {
    let queryString = street + ", " + postalCode + " " + location

    return GoogleMapsService.baseURL + encodeURIComponent(queryString)
  }

}