import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import Client from "../../Model/Client";


export default class ContainersAtClientPageViewModel extends BaseViewModel {

  clientId: number
  client: Client | null = null


  constructor(
    clientId: number,
  ) {
    super();

    this.clientId = clientId
  }

  async viewDidMount() {
    super.viewDidMount();

    const resp = await APIService.getInstance().loadClient(this.clientId)
    this.client = resp.client
    this.updateView()
  }

}