import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import ContainersAtClient from "../../Model/ContainersAtClient";
import Client from "../../Model/Client";


export default class ContainersAtClientDetailPageViewModel extends BaseViewModel {

  clientId: number
  containersAtClientID: number
  loadingIt: number = 0
  client: Client | null = null
  containersAtClient: ContainersAtClient | null = null


  constructor(
    clientId: number,
    containersAtClientID: number,
  ) {
    super();

    this.clientId = clientId
    this.containersAtClientID = containersAtClientID
  }

  viewDidMount() {
    super.viewDidMount();

    this.loadClient()
    this.loadContainersAtClient()
  }

  async loadClient(): Promise<any> {
    this.loadingIt += 1
    this.updateView()

    const resp = await APIService.getInstance().loadClient(this.clientId)
    this.client = resp.client

    this.loadingIt -= 1
    this.updateView()
  }

  async loadContainersAtClient(): Promise<any> {
    this.loadingIt += 1
    this.updateView()

    const resp = await APIService.getInstance().getContainersAtClient({
      id: this.containersAtClientID,
    })
    this.containersAtClient = resp

    this.loadingIt -= 1
    this.updateView()
  }


}