import BaseVMComponent from "../../BaseVMComponent";
import OrdersTableComponent from "../OrdersTableComponent";
import ClientOrdersTableComponentViewModel from "../../../ViewModels/Orders/ClientOrdersTableComponentViewModel";


interface Props {
  clientId: number,
  page: number,
  rowLimit: number|null,
  showSimpleTable: boolean,
}
interface State {
}
export default class ClientOrdersTableComponent extends BaseVMComponent<Props, State, any, ClientOrdersTableComponentViewModel> {
  static defaultProps = {
    rowLimit: null,
    showDone: false,
  }

  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ClientOrdersTableComponentViewModel(
      this.props.clientId,
      this.props.rowLimit
    )
  }

  async componentDidMount() {
    this.viewModel.setPage(this.props.page)
    this.viewModel.loadOrders()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (
      prevProps.page !== this.props.page
    ) {
      this.viewModel.setPage(this.props.page)
      this.viewModel.loadOrders()
    }
  }

  render() {
    return (
      <>
        <OrdersTableComponent
          onPageChange={(page) => {
          }}
          orders={this.viewModel.orders}
          page={this.props.page || 1}
          loading={this.viewModel.isLoading()}
          showSimpleTable={this.props.showSimpleTable}
          rowLimit={this.props.rowLimit}
          offset={this.viewModel.getRequestOffset()}
          limit={this.viewModel.getRequestLimit()}
          totalCount={this.viewModel.allOrdersCount}
          onShowDoneChange={(showDone) => {
            this.viewModel.setShowDone(showDone)
            this.viewModel.loadOrders()
          }}
          orderedBy={{
            colName: "id",
            ascOrDesc: "asc",
          }}
          onOrderedByChanged={(itemOrder) => {

          }}
        />
      </>
    )
  }
}

