import BaseViewModel from "./BaseViewModel";
import {DropDownOption} from "../Components/Shared/DropDownComponent";
import Client from "../Model/Client";
import APIService from "../Services/APIService";

export default class HomePageViewModel extends BaseViewModel {

  private clientDropDownInputValue: string|null = null
  private clientSuggestions: Client[] = []

  orderLimit: number = 3
  productsLimit: number = 3


  viewDidMount() {
    super.viewDidMount();

    this.loadClients()
  }

  private async loadClients(): Promise<void> {
    this.enterLoadingProcess()
    this.updateView()

    const resp = await APIService.getInstance().loadClientsRequest({
      offset: 0,
      limit: 10,
      name: this.clientDropDownInputValue || undefined,
    })
    this.clientSuggestions = resp.clients

    this.leaveLoadingProcess()
    this.updateView()
  }

  onClientDropDownInputValueChange(newValue: string|null) {
    this.clientDropDownInputValue = newValue

    this.loadClients()
  }

  getClientOptions(): DropDownOption[] {
    let options: DropDownOption[] = []
    this.clientSuggestions.forEach(client => {
      options.push({
        label: client.fullName,
        value: client.id.toString(),
      })
    })
    return options
  }

}