import BaseVMComponent from "../BaseVMComponent";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import AllOrdersTableComponentViewModel from "../../ViewModels/Orders/AllOrdersTableComponentViewModel";
import OrdersTableComponent from "./OrdersTableComponent";


interface Props {
  navigation: NavigateFunction,
  page?: number,
  showSimpleTable: boolean,
  rowLimit: number|null,
}
interface State {
}
class _AllOrdersTableComponent extends BaseVMComponent<Props, State, any, AllOrdersTableComponentViewModel> {
  static defaultProps = {
    showSimpleTable: false,
    rowLimit: null,
    showDone: false,
  }

  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  protected initViewModel(): void {
    this.viewModel = new AllOrdersTableComponentViewModel(this.props.rowLimit)
  }

  async componentDidMount() {
    this.viewModel.setPage(this.props.page!)
    this.viewModel.loadOrders()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (
      prevProps.page !== this.props.page
    ) {
      this.viewModel.setPage(this.props.page!)
      this.viewModel.loadOrders()
    }
  }

  private onPageChange(page: number) {
    this.props.navigation("/admin/orders/" + page)
  }

  render() {
    return (
      <>
        <OrdersTableComponent
          title={"Liste aller Aufträge"}
          onPageChange={(page) => {
            this.onPageChange(page)
          }}
          orders={this.viewModel.orders}
          page={this.props.page || 1}
          loading={this.viewModel.isLoading()}
          showSimpleTable={this.props.showSimpleTable}
          rowLimit={this.props.rowLimit}
          offset={this.viewModel.getRequestOffset()}
          limit={this.viewModel.getRequestLimit()}
          totalCount={this.viewModel.allOrdersCount}
          onShowDoneChange={(showDone) => {
            this.viewModel.setShowDone(showDone)
            this.viewModel.loadOrders()
          }}
          orderedBy={this.viewModel.getItemOrder()}
          onOrderedByChanged={(itemOrder) => {
            this.viewModel.setItemOrder(itemOrder)
          }}
        />
      </>
    )
  }
}


const AllOrdersTableComponent: any = (props: Props) => {
  const navigation = useNavigate();
  const { page } = useParams();

  return (
    <_AllOrdersTableComponent
      {...props}
      navigation={navigation}
      page={parseInt(page || "1")}
      ref={(props as any).innerRef}
    />
  )
}


export default AllOrdersTableComponent;
