import APIService from "../../Services/APIService";
import Client from "../../Model/Client";
import {DropDownOption} from "../../Components/Shared/DropDownComponent";
import OrderStatus from "../../Model/OrderStatus";
import BaseFormViewModel from "../BaseFormViewModel";
import Product from "../../Model/Product";
import OrderProduct from "../../Model/OrderProduct";
import Container from "../../Model/Container";
import OrderContainer from "../../Model/OrderContainer";
import ContainersAtClient from "../../Model/ContainersAtClient";
import {OrderProductsInput} from "../../Services/APIServiceGQLDocs/OrderProductDocs";
import {OrderContainersInput} from "../../Services/APIServiceGQLDocs/OrderContainersDocs";
import {AddContainersContainer} from "../Containers/AddContainersFormViewModel";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";
import {CreateOrderRequestData} from "../../Services/APIServiceGQLDocs/OrderDocs/CreateOrderGQLDoc";
import {AddProductsProduct} from "../../Components/Products/AddProductsForm/AddProductsFormVM";


export default class OrderFormViewModel extends BaseFormViewModel<CreateOrderRequestData> {

  selectedClient: Client|null = null
  selectedOrderStatus: OrderStatus|null = null
  private addressAsString: string|null = null
  private date: Date|null = null
  private notes: string|null = null
  private formType: "create" | "edit"

  private initialOrderData: Order|null = null
  private orderStatuses: OrderStatus[] = []

  private clientDropDownInput: string = ""
  private clientSuggestions: Client[] = []

  private products: Product[] = []
  private currentOrderProducts: OrderProduct[] = []
  private newOrderProducts: AddProductsProduct[] | null = null

  private containers: Container[] = []
  private currentOrderContainers: OrderContainer[] = []
  private newOrderContainersToAdd: OrderContainersInput[] | null = null

  inputDisabled: boolean


  constructor(
    sendSaveRequest: (data: CreateOrderRequestData) => Promise<any>,
    orderData: Order|null,
    formType: "create" | "edit",
    inputDisabled: boolean
  ) {
    super(sendSaveRequest);

    this.initialOrderData = orderData
    this.formType = formType
    this.inputDisabled = inputDisabled
  }

  viewDidMount() {
    super.viewDidMount();

    const orderData = this.initialOrderData
    // set initial data
    if (orderData != null) {
      this.setClient(orderData.client)
      this.setOrderStatus(orderData.orderStatus)
      if (orderData.address && orderData.address.addressAsString) {
        this.setAddressAsString(orderData.address.addressAsString)
      } else if (orderData.client && orderData.client.address && orderData.client.address.addressAsString) {
        this.setAddressAsString(orderData.client.address.addressAsString)
      }

      if (orderData.orderProducts) {
        this.currentOrderProducts = orderData.orderProducts
      }

      if (orderData.orderContainers) {
        this.currentOrderContainers = orderData.orderContainers
      }

      if (orderData.date != null) {
        let date = new Date(orderData.date)
        this.setDate(date)
      }
      if (orderData.notes != null) {
        this.setNotes(orderData.notes)
      }
    }

    this.loadClientsBasedOnDropDownInput()
    this.loadOrderStatuses().then(() => {
      if (this.formType === "create") {
        // set to "in planung"
        const inPlanungStatus = this.getInPlanungStatus()
        if (inPlanungStatus != null) {
          this.setOrderStatus(inPlanungStatus)
        }
      }
    })
    this.loadProducts()
    this.loadContainers()
  }

  private async loadOrderStatuses(): Promise<void> {
    const resp = await APIService.getInstance().loadOrderStatusesRequest()
    this.orderStatuses = resp.orderStatuses
    this.updateView()
  }

  private async loadClientsBasedOnDropDownInput(): Promise<void> {
    const resp = await APIService.getInstance().loadClientsRequest(
      {
        offset: 0,
        limit: 50,
        name: this.clientDropDownInput,
      },
      {
        withAddress: true,
      }
    )
    this.clientSuggestions = resp.clients
    this.updateView()
  }

  private async loadProducts(): Promise<void> {
    const resp = await APIService.getInstance().loadProducts(
      0,
      50,
    )
    this.products = []
    resp.products.forEach(prod => {
      this.products.push({
        id: prod.id.toString(),
        productName: prod.name,
        productNettoPrice: prod.basePrice,
        productBruttoPrice: prod.bruttoPrice,
        taxRate: prod.taxRate,
      })
    })
    this.updateView()
  }

  private async loadContainers(): Promise<void> {
    const resp = await APIService.getInstance().loadContainers(
      0,
      50,
    )
    this.containers = resp.containers
    this.updateView()
  }

  isInCreateMode(): boolean {
    return this.initialOrderData == null && !this.inputDisabled
  }

  getClientOptions(): DropDownOption[] {
    let options: DropDownOption[] = []

    this.clientSuggestions.forEach(client => {
      options.push({
        value: client.id.toString(),
        label: client.fullName,
      })
    })

    return options
  }

  private getInPlanungStatus(): OrderStatus | null {
    let inPlanungStatus: OrderStatus | null = null
    this.orderStatuses.forEach(status => {
      if (status.name.toLowerCase() === "in planung") {
        inPlanungStatus = status
      }
    })
    return inPlanungStatus
  }

  getOrderStatusOptions(): DropDownOption[] {
    let options: DropDownOption[] = []

    let orderStatuses = [...this.orderStatuses]
    // if (this.formType === "create") {
      orderStatuses.forEach(status => {
        // only in planung status
        if (status.id === this.getInPlanungStatus()?.id) {
          options.push({
            value: status.id.toString(),
            label: status.name,
          })
        }
      })
    // } else {
    // TODO: add after routes
    //   orderStatuses.forEach(status => {
    //     // all status unless done order status
    //     if (!status.isOrderDoneStatus) {
    //       options.push({
    //         value: status.id.toString(),
    //         label: status.name,
    //       })
    //     }
    //   })
    // }

    return options
  }

  getProducts(): Product[] {
    return this.products
  }

  getNotFreeOrderProducts(): OrderProduct[] {
    return this.currentOrderProducts.filter(el => !el.isFreeProduct)
  }

  getFreeOrderProducts(): OrderProduct[] {
    return this.currentOrderProducts.filter(el => el.isFreeProduct)
  }

  getContainers(): Container[] {
    return this.containers
  }

  getOrderContainers(): OrderContainer[] {
    return this.currentOrderContainers
  }

  onClientDropDownInputChange(newValue: string) {
    // load clients based on input
    this.clientDropDownInput = newValue

    this.loadClientsBasedOnDropDownInput()
  }

  onClientSelect(newIdValue: string|null) {
    if (newIdValue == null) {
      this.selectedClient = null
    }

    let selectedClient = this.clientSuggestions.find(el => el.id.toString() === newIdValue)
    this.selectedClient = selectedClient || null

    this.updateView()
  }

  onOrderStatusSelect(newIdValue: string|null) {
    if (newIdValue == null) {
      this.selectedOrderStatus = null
    }

    let selectedObj = this.orderStatuses.find(el => el.id.toString() === newIdValue)
    this.selectedOrderStatus = selectedObj || null

    this.updateView()
  }

  private setClient(value: Client) {
    this.selectedClient = value
    this.updateView()
  }

  private setAddressAsString(value: string) {
    this.addressAsString = value
    this.updateView()
  }

  private setOrderStatus(value: OrderStatus) {
    this.selectedOrderStatus = value
    this.updateView()
  }


  getAddressAsString(): string {
    // check if custom address is used
    if (this.addressAsString) {
      return this.addressAsString
    }

    // otherwise return clients address
    if (
      this.selectedClient &&
      this.selectedClient.address &&
      this.selectedClient.address.addressAsString
    ) {
      return this.selectedClient.address.addressAsString
    }

    return "-"
  }

  getNotes(): string {
    return this.notes || ""
  }

  setNotes(value: string): void {
    this.notes = value
    this.updateView()
  }

  getDate(): Date|null {
    return this.date
  }

  setDate(value: Date|null): void {
    this.date = value
    this.updateView()
  }

  getCurrentContainersAtClient(): ContainersAtClient | null {
    if (this.selectedClient == null) {
      return null
    }
    return this.selectedClient.containersAtClient
  }

  setNewOrderProducts(newOrderProducts: AddProductsProduct[]) {
    this.newOrderProducts = newOrderProducts
  }

  getCurrentProductsOfOrder(): AddProductsProduct[] {
    return this.currentOrderProducts.map((el): AddProductsProduct => {
      const product = this.products.find(p => el.id === p.id)
      return {
        orderProductsInput: {
          quantity: el.productQuantity,
          bruttoPrice: el.productBruttoPrice,
          isFreeProduct: el.isFreeProduct,
          productId: el.productId,
        },
        product: product || null,
        valid: true,
      }
    })
  }

  setNewOrderContainersToAdd(newOrderContainersToAdd: AddContainersContainer[]) {
    this.newOrderContainersToAdd = newOrderContainersToAdd.map(el => el.orderContainersInput)
  }

  getCurrentContainersOfOrder(): AddContainersContainer[] {
    return this.getOrderContainers().map((el): AddContainersContainer => {
      const container = this.containers.find(p => el.id === p.id)
      return {
        container: container || null,
        orderContainersInput: {
          quantity: el.containerQuantity,
          containerId: el.containerId,
          bruttoPrice: el.containerBruttoPrice,
        },
        valid: true,
      }
    })
  }

  private getNewProductsOfOrderBeforeSave(): OrderProductsInput[] {
    let products: OrderProductsInput[] = []

    if (this.newOrderProducts != null) {
      this.newOrderProducts.forEach(orderProduct => {
        const orderProductInput = orderProduct.orderProductsInput
        // check if already in array
        const index = products.findIndex(el => {
          return el.productId === orderProductInput.productId &&
            el.bruttoPrice === orderProductInput.bruttoPrice &&
            el.isFreeProduct === orderProductInput.isFreeProduct
        })
        if (index >= 0) {
          // add quantity
          products[index].quantity += orderProductInput.quantity
        } else {
          // add to array
          products.push({
            productId: orderProductInput.productId,
            quantity: orderProductInput.quantity,
            bruttoPrice: orderProductInput.bruttoPrice,
            isFreeProduct: orderProductInput.isFreeProduct,
          })
        }
      })
    } else {
      // have to get current products
      this.currentOrderProducts.forEach(orderProduct => {
        // check if already in array
        const index = products.findIndex(el => {
          return el.productId === orderProduct.productId &&
            el.bruttoPrice === orderProduct.productBruttoPrice &&
            el.isFreeProduct === orderProduct.isFreeProduct
        })
        if (index >= 0) {
          // add quantity
          products[index].quantity += orderProduct.productQuantity
        } else {
          // add to array
          products.push({
            productId: orderProduct.productId,
            quantity: orderProduct.productQuantity,
            bruttoPrice: orderProduct.productBruttoPrice,
            isFreeProduct: orderProduct.isFreeProduct,
          })
        }
      })
    }

    return products
  }

  private getNewContainersOfOrderBeforeSave(): OrderContainersInput[] {
    let containers: OrderContainersInput[] = []

    if (this.newOrderContainersToAdd != null) {
      this.newOrderContainersToAdd.forEach(orderContainer => {
        // check if already in array
        const index = containers.findIndex(el => {
          return el.containerId === orderContainer.containerId &&
            el.bruttoPrice === orderContainer.bruttoPrice
        })
        if (index >= 0) {
          // add quantity
          containers[index].quantity += orderContainer.quantity
        } else {
          // add to array
          containers.push({
            containerId: orderContainer.containerId,
            quantity: orderContainer.quantity,
            bruttoPrice: orderContainer.bruttoPrice
          })
        }
      })
    } else {
      // have to get current containers
      this.currentOrderContainers.forEach(orderContainer => {
        // check if already in array
        const index = containers.findIndex(el => {
          return el.containerId === orderContainer.containerId &&
            el.bruttoPrice === orderContainer.containerBruttoPrice
        })
        if (index >= 0) {
          // add quantity
          containers[index].quantity += orderContainer.containerQuantity
        } else {
          // add to array
          containers.push({
            containerId: orderContainer.containerId,
            quantity: orderContainer.containerQuantity,
            bruttoPrice: orderContainer.containerBruttoPrice
          })
        }
      })
    }

    return containers
  }

  protected formDataIsValid(): boolean {
    // products
    let numberOfOrderProducts = 0
    this.getNewProductsOfOrderBeforeSave().forEach(product => {
      numberOfOrderProducts += product.quantity
    })
    if (numberOfOrderProducts === 0) {
      alert("Ein Auftrag muss mindestens ein Produkt enthalten")
      return false
    }

    // containers
    let numberOfOrderContainers = 0
    this.getNewContainersOfOrderBeforeSave().forEach(container => {
      numberOfOrderContainers += container.quantity
    })

    // check if same number
    if (numberOfOrderContainers !== numberOfOrderProducts) {
      alert("Nicht gleich viele Gebinde wie Produkte")
      return false
    }

    // others
    if (
      this.selectedClient == null ||
      this.selectedOrderStatus == null
    ) {
      return false
    }

    return true
  }

  getIdOfCreatedObjectFromResponse(resp: any): string | null {
    if (resp && resp.resp && resp.resp.order && resp.resp.order.id) {
      return resp.resp.order.id
    }
    return null;
  }

  protected getCreateRequestData(): CreateOrderRequestData {
    const products = this.getNewProductsOfOrderBeforeSave()
    const containers = this.getNewContainersOfOrderBeforeSave()

    return {
      clientId: this.selectedClient!.id.toString(),
      orderStatusId: this.selectedOrderStatus!.id.toString(),
      date: this.date,
      notes: this.notes,
      products: products,
      containers: containers,
    };
  }

}