import React, {Component} from "react";
import OrderContainer from "../../Model/OrderContainer";
import OrderContainerDetailsRow from "./OrderContainerDetailsRow";


interface Props {
  orderContainers: OrderContainer[],
}
interface State {

}
export default class OrderContainersList extends Component<Props, State> {

  render() {
    const containersOfOrderElements: JSX.Element[] = []
    this.props.orderContainers.forEach(container => {
      // ignore elements with quantity 0
      if (container.containerQuantity === 0) {
        return
      }

      containersOfOrderElements.push(
        <OrderContainerDetailsRow
          containerInputData={{
            name: container.containerName,
            bruttoPrice: container.containerBruttoPrice,
            quantity: container.containerQuantity,
          }}
        />
      )
    })

    return (
      <div className={"order-containers-list"}>
        {containersOfOrderElements}
      </div>
    )
  }
}