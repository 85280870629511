import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";
import {UpdateOrderRequestData} from "../../Services/APIServiceGQLDocs/OrderDocs/UpdateOrderGQLDoc";


export default class OrderDetailsPageViewModel extends BaseViewModel {

  orderId: number
  order: Order|null = null

  constructor(
    orderId: number,
  ) {
    super();

    this.orderId = orderId
  }

  async loadOrder(): Promise<any> {
    this.enterLoadingProcess()
    this.updateView()

    const resp = await APIService.getInstance().loadOrder(
      this.orderId,
    )
    this.order = resp.order

    this.leaveLoadingProcess()
    this.updateView()
  }

  sendSaveRequest(data: UpdateOrderRequestData): Promise<any> {
    return APIService.getInstance().updateOrderRequest(data)
  }

}