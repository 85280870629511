import BaseVMComponent from "../BaseVMComponent";
import React, {Fragment} from "react";
import Container from "../../Model/Container";
import AddNewOrderContainerInputRow from "./AddContainersForm/AddNewOrderContainerInputRow";
import AddContainersFormViewModel, {
  AddContainersContainer
} from "../../ViewModels/Containers/AddContainersFormViewModel";


interface Props {
  inputDisabled?: boolean,
  onNewOrderContainersChange: (orderContainers: AddContainersContainer[]) => void,
  initialContainers?: AddContainersContainer[],
}
interface State {
}
export default class AddContainersForm extends BaseVMComponent<Props, State, any, AddContainersFormViewModel> {

  protected initViewModel(): void {
    this.viewModel = new AddContainersFormViewModel(
      this.props.initialContainers
    )
  }

  private onContainersChange() {
    this.props.onNewOrderContainersChange(this.viewModel.getNewOrderContainersToAdd())
  }

  private getContainerInputFields(): JSX.Element | null {
    let containerElements: JSX.Element[] = []

    if (this.props.inputDisabled) {
      return null
    }

    this.viewModel.getNewOrderContainersToAdd().forEach((newOrderContainerToAdd, index) => {
      const selectedContainer: Container | null = this.viewModel.getContainers().find(p => {
        return p.id === newOrderContainerToAdd.orderContainersInput.containerId
      }) || null

      containerElements.push(
        <AddNewOrderContainerInputRow
          key={"AddNewOrderProductInputRow-" + index + "-" + newOrderContainerToAdd.orderContainersInput.containerId}
          onContainerSelectionChange={(container) => {
            this.viewModel.setContainerIdOfNewOrderProductsToAdd(index, container)

            this.onContainersChange()
          }}
          selectableContainers={this.viewModel.getContainers()}
          selectedContainer={selectedContainer}
          quantity={this.viewModel.getQuantityOfNewOrderContainersToAdd(index)}
          onQuantityChange={(value) => {
            this.viewModel.setQuantityOfNewOrderContainersToAdd(index, value)

            this.onContainersChange()
          }}
          price={this.viewModel.getPriceOfNewOrderContainersToAdd(index)}
          onPriceChange={(value) => {
            this.viewModel.setPriceOfNewOrderContainersToAdd(index, value)

            this.onContainersChange()
          }}
          inputDisabled={false}
        />
      )
    })

    return (
      <Fragment>
        <div className={"mt-3"}>
          {containerElements}
          <button
            type="button"
            className="btn btn-secondary btn-sm mb-4"
            onClick={(e) => {
              this.viewModel.addNewOrderContainersToAdd()
              e.preventDefault()

              this.onContainersChange()
            }}
          >
            + Gebindeart hinzufügen
          </button>
        </div>
      </Fragment>
    )
  }

  render() {
    return (
      <div className={"order-content"}>
        { this.getContainerInputFields() }
      </div>
    );
  }
}
