import React, {Fragment} from "react";
import SignatureCanvas from "react-signature-canvas";
import {Button} from "react-bootstrap";
import BaseVMComponent from "../BaseVMComponent";
import {
  ContainerConfirmationContainer,
  ContainerConfirmationFormViewModel
} from "../../ViewModels/ContainerConfirmationFormViewModel";
import OrderContainerDetailsRow, {ContainerInputData} from "./OrderContainerDetailsRow";
import ReactSignatureCanvas from "react-signature-canvas";
import IBaseContainer from "../../Model/IBaseContainer";


interface Props {
  clientName: string,
  containerToConfirm: ContainerConfirmationContainer[],
  onCanvasChange: (isValid: boolean, base64String: string) => void,
}
interface State {
}
export default class ContainerConfirmationForm extends BaseVMComponent<Props, State, any, ContainerConfirmationFormViewModel> {

  private canvasRef: ReactSignatureCanvas | null


  constructor(props: Props) {
    super(props);

    this.canvasRef = null;
    this.state = {
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ContainerConfirmationFormViewModel(
      this.props.clientName,
      this.props.containerToConfirm,
    )
  }

  private getContainersToConfirmElements(): JSX.Element {
    let elements: JSX.Element[] = []
    this.viewModel.containersToConfirm.forEach(containerToConfirm => {
      if (containerToConfirm.containerInputData.quantity === 0) {
        return
      }
      elements.push(
        <OrderContainerDetailsRow
          key={"con-at-client-" + containerToConfirm.containerId}
          containerInputData={containerToConfirm.containerInputData}
        />
      )
    })

    if (elements.length === 0) {
      return (
        <p style={{color: "red"}}><b>Warnung:</b> kein Leergut eingetragen</p>
      )
    }
    return (
      <Fragment>
        {elements}
      </Fragment>
    )
  }

  private onClearSignatureClick() {
    if (this.canvasRef == null) {
      return
    }

    this.canvasRef.clear()

    this.onCanvasDrawEnd()
  }

  private onCanvasDrawEnd() {
    console.log(this.canvasRef!.toDataURL())
    console.log(this.canvasRef!.toData())

    this.props.onCanvasChange(this.canvasIsValid(), this.canvasRef!.toDataURL())
  }

  private canvasIsValid(): boolean {
    if (this.canvasRef == null) {
      return false
    }

    return !this.canvasRef.isEmpty()
  }

  render() {
    return (
      <div>
        <div className={"content"}>
          <h4>Leergutbestätigung Kunde</h4>
          <p>Hiermit bestätige ich, <b>{this.viewModel.getClientName()}</b>, im Besitz von folgendem Leergut der Rosenbergquelle zu sein:</p>
        </div>

        <div className={"containers row mb-4"}>
          <div className={"col-6"}>
            {this.getContainersToConfirmElements()}
          </div>
        </div>

        <div className={"signature mt-3"}>
          <h5>Unterschrift</h5>

          <div
            id={"canvas-wrapper"}
            style={{
              height: 202, // otherwise border is behind canvas
              display: "inline-block",
              border: "1px solid black",
              backgroundColor: "white",
            }}
          >
            <SignatureCanvas
              minWidth={1.5}
              maxWidth={1.5}
              ref={(ref) => { this.canvasRef = ref }}
              penColor={"#282828"}
              canvasProps={{
                width: 500,
                height: 200,
              }}
              onEnd={() => this.onCanvasDrawEnd()}
            />
          </div>

          <div className={"clear-button-wrapper mb-4"}>
            <Button
              type={"button"}
              variant={"danger"}
              onClick={() => this.onClearSignatureClick()}
            >
              Zurücksetzen
            </Button>
          </div>
        </div>
      </div>
    );
  }

}