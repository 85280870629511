import React, {Component} from "react";
import ContainerInputRow from "./ContainerInputRow";


export interface ContainerInputData {
  name: string,
  quantity: number,
  bruttoPrice: number,
}
interface Props {
  containerInputData: ContainerInputData,
}
interface State {

}
export default class OrderContainerDetailsRow extends Component<Props, State> {
  render() {
    return (
      <ContainerInputRow
        id={""}
        containerSelect={{
          idValue: this.props.containerInputData.name,
          options: [
            {
              value: this.props.containerInputData.name,
              label: this.props.containerInputData.name,
            }
          ],
          inputDisabled: true,
        }}
        quantityInput={{
          value: this.props.containerInputData.quantity,
          inputDisabled: true,
        }}
        priceInput={{
          value: this.props.containerInputData.bruttoPrice,
          inputDisabled: true,
        }}
        normalPrice={{
          value: this.props.containerInputData.bruttoPrice,
        }}
      />
    )
  }
}
