import BaseViewModel from "../BaseViewModel";


export default class ProductModalViewModel extends BaseViewModel {

  constructor(
  ) {
    super();
  }

}