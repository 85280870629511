import {gql} from "graphql-request";
import {ContainersAtClientDoc} from "../../ContainersAtClientGQLDocs";
import {OrderProductDocs} from "../../OrderProductDocs";
import {OrderContainersDocs} from "../../OrderContainersDocs";
import Client from "../../../../Model/Client";
import Address from "../../../../Model/Address";
import OrderStatus from "../../../../Model/OrderStatus";
import OrderProduct from "../../../../Model/OrderProduct";
import OrderContainer from "../../../../Model/OrderContainer";



export interface Order {
  id: number
  date: string|null
  notes: string|null
  isDone: boolean
  client: Client
  address: Address|null
  orderStatus: OrderStatus
  orderProducts: OrderProduct[]
  orderContainers: OrderContainer[]
}

export function IOrderDoc() {
  return gql`
    id
    date
    notes
    isDone
    
    client {
      id
      firstName
      lastName
      fullName
      
      address {
        id
        addressAsString
      }
      
      containersAtClient {
        ${ContainersAtClientDoc()}
      }
    }
    
    address {
      id
      addressAsString
    }
    
    orderStatus {
      id
      name
      queuePosition
    }
    
    ${OrderProductDocs()}
    
    ${OrderContainersDocs()}
  `
}

