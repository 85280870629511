import BaseVMComponent from "../BaseVMComponent";
import React from "react";
import CreateContainerButtonViewModel from "../../ViewModels/Containers/CreateContainerButtonViewModel";
import CreateContainerModal from "../Containers/CreateContainerModal";

interface Props {
  onSuccessfullySaved: () => void,
}
interface State {
  createModalOpen: boolean,
}
export default class CreateContainerButton extends BaseVMComponent<Props, State, any, CreateContainerButtonViewModel> {
  static defaultProps = {
    onSuccessfullySaved: () => {},
  }

  constructor(props: any) {
    super(props);

    this.state = {
      createModalOpen: false,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new CreateContainerButtonViewModel()
  }

  private onOpenModalButtonClick() {
    this.setState({
      createModalOpen: true,
    })
  }

  render() {
    return (
      <>
        <button
          className={"btn btn-primary"}
          onClick={this.onOpenModalButtonClick.bind(this)}
        >
          Neue Gebindeart erstellen
        </button>

        { this.state.createModalOpen &&
          <CreateContainerModal
            onModalClose={() => {
              this.setState({
                createModalOpen: false,
              })
            }}
            onSuccessfullySaved={() => {
              this.props.onSuccessfullySaved()
            }}
          />
        }
      </>
    );
  }
}