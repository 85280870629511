import React, {Fragment} from "react";
import BaseVMComponent from "../BaseVMComponent";
import {NavigateFunction, NavLink, useNavigate, useParams} from "react-router-dom";
import {Button} from "react-bootstrap";
import ContainerConfirmationForm from "../Shared/ContainerConfirmationForm";
import CustomContainerConfirmationPageViewModel, {
  NoSignatureError
} from "../../ViewModels/Pages/CustomContainerConfirmationPageViewModel";
import ContainersAtClientComponent from "../Shared/ContainersAtClientComponent";
import AddContainersForm from "../Containers/AddContainersForm";
import {AddContainersContainer} from "../../ViewModels/Containers/AddContainersFormViewModel";


interface _Props extends Props {
  navigation: NavigateFunction,
}
interface _State {
  submitSignatureButtonIsDisabled: boolean,
  step: number,
}
class _CustomContainerConfirmationPageComponent extends BaseVMComponent<_Props, _State, any, CustomContainerConfirmationPageViewModel> {

  constructor(props: _Props) {
    super(props);

    this.state = {
      submitSignatureButtonIsDisabled: true,
      step: 1,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new CustomContainerConfirmationPageViewModel(
      this.props.clientId
    )
  }

  componentDidMount() {
    this.viewModel.loadClient()
  }

  private onContainersChange(containers: AddContainersContainer[]) {
    this.viewModel.onNewOrderContainersChange(containers)
  }

  private onCanvasChange(isValid: boolean, base64String: string) {
    this.setState({
      submitSignatureButtonIsDisabled: !isValid,
    })

    if (isValid) {
      this.viewModel.setBase64StringOfSignature(base64String)
    } else {
      this.viewModel.setBase64StringOfSignature(null)
    }
  }

  private onContinueWithConfirmationClick() {
    if (this.viewModel.hasNoContainersSet()) {
      // eslint-disable-next-line no-restricted-globals
      const continueSave = confirm("Achtung! Wirklich ohne Pfandbesitz speichern?")
      if (!continueSave) {
        return
      }
    }

    this.setState({
      step: 2
    })
  }

  private getClientPageUrl(): string {
    return `/admin/clients/client/${this.props.clientId}`
  }

  private async onContinueWithoutConfirmationClick() {
    if (this.viewModel.hasNoContainersSet()) {
      // eslint-disable-next-line no-restricted-globals
      const continueSave = confirm("Achtung! Wirklich ohne Pfandbesitz speichern?")
      if (!continueSave) {
        return
      }
    }

    await this.viewModel.onSaveWithoutConfirmationClick()

    this.props.navigation(this.getClientPageUrl())
  }

  private async onSaveWithConfirmationClick() {
    try {
      await this.viewModel.onSaveWithConfirmationClick()

      this.props.navigation(this.getClientPageUrl())
    } catch (e) {
      if (e instanceof NoSignatureError) {
        alert("Keine Signature gegeben")
      }
    }
  }

  private getBreadCrumbs(): JSX.Element {
    let content: JSX.Element | null = null

    if (this.state.step === 1) {
      content = (
        <>
          <li className="breadcrumb-item">
            <NavLink to={this.getClientPageUrl()}>
              Mandantendetails
            </NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">Leergutbesitz ändern</li>
        </>
      )
    } else if (this.state.step === 2) {
      content = (
        <>
          <li className="breadcrumb-item">
            <NavLink to={this.getClientPageUrl()}>
              Mandantendetails
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <a
              href="javascript:void(0);"
              onClick={() => {
                this.setState({
                  step: 1,
                })
              }}
            >
              Leergutbesitz ändern
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">Leergut bestätigen</li>
        </>
      )
    }

    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {content}
        </ol>
      </nav>
    )
  }

  render(): JSX.Element {
    return (
      <div className={"mt-4"}>
        <div className={"mb-3"}>
          {this.getBreadCrumbs()}
        </div>
        <div className={"row"}>
          <div className={"col-12 mb-4"}>
            <h2>Leergutbesitz ändern</h2>
          </div>

          {!this.viewModel.loading && this.viewModel.client &&
            <>
              {this.state.step === 1 &&
                <>
                  <div className={"col-md-6"}>
                    <h3>Letzter Pfandbesitz</h3>
                    <ContainersAtClientComponent
                      containersAtClient={this.viewModel.client.containersAtClient}
                    />
                  </div>

                  <div className={"col-md-6"}>
                    <h3>Neuer Pfandbesitz</h3>

                    <div>
                      <AddContainersForm
                        onNewOrderContainersChange={(containers) => this.onContainersChange(containers)}
                        initialContainers={this.viewModel.getContainersInput()}
                      />
                    </div>

                    <div className={"mt-3"}>
                      <Button
                        type={"button"}
                        variant={"success"}
                        onClick={() => this.onContinueWithConfirmationClick()}
                      >
                        Pfandbesitz von Mandant bestätigen lassen
                      </Button>
                    </div>
                    <div className={"mt-3"}>
                      <Button
                        type={"button"}
                        variant={"primary"}
                        onClick={() => this.onContinueWithoutConfirmationClick()}
                      >
                        ohne Bestätigung speichern
                      </Button>
                    </div>
                  </div>
                </>
              }

              {this.state.step === 2 &&
                <div className={"col-12"}>
                  <ContainerConfirmationForm
                    clientName={this.viewModel.client.fullName}
                    containerToConfirm={this.viewModel.getContainerInputAsOrderContainers()}
                    onCanvasChange={(isValid, base64String) => this.onCanvasChange(isValid, base64String)}
                  />

                  <div className={"mt-3"}>
                    <Button
                      type={"button"}
                      variant={"success"}
                      disabled={this.state.submitSignatureButtonIsDisabled}
                      onClick={() => this.onSaveWithConfirmationClick()}
                    >
                      Pfandbesitz speichern
                    </Button>
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
    )
  }
}


interface Props {
  clientId: number,
}
const CustomContainerConfirmationPageComponent: any = (props: Props) => {
  const navigation = useNavigate();
  const { clientId } = useParams();

  return (
    <_CustomContainerConfirmationPageComponent
      {...props}
      clientId={parseInt(clientId!)}
      navigation={navigation}
    />
  )
}


export default CustomContainerConfirmationPageComponent;