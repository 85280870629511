import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import Container from "../../Model/Container";


export default class ContainersTableComponentViewModel extends BaseViewModel {

  private rowLimit: number|null
  loading: boolean
  containers: Container[]
  allOrdersCount: number
  page: number

  constructor(rowLimit: number|null) {
    super();

    this.loading = false
    this.containers = []
    this.allOrdersCount = 0
    this.page = 1

    this.rowLimit = rowLimit
  }

  setPage(page: number) {
    this.page = page
  }

  async loadContainers() {
    this.loading = true
    this.updateView()

    const resp = await APIService.getInstance().loadContainers(
      this.getRequestOffset(),
      this.getRequestLimit()
    )
    this.containers = resp.containers
    this.allOrdersCount = resp.count

    this.loading = false
    this.updateView()
  }

  getRequestOffset(): number {
    return this.getRequestLimit() * (this.page - 1)
  }

  getRequestLimit(): number {
    if (this.rowLimit != null) {
      return this.rowLimit
    }
    return 20
  }

}