import {GraphQLClient, RequestDocument} from "graphql-request";
import ConfigService from "../Services/ConfigService";


export interface IHTTPProvider {
  sendRequest(
    document: RequestDocument,
    variables?: any,
    headers?: any
  ): Promise<any>
}


export class GQLHTTPProvider implements IHTTPProvider {

  static getAPIEndPoint(): string {
    if (ConfigService.getAPIEndpoint()) {
      return ConfigService.getAPIEndpoint()!
    }
    return "http://localhost:8082"
  }

  private getGraphQLEndPoint(): string {
    return GQLHTTPProvider.getAPIEndPoint() + "/graphql"
  }

  private getGraphQLClient(): GraphQLClient {
    // noinspection UnnecessaryLocalVariableJS
    const graphQLClient = new GraphQLClient(this.getGraphQLEndPoint());
    return graphQLClient
  }

  sendRequest(
    document: RequestDocument,
    variables?: any,
    headers?: any
  ): Promise<any> {
    return this.getGraphQLClient().request(
      document,
      variables,
      headers
    );
  }

}