import React, {Fragment} from "react";
import BaseVMComponent from "../BaseVMComponent";
import {NavigateFunction, NavLink, useNavigate, useParams} from "react-router-dom";
import LoadingComponent from "../Shared/LoadingComponent";
import {Button} from "react-bootstrap";
import OrderDetailsPageViewModel from "../../ViewModels/Pages/OrderDetailsPageViewModel";
import OrderForm from "../Orders/OrderForm";


interface _Props extends Props {
  navigation: NavigateFunction
}
interface State {
  inEditMode: boolean,
}
class _OrderDetailsPage extends BaseVMComponent<_Props, State, any, OrderDetailsPageViewModel> {

  constructor(props: _Props) {
    super(props);

    this.state = {
      inEditMode: false,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new OrderDetailsPageViewModel(
      this.props.orderId
    )
  }

  async componentDidMount() {
    this.viewModel.loadOrder()
  }

  private onMarkAsDoneButtonClick() {
    this.props.navigation(`/admin/orders/order/${this.viewModel.orderId}/complete`)
  }

  render(): JSX.Element {
    return (
      <div className="content-header">
        <div className="container-fluid">
          <div className="row m-2">
            <div className="col-sm-12">
              <div className="App">
                <header className="App-header">
                  <div className={"row mb-3"}>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to={`/admin/orders`}>
                            Aufträge
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Auftragsdetails</li>
                      </ol>
                    </nav>
                  </div>

                  <div className={"row"}>
                    <div className={"col-8"}>
                      <h2>Auftragsdetails</h2>
                    </div>
                    <div className={"col-4 d-flex justify-content-end"}>
                      { this.viewModel.order?.isDone === false &&
                        <Fragment>
                          { !this.state.inEditMode &&
                            <button
                              className={"btn btn-outline-primary"}
                              onClick={() => {
                                this.setState({
                                  inEditMode: true,
                                })
                              }}
                            >
                              Bearbeiten
                            </button>
                          }
                          { this.state.inEditMode &&
                            <button
                              className={"btn btn-outline-secondary"}
                              onClick={() => {
                                this.setState({
                                  inEditMode: false,
                                })
                              }}
                            >
                              Schließen
                            </button>
                          }
                        </Fragment>
                      }
                    </div>
                  </div>

                  { !this.viewModel.isLoading() && this.viewModel.order?.isDone &&
                    <div className="alert alert-danger mt-3 text-center" role="alert">
                      Auftrag ist abgeschlossen
                    </div>
                  }

                  <div className="mt-4">
                    <LoadingComponent
                      shown={this.viewModel.isLoading()}
                    />

                    { !this.viewModel.isLoading() &&
                      <>
                        <OrderForm
                          formType={"edit"}
                          orderData={this.viewModel.order}
                          inputDisabled={!this.state.inEditMode}
                          onSuccessfullySaved={() => {
                            this.viewModel.loadOrder()
                          }}
                          sendSaveRequest={(data) => {
                            const _data: any = {...data}
                            _data.id = this.props.orderId
                            return this.viewModel.sendSaveRequest(_data)
                              .then(() => {
                                this.setState({
                                  inEditMode: false,
                                })
                              })
                          }}
                          buttons={
                            <div className={"mt-2 d-flex justify-content-end align-items-end"}>
                              { this.state.inEditMode &&
                                  <Button
                                    variant="primary"
                                    type={"submit"}
                                  >
                                    Speichern
                                  </Button>
                              }

                              { !this.state.inEditMode && !this.viewModel.order?.isDone &&
                                <Button
                                  variant="success"
                                  type={"button"}
                                  onClick={() => {
                                    this.onMarkAsDoneButtonClick()
                                  }}
                                >
                                  Erledigen
                                </Button>
                              }
                            </div>
                          }
                        />
                      </>
                    }
                  </div>
                </header>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


interface Props {
  orderId: number,
}
const OrderDetailsPage: any = (props: Props) => {
  const navigation = useNavigate();
  const { orderId } = useParams();

  return (
    <_OrderDetailsPage
      {...props}
      orderId={parseInt(orderId!)}
      navigation={navigation}
    />
  )
}


export default OrderDetailsPage;