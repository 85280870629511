import React from 'react';
import {Navigate, Route, Routes, Link} from "react-router-dom";
import App from "./App";
import {ProtectedRouteComponent} from "./Components/ProtectedRouteComponent";
import {AuthLayout} from "./Components/AuthLayoutComponent";
import AuthenticationService from "./Services/AuthenticationService";
import {UnauthenticatedRouteComponent} from "./Components/UnauthenticatedRouteComponent";
import LoginPageComponent from "./Components/Pages/LoginPageComponent";

function RouterWrapper() {
  return (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route path="/login" element={
          <UnauthenticatedRouteComponent>
            <LoginPageComponent />
          </UnauthenticatedRouteComponent>
        } />

        <Route path="/admin/" element={<Navigate to={"/admin/home"} />} />
        <Route path="/admin/*" element={
          <ProtectedRouteComponent>
            <App />
          </ProtectedRouteComponent>
        } />
      </Route>
    </Routes>
  );
}

export default RouterWrapper;
