import {gql} from "graphql-request";
import {IOrderDoc, Order} from "./interfaces/IOrderGQLDoc";
import {IItemOrder} from "../../APIServiceInterfaces/IItemOrder";


export interface OrdersRequestReqData {
  offset: number,
  limit: number,
  showDone: boolean,
  clientId?: number,
  itemOrder?: IItemOrder[],
}
export interface OrdersRequestResponse {
  orders: Order[],
  count: number,
}

export const getOrdersDoc = () => {
  return gql`
    query orders(
      $offset: Int!, 
      $limit: Int!,
      $clientId: ID,
      $showDone: Boolean!,
      $itemOrder: [ItemOrder],
    ) {
      orders(
        offset: $offset,
        limit: $limit,
        clientId: $clientId,
        showDone: $showDone,
        itemOrder: $itemOrder,
      ) {
        orders {
        ` +
        IOrderDoc()
        + `
        }
        count
      }
    }    
  `;
};

