import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {InputComponent} from "../../../Shared/InputComponent";
import {DateInputComponent} from "../../../Shared/DateInput";


export interface ClientFilterData {
  name: string | null,
  location: string | null,
  postalCode: string | null,
  street: string | null,
  lastOrderFrom: Date | null,
  lastOrderTo: Date | null,
  lastContainerConfirmationFrom: Date | null,
  lastContainerConfirmationTo: Date | null,
  containersAtClientFrom: number | null,
  containersAtClientTo: number | null,
}
interface Props {
  onSubmit: (filterData: ClientFilterData) => void,
  onReset: () => void,
  onClose: () => void,
  filterData: ClientFilterData,
}
interface State {
  filterData: ClientFilterData,
}
export default class ClientFilterFormModal extends Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      filterData: props.filterData,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.filterData !== this.props.filterData) {
      this.setState({
        filterData: {...this.props.filterData},
      })
    }
  }

  render() {
    return (
      <Modal
        show={true}
        size={"lg"}
        onEscapeKeyDown={() => this.props.onClose()}
      >
        <Modal.Header>
          <h3>Mandantensuche</h3>
        </Modal.Header>

        <Modal.Body>
          <form>
            <InputComponent
              labelTitle={"Name"}
              onValueChange={(value) => {
                const filterData = this.state.filterData
                filterData.name = value
                this.setState({
                  filterData: filterData,
                })
              }}
              type={"text"}
              value={this.state.filterData.name}
            />

            <InputComponent
              labelTitle={"Ort"}
              onValueChange={(value) => {
                const filterData = this.state.filterData
                filterData.location = value
                this.setState({
                  filterData: filterData,
                })
              }}
              type={"text"}
              value={this.state.filterData.location}
            />

            <InputComponent
              labelTitle={"PLZ"}
              onValueChange={(value) => {
                const filterData = this.state.filterData
                filterData.postalCode = value
                this.setState({
                  filterData: filterData,
                })
              }}
              type={"text"}
              value={this.state.filterData.postalCode}
            />

            <InputComponent
              labelTitle={"Straße"}
              onValueChange={(value) => {
                const filterData = this.state.filterData
                filterData.street = value
                this.setState({
                  filterData: filterData,
                })
              }}
              type={"text"}
              value={this.state.filterData.street}
            />


            <label>Datum letzte Bestellung</label>
            <div className={"row"}>
              <div className={"col-6"}>
                <DateInputComponent
                  labelTitle={"von"}
                  value={this.state.filterData.lastOrderFrom}
                  onChange={value => {
                    const filterData = this.state.filterData
                    filterData.lastOrderFrom = value
                    this.setState({
                      filterData: filterData,
                    })
                  }}
                  inputProps={{
                    hideLabel: true,
                  }}
                />
              </div>

              <div className={"col-6"}>
                <DateInputComponent
                  labelTitle={"bis"}
                  value={this.state.filterData.lastOrderTo}
                  onChange={value => {
                    const filterData = this.state.filterData
                    filterData.lastOrderTo = value
                    this.setState({
                      filterData: filterData,
                    })
                  }}
                  inputProps={{
                    hideLabel: true,
                  }}
                />
              </div>
            </div>


            <label>Datum letzte Pfandbestätigung</label>
            <div className={"row"}>
              <div className={"col-6"}>
                <DateInputComponent
                  labelTitle={"von"}
                  value={this.state.filterData.lastContainerConfirmationFrom}
                  onChange={value => {
                    const filterData = this.state.filterData
                    filterData.lastContainerConfirmationFrom = value
                    this.setState({
                      filterData: filterData,
                    })
                  }}
                  inputProps={{
                    hideLabel: true,
                  }}
                />
              </div>

              <div className={"col-6"}>
                <DateInputComponent
                  labelTitle={"bis"}
                  value={this.state.filterData.lastContainerConfirmationTo}
                  onChange={value => {
                    const filterData = this.state.filterData
                    filterData.lastContainerConfirmationTo = value
                    this.setState({
                      filterData: filterData,
                    })
                  }}
                  inputProps={{
                    hideLabel: true,
                  }}
                />
              </div>
            </div>


            <label>Pfand bei Kunde</label>
            <div className={"row"}>
              <div className={"col-6"}>
                <InputComponent
                  labelTitle={"von Anzahl Kisten"}
                  hideLabel={true}
                  onValueChange={(value) => {
                    const filterData = this.state.filterData
                    filterData.containersAtClientFrom = parseInt(value)
                    this.setState({
                      filterData: filterData,
                    })
                  }}
                  type={"number"}
                  value={(this.state.filterData.containersAtClientFrom && this.state.filterData.containersAtClientFrom.toString()) || null}
                />
              </div>

              <div className={"col-6"}>
                <InputComponent
                  labelTitle={"bis Anzahl Kisten"}
                  hideLabel={true}
                  onValueChange={(value) => {
                    const filterData = this.state.filterData
                    filterData.containersAtClientTo = parseInt(value)
                    this.setState({
                      filterData: filterData,
                    })
                  }}
                  type={"number"}
                  value={(this.state.filterData.containersAtClientTo && this.state.filterData.containersAtClientTo.toString()) || null}
                />
              </div>
            </div>

            <div className={"buttons mt-2 d-flex justify-content-between"}>
              <div>
                <Button
                  className={"mr-1"}
                  variant={"primary"}
                  onClick={() => this.props.onSubmit(this.state.filterData)}
                  type={"submit"}
                >
                  Suchen
                </Button>
                <Button
                  className={"ml-1"}
                  variant={"warning"}
                  onClick={() => this.props.onReset()}
                >
                  Zurücksetzen
                </Button>
              </div>

              <div>
                <Button
                  variant={"secondary"}
                  onClick={() => this.props.onClose()}
                >
                  Schließen
                </Button>
              </div>
            </div>
          </form>

        </Modal.Body>
      </Modal>
    );
  }
}