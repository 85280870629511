import BaseViewModel from "./BaseViewModel";


export default class TableComponentViewModel extends BaseViewModel {

  getNumberOfPages(
    limit: number,
    total: number
  ): number {
    return Math.ceil(total / limit)
  }

}