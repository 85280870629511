import React, {Component} from "react";
import IBaseProduct from "../../../Model/IBaseProduct";
import ProductInputRow from "../../Shared/ProductInputRow";


interface Props {
  product: IBaseProduct,
  quantity: number,
  price: number,
  isFreeProduct: boolean,
}
interface State {

}
export default class OrderProductDetailsRow extends Component<Props, State> {
  render() {
    const product = this.props.product

    return (
      <ProductInputRow
        id={product.id}
        productSelect={{
          idValue: product?.id || null,
          options: [
            {
              value: product.id,
              label: product.productName,
            }
          ],
          inputDisabled: true,
        }}
        quantityInput={{
          value: this.props.quantity,
          inputDisabled: true,
        }}
        priceInput={{
          value: this.props.price,
          inputDisabled: true,
        }}
        isFreeCheckbox={{
          checked: this.props.isFreeProduct,
          inputDisabled: true,
        }}
        normalPrice={{
          value: this.props.product.productBruttoPrice,
        }}
      />
    )
  }
}