import BaseViewModel from "../../BaseViewModel";
import {DateService} from "../../../Services/DateService";
import {AddContainersContainer} from "../../Containers/AddContainersFormViewModel";
import ContainersAtClient from "../../../Model/ContainersAtClient";
import {Order} from "../../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";


export default class OrderBillViewModel extends BaseViewModel {
  orderId: number
  order: Order
  containersAtClient: ContainersAtClient | null

  private containersToReturn: AddContainersContainer[]


  constructor(
    orderId: number,
    order: Order,
    containersAtClient: ContainersAtClient | null,
    containersToReturn: AddContainersContainer[] | null,
  ) {
    super();

    this.orderId = orderId
    this.order = order
    this.containersAtClient = containersAtClient

    if (containersToReturn == null) {
      this.containersToReturn = []

      if (this.order == null || this.order.client.containersAtClient == null) {
        return
      }
      // set initial value
      this.order.client.containersAtClient.containers.forEach(containerAtClient => {
        this.containersToReturn.push({
          container: {
            id: containerAtClient.containerId.toString(),
            name: containerAtClient.containerName,
            basePrice: containerAtClient.containerNettoPrice,
            taxRate: containerAtClient.containerTaxRate,
            bruttoPrice: containerAtClient.containerBruttoPrice,
          },
          orderContainersInput: {
            containerId: containerAtClient.containerId.toString(),
            quantity: containerAtClient.containerQuantity,
            bruttoPrice: containerAtClient.containerBruttoPrice,
          },
          valid: true,
        })
      })
      this.updateView()
    } else {
      this.containersToReturn = containersToReturn
    }
  }

  hasContainersAtClient(): boolean {
    const containersAtClient = this.order!.client.containersAtClient
    return containersAtClient != null
  }

  getContainersAtClientDate(): string | null {
    const containersAtClient = this.order!.client.containersAtClient
    if (containersAtClient == null) {
      return null
    }

    return DateService.getShortDateString(new Date(containersAtClient.date))
  }

  getContainersToReturn(): AddContainersContainer[] {
    return this.containersToReturn
  }

  setContainersToReturn(containers: AddContainersContainer[]) {
    this.containersToReturn = containers
    this.updateView()
  }
}