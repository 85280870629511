import BaseViewModel from "../../BaseViewModel";
import APIService from "../../../Services/APIService";
import {ContainerConfirmationContainer} from "../../ContainerConfirmationFormViewModel";
import {Order} from "../../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";
import {
  MarkOrderAsDoneData_containersToConfirm
} from "../../../Services/APIServiceGQLDocs/OrderDocs/MarkOrderAsDoneGQLDoc";


export default class CompleteOrderViewModel extends BaseViewModel {
  order: Order
  containersToConfirm: ContainerConfirmationContainer[]


  constructor(
    order: Order,
    containersToConfirm: ContainerConfirmationContainer[]
  ) {
    super();

    this.order = order
    this.containersToConfirm = containersToConfirm
  }

  async onMarkAsDoneButtonClick(): Promise<any> {
    const markOrderAsDoneData_containersToReturn: MarkOrderAsDoneData_containersToConfirm[] = []

    this.containersToConfirm.forEach(el => {
      markOrderAsDoneData_containersToReturn.push({
        containerId: parseInt(el.containerId),
        containerName: el.containerInputData.name,
        containerQuantity: el.containerInputData.quantity,
        containerBruttoPrice: el.containerInputData.bruttoPrice,
      })
    })

    await APIService.getInstance().markOrderAsDone({
      id: this.order.id,
      base64StringOfSignature: null,
      containersToConfirm: markOrderAsDoneData_containersToReturn,
    })
  }

}