import BaseVMComponent from "../BaseVMComponent";
import React, {FormEvent} from "react";
import {InputComponent} from "../Shared/InputComponent";
import ProductFormViewModel from "../../ViewModels/Products/ProductFormViewModel";
import {CreateProductRequestData} from "../../Services/APIServiceInterfaces/Product";
import LoadingComponent from "../Shared/LoadingComponent";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";


interface Props {
  orderData: Order|null,
  onSuccessfullySaved: (closeAfterSave: boolean) => void,
  sendSaveRequest: (data: CreateProductRequestData) => Promise<any>,
  inputDisabled: boolean,
  buttons: JSX.Element,
}
interface State {
}
export default class ProductForm extends BaseVMComponent<Props, State, any, ProductFormViewModel> {
  static defaultProps = {
    orderData: null,
    inputDisabled: false,
    buttons: null,
  }


  protected initViewModel(): void {
    this.viewModel = new ProductFormViewModel(
      this.props.sendSaveRequest
    )
  }

  private onSuccessfullySaved(closeAfterSave: boolean) {
    this.props.onSuccessfullySaved(closeAfterSave)
  }

  private async onSaveClick(e: FormEvent) {
    const closeAfterSave = (e.nativeEvent as any).submitter?.dataset.saveAndClose != null

    await this.viewModel.onSaveClick(
      closeAfterSave,
      this.onSuccessfullySaved.bind(this)
    )
  }

  render() {
    return (
      <form
        className={"position-relative"}
        onSubmit={(e) => {
          this.onSaveClick(e)
          e.preventDefault()
        }}
      >
        <div
          className={"data-input-wrapper"}
        >
          <InputComponent
            labelTitle={"Name"}
            type={"text"}
            value={this.viewModel.getName()}
            onValueChange={(value) => this.viewModel.setName(value)}
            required={true}
          />
          <InputComponent
            labelTitle={"Nettopreis"}
            type={"number"}
            value={this.viewModel.getNettoPrice()}
            onValueChange={(value) => this.viewModel.setNettoPrice(value)}
            required={true}
          />
          <InputComponent
            labelTitle={"Steuersatz (in %)"}
            type={"number"}
            value={this.viewModel.getTaxRate()}
            onValueChange={(value) => this.viewModel.setTaxRate(value)}
            required={true}
          />
          <InputComponent
            labelTitle={"Bruttopreis"}
            type={"number"}
            value={this.viewModel.getBruttoPrice()}
            onValueChange={() => {}}
            disabled={true}
            required={true}
          />
        </div>


        { this.viewModel.successfullySave.visible &&
          <div className="alert alert-success mt-4" role="alert">
            Produkt wurde erfolgreich gespeichert
          </div>
        }
        { this.viewModel.error.visible &&
          <div className="alert alert-danger mt-4" role="alert">
            { this.viewModel.error.string }
          </div>
        }

        <div className={"buttons"}>
          { this.props.buttons }
        </div>

        <LoadingComponent shown={this.viewModel.saveIsLoading} positionAbsolute={true} />
      </form>
    );
  }
}


