import BaseVMComponent from "../BaseVMComponent";
import React, {FormEvent} from "react";
import OrderForm from "../Orders/OrderForm";
import {Button} from "react-bootstrap";
import CreateOrderPageViewModel from "../../ViewModels/Pages/CreateOrderPageViewModel";
import {NavigateFunction, NavLink, useNavigate, useParams} from "react-router-dom";


interface _Props extends Props {
  navigation: NavigateFunction,
}
interface State {
}
class _CreateOrderPage extends BaseVMComponent<_Props, State, any, CreateOrderPageViewModel> {
  static defaultProps = {
  }


  protected initViewModel(): void {
    this.viewModel = new CreateOrderPageViewModel()
  }

  render() {
    return (
      <div className="content-header">
        <div className="container-fluid">
          <div className="row m-2">
            <div className="col-sm-12">
              <div className="App">
                <header className="App-header">
                  <div className={"row mb-3"}>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to={`/admin/orders`}>
                            Aufträge
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Auftrag erstellen</li>
                      </ol>
                    </nav>
                  </div>

                  <div className={"row"}>
                    <div className={"col-md-8"}>
                      <h3>Auftrag erstellen</h3>
                    </div>
                  </div>
                </header>

                <OrderForm
                  formType={"create"}
                  onSuccessfullySaved={(closeAfterSave: boolean, newIdValue: string | null) => {
                    if (newIdValue) {
                      this.props.navigation("/admin/orders/order/" + newIdValue)
                    }
                  }}
                  sendSaveRequest={(data) => {
                    return this.viewModel.sendSaveRequest(data)
                  }}
                  inputDisabled={false}
                  buttons={
                    <div className={"d-flex mt-4 justify-content-between"} style={{ columnGap: 10 }}>
                      <div className={"d-flex justify-content-end"} style={{ columnGap: 10 }}>
                        <>
                          <Button
                            variant="primary"
                            type={"submit"}
                          >
                            Speichern
                          </Button>
                        </>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface Props {
}
export default function CreateOrderPage(props: Props) {
  const navigation = useNavigate();

  return (
    <_CreateOrderPage
      {...props}
      navigation={navigation}
    />
  )
}