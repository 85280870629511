import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import Product from "../../Model/Product";


export default class ProductsTableComponentViewModel extends BaseViewModel {

  private rowLimit: number|null
  products: Product[]
  allOrdersCount: number
  page: number

  constructor(rowLimit: number|null) {
    super();

    this.products = []
    this.allOrdersCount = 0
    this.page = 1

    this.rowLimit = rowLimit
  }

  setPage(page: number) {
    this.page = page
  }

  async loadProducts() {
    this.enterLoadingProcess()
    this.updateView()

    this.products = []
    const resp = await APIService.getInstance().loadProducts(
      this.getRequestOffset(),
      this.getRequestLimit()
    )
    resp.products.forEach(prod => {
      this.products.push({
        id: prod.id.toString(),
        productName: prod.name,
        productBruttoPrice: prod.bruttoPrice,
        productNettoPrice: prod.basePrice,
        taxRate: prod.taxRate,
      })
    })
    this.allOrdersCount = resp.count

    this.leaveLoadingProcess()
    this.updateView()
  }

  getRequestOffset(): number {
    return this.getRequestLimit() * (this.page - 1)
  }

  getRequestLimit(): number {
    if (this.rowLimit != null) {
      return this.rowLimit
    }
    return 20
  }

}