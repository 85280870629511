import { Navigate } from "react-router-dom";
import {useAuth} from "../Context/AuthContext";


interface ProtectedRouteComponentProps {
  children: any
}
export const ProtectedRouteComponent = (props: ProtectedRouteComponentProps) => {
  const { userIsLoggedIn } = useAuth();

  if (!userIsLoggedIn()) {
    // user is not authenticated
    console.warn("Not authenticated")
    return <Navigate to="/login" />;
  }

  return props.children;
};
