import {CSSProperties} from "react";

export const ProductInputRowStyles = {
  productsInputRow: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    display: "grid",
    gridTemplateColumns: '30% 20% 30% 20%',
  },
  productQuantityInput: (args?: { lightBg: boolean }) => {
    const style: CSSProperties = {
      flex: 5,
      margin: 0,
      alignItems: "center",
    }
    if (args?.lightBg) {
      style.opacity = 0.5
    }
    return style
  },
  productPriceInput: {
    flex: 2,
    margin: 0,
    alignItems: "center",
  },
  productInputLabel: {
    margin: 0,
    padding: "0 1rem 0 0",
  },
  productInputInput: {
  },
  productPriceSpan: {
    flex: 3,
    paddingLeft: "1rem",
    color: "gray",
  },
  priceWrapper: {
    flex: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
  },
  priceWrapperSpan: {
    marginRight: 5,
  },
}
