import BaseViewModel from "./BaseViewModel";
import IBaseContainer from "../Model/IBaseContainer";
import {ContainerInputData} from "../Components/Shared/OrderContainerDetailsRow";
import exp from "constants";

export interface ContainerConfirmationContainer {
  containerId: string,
  containerInputData: ContainerInputData,
}
export class ContainerConfirmationFormViewModel extends BaseViewModel {

  loading: boolean = true
  clientName: string
  containersToConfirm: ContainerConfirmationContainer[]


  constructor(
    clientName: string,
    containersToConfirm: ContainerConfirmationContainer[],
  ) {
    super();

    this.clientName = clientName
    this.containersToConfirm = containersToConfirm
  }

  getClientName(): string {
    if (this.clientName == null) {
      return ""
    }

    return this.clientName
  }

}