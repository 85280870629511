import React from "react";
import BaseVMComponent from "../BaseVMComponent";
import {NavLink, useParams} from "react-router-dom";
import ContainersAtClientPageViewModel from "../../ViewModels/Pages/ContainersAtClientPageViewModel";
import ContainersAtClientTable from "../Clients/ContainersAtClientTable";


interface Props {
  clientId: number,
}
interface State {
}
class _ContainersAtClientPage extends BaseVMComponent<Props, State, any, ContainersAtClientPageViewModel> {

  constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ContainersAtClientPageViewModel(
      this.props.clientId
    )
  }

  render(): JSX.Element {
    return (
      <div className={"mt-4"}>
        <div className={"mb-3"}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to={`/admin/clients/client/${this.props.clientId}`}>
                  Mandantendetails
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Letzte Pfandbestätigungen</li>
            </ol>
          </nav>
        </div>

        <div className={"row"}>
          <div className={"col-12"}>
            <h3>Letzte Pfandbestätigungen</h3>
          </div>
        </div>

        <div className="mt-4">
          <ContainersAtClientTable
            clientId={this.props.clientId}
          />
        </div>
      </div>
    )
  }
}


const ContainersAtClientPage: any = () => {
  const { clientId } = useParams();

  return (
    <_ContainersAtClientPage
      clientId={parseInt(clientId!)}
    />
  )
}


export default ContainersAtClientPage;