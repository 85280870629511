import BaseVMComponent from "../BaseVMComponent";
import React, {FormEvent} from "react";
import Client from "../../Model/Client";
import ClientFormViewModel from "../../ViewModels/Clients/ClientFormViewModel";
import {InputComponent} from "../Shared/InputComponent";
import {CreateClientRequestData} from "../../Services/APIServiceInterfaces/Client";
import LoadingComponent from "../Shared/LoadingComponent";


interface Props {
  clientData: Client|null,
  onSuccessfullySaved: (closeAfterSave: boolean) => void,
  sendSaveRequest: (data: CreateClientRequestData) => Promise<any>,
  inputDisabled: boolean,
  buttons: JSX.Element,
}
interface State {
}
export default class ClientForm extends BaseVMComponent<Props, State, any, ClientFormViewModel> {
  static defaultProps = {
    clientData: null,
    inputDisabled: false,
    buttons: null,
  }

  constructor(props: any) {
    super(props);
  }

  protected initViewModel(): void {
    this.viewModel = new ClientFormViewModel(
      this.props.sendSaveRequest
    )
    if (this.props.clientData != null) {
      this.viewModel.setCompanyName(this.props.clientData.companyName)
      this.viewModel.setFirstname(this.props.clientData.firstName)
      this.viewModel.setLastName(this.props.clientData.lastName)
      this.viewModel.setLeadingTitle(this.props.clientData.leadingTitle)
      this.viewModel.setTrailingTitle(this.props.clientData.trailingTitle)
      this.viewModel.setPhone(this.props.clientData.phone)
      this.viewModel.setMail(this.props.clientData.mail)
      if (this.props.clientData.isPrivateCustomer) {
        this.viewModel.setIsPrivateClient()
      } else {
        this.viewModel.setIsCompanyClient()
      }
      this.viewModel.setNotes(this.props.clientData.notes)

      // address
      if (this.props.clientData.address) {
        this.viewModel.setStreet(this.props.clientData.address.street)
        this.viewModel.setLocation(this.props.clientData.address.location)
        this.viewModel.setPostalCode(this.props.clientData.address.postalCode)
      }
    }
  }

  private onSuccessfullySaved(closeAfterSave: boolean) {
    this.props.onSuccessfullySaved(closeAfterSave)
  }

  private async onSaveClick(e: FormEvent) {
    const closeAfterSave = (e.nativeEvent as any).submitter?.dataset.saveAndClose != null

    await this.viewModel.onSaveClick(
      closeAfterSave,
      this.onSuccessfullySaved.bind(this)
    )
  }

  render() {
    return (
      <form
        onSubmit={(e) => {
          this.onSaveClick(e)
          e.preventDefault()
        }}
      >
        <div
          className={"data-input-wrapper"}
        >
          <div
            className="btn-group mb-3"
            role="group"
            aria-label="Basic outlined example"
            style={{ width: "100%" }}
          >
            <button
              type="button"
              className={["btn btn-outline-primary btn-sm", this.viewModel.isPrivateCustomer ? "active" : ""].join(" ")}
              onClick={() => {
                this.viewModel.setIsPrivateClient()
              }}
              style={{
                width: "50%",
              }}
              disabled={this.props.inputDisabled}
            >
              Privatkunde
            </button>
            <button
              type="button"
              className={["btn btn-outline-primary btn-sm", !this.viewModel.isPrivateCustomer ? "active" : ""].join(" ")}
              onClick={() => {
                this.viewModel.setIsCompanyClient()
              }}
              style={{
                width: "50%",
              }}
              disabled={this.props.inputDisabled}
            >
              Firmenkunde
            </button>
          </div>

          <InputComponent
            labelTitle={"Firmenname"}
            type={"text"}
            value={this.viewModel.companyName}
            onValueChange={(value) => this.viewModel.setCompanyName(value)}
            disabled={this.props.inputDisabled || this.viewModel.isPrivateCustomer}
          />
          <InputComponent
            labelTitle={"Vorname"}
            type={"text"}
            value={this.viewModel.firstName}
            onValueChange={(value) => this.viewModel.setFirstname(value)}
            disabled={this.props.inputDisabled}
          />
          <InputComponent
            labelTitle={"Nachname"}
            type={"text"}
            value={this.viewModel.lastName}
            onValueChange={(value) => this.viewModel.setLastName(value)}
            disabled={this.props.inputDisabled}
          />

          <InputComponent
            labelTitle={"Titel (führend)"}
            type={"text"}
            value={this.viewModel.leadingTitle}
            onValueChange={(value) => this.viewModel.setLeadingTitle(value)}
            disabled={this.props.inputDisabled}
          />
          <InputComponent
            labelTitle={"Titel (nachstehend)"}
            type={"text"}
            value={this.viewModel.trailingTitle}
            onValueChange={(value) => this.viewModel.setTrailingTitle(value)}
            disabled={this.props.inputDisabled}
          />

          <InputComponent
            labelTitle={"Mobil"}
            type={"tel"}
            value={this.viewModel.phone}
            onValueChange={(value) => this.viewModel.setPhone(value)}
            disabled={this.props.inputDisabled}
          />
          <InputComponent
            labelTitle={"E-Mail"}
            type={"email"}
            value={this.viewModel.mail}
            onValueChange={(value) => this.viewModel.setMail(value)}
            disabled={this.props.inputDisabled}
          />
        </div>

        <div className={"address-wrapper mt-5"}>
          <div className={"row"}>
            <div className={"col-md-8"}>
              <h4>Addresse</h4>
            </div>
            <div className={"col-md-4 d-flex justify-content-end align-items-center"}>
              { this.viewModel.getGoogleMapsLink() &&
                <a
                  href={this.viewModel.getGoogleMapsLink()!}
                  className={"btn btn-outline-secondary btn-sm"}
                  target={"_blank"}
                >
                  In Google Maps öffnen
                </a>
              }
            </div>
          </div>

          <InputComponent
            labelTitle={"Straße"}
            type={"text"}
            value={this.viewModel.street}
            onValueChange={(value) => this.viewModel.setStreet(value)}
            disabled={this.props.inputDisabled}
            required={true}
          />
          <InputComponent
            labelTitle={"PLZ"}
            type={"text"}
            value={this.viewModel.postalCode}
            onValueChange={(value) => this.viewModel.setPostalCode(value)}
            disabled={this.props.inputDisabled}
            required={true}
          />
          <InputComponent
            labelTitle={"Ort"}
            type={"text"}
            value={this.viewModel.location}
            onValueChange={(value) => this.viewModel.setLocation(value)}
            disabled={this.props.inputDisabled}
            required={true}
          />
        </div>

        <div className={"notes-wrapper mt-5"}>
          <h4>Notizen</h4>

          <InputComponent
            labelTitle={"Notizen"}
            hideLabel={true}
            type={"textarea"}
            value={this.viewModel.notes}
            onValueChange={(value) => this.viewModel.setNotes(value)}
            disabled={this.props.inputDisabled}
            required={false}
          />
        </div>

        { this.viewModel.successfullySave.visible &&
          <div className="alert alert-success mt-4" role="alert">
            Mandant wurde erfolgreich gespeichert
          </div>
        }
        { this.viewModel.error.visible &&
          <div className="alert alert-danger mt-4" role="alert">
            { this.viewModel.error.string }
          </div>
        }

        <div className={"buttons"}>
          { this.props.buttons }
        </div>

        <LoadingComponent shown={this.viewModel.saveIsLoading} positionAbsolute={true} />
      </form>
    );
  }
}


