import {gql} from "graphql-request";


export interface OrderProductsInput {
  productId: string
  quantity: number
  bruttoPrice: number
  isFreeProduct: boolean
}

export const OrderProductDocs = () => {
  return gql`
    orderProducts {
      id
      orderId
      productId
      productName
      productQuantity
      productNettoPrice
      productBruttoPrice
      isFreeProduct
    }
  `
}
