import BaseViewModel from "./BaseViewModel";
import APIService from "../Services/APIService";
import {CreateClientRequestData} from "../Services/APIServiceInterfaces/Client";


export default class CreateClientModalViewModel extends BaseViewModel {

  sendSaveRequest(data: CreateClientRequestData): Promise<any> {
    console.log(data)
    return APIService.getInstance().createClientRequest(data)
  }

}