import React from "react";
import BaseVMComponent from "../BaseVMComponent";
import ProductsManagementPageViewModel from "../../ViewModels/Products/ProductsManagementPageViewModel";
import ProductsTableComponent from "./ProductsTableComponent";
import CreateProductButton from "../Shared/CreateProductButton";


interface State {
}
class ProductsManagementPageComponent extends BaseVMComponent<any, State, any, ProductsManagementPageViewModel> {

  private readonly tableRef: any

  constructor(props: any) {
    super(props);

    this.tableRef = React.createRef();
  }

  protected initViewModel(): void {
    this.viewModel = new ProductsManagementPageViewModel()
  }

  private onSuccessfullySaved() {
    (this.tableRef.current as any).reloadOrders()
  }

  render(): JSX.Element {
    return (
      <div className="content-header">
        <div className="container-fluid">
          <div className="row m-2">
            <div className="col-sm-12">
              <div className="App">
                <header className="App-header">
                  <div className={"row"}>
                    <div className={"col-md-8"}>
                      <h2>Produktverwaltung</h2>
                    </div>
                    <div className={"col-md-4 d-flex justify-content-end"}>
                      <CreateProductButton
                        onSuccessfullySaved={this.onSuccessfullySaved.bind(this)}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <ProductsTableComponent
                      innerRef={this.tableRef}
                    />
                  </div>
                </header>
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
    )
  }
}

export default ProductsManagementPageComponent;