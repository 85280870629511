import {gql} from "graphql-request";
import {IOrderDoc, Order} from "./interfaces/IOrderGQLDoc";
import {OrderProductsInput} from "../OrderProductDocs";
import {OrderContainersInput} from "../OrderContainersDocs";


export interface UpdateOrderRequestData {
  id: string,
  clientId?: string|null
  orderStatusId?: string|null
  date?: Date|null,
  notes?: string|null,
  products?: OrderProductsInput[],
  containers?: OrderContainersInput[],
}
export declare type UpdateOrderRequestResponse = {
  order: Order,
}

export const updateOrderDoc = () => {
  return gql`
    mutation updateOrder(
      $id: ID!
      $clientId: ID
      $orderStatusId: ID
      $date: String
      $notes: String
      $products: [OrderProductsInput!]
      $containers: [OrderContainersInput!]
    ) {
      updateOrder(
        id: $id
        clientId: $clientId
        orderStatusId: $orderStatusId
        date: $date
        notes: $notes
        products: $products
        containers: $containers
      ) {
        ` +
    IOrderDoc()
    + `
      }
    }  
  `;
};

