import React, {Fragment} from "react";
import {Button} from "react-bootstrap";
import BaseVMComponent from "../../BaseVMComponent";
import CompleteOrderViewModel from "../../../ViewModels/Pages/Client/CompleteOrderViewModel";
import LoadingComponent from "../../Shared/LoadingComponent";
import {ContainerConfirmationContainer} from "../../../ViewModels/ContainerConfirmationFormViewModel";
import OrderContainerDetailsRow from "../../Shared/OrderContainerDetailsRow";
import {Order} from "../../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";


interface Props {
  order: Order,
  containersToConfirm: ContainerConfirmationContainer[],
  onContinueWithoutConfirmationClick: () => void,
  onGoToConfirmationClick: () => void,
}
interface State {
}
export default class CompleteOrderComponent extends BaseVMComponent<Props, State, any, CompleteOrderViewModel> {


  constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  protected initViewModel(): void {
    this.viewModel = new CompleteOrderViewModel(
      this.props.order,
      this.props.containersToConfirm
    )
  }

  private async onContinueWithoutConfirmationClick() {
    await this.viewModel.onMarkAsDoneButtonClick()

    this.props.onContinueWithoutConfirmationClick()
  }

  private onGoToConfirmationClick() {
    this.props.onGoToConfirmationClick()
  }

  private getContainersToConfirmElements(): JSX.Element {
    let elements: JSX.Element[] = []
    this.viewModel.containersToConfirm.forEach(containerToConfirm => {
      if (containerToConfirm.containerInputData.quantity === 0) {
        return
      }
      elements.push(
        <OrderContainerDetailsRow
          key={"con-at-client-" + containerToConfirm.containerId}
          containerInputData={containerToConfirm.containerInputData}
        />
      )
    })

    if (elements.length === 0) {
      return (
        <p style={{color: "red"}}><b>Warnung:</b> kein Leergut eingetragen</p>
      )
    }
    return (
      <div className={"row"}>
        <div className={"col-md-6"}>
          {elements}
        </div>
      </div>
    )
  }

  render(): JSX.Element {
    return (
      <div className="">
        <LoadingComponent
          shown={this.viewModel.isLoading()}
        />

        {!this.viewModel.isLoading() &&
          <>
            <div className={"mt-4 mb-4"}>
              <h4>Leergutbestätigung Kunde</h4>
              <p>Gebindestand <b>nach Auftrag</b>:</p>

              { this.getContainersToConfirmElements() }
            </div>

            <div className={"mt-3"}>
              <Button
                type={"button"}
                variant={"success"}
                onClick={() => this.onGoToConfirmationClick()}
              >
                Pfand bestätigen lassen
              </Button>
            </div>

            <div className={"mt-3"}>
              <Button
                type={"button"}
                variant={"primary"}
                onClick={() => this.onContinueWithoutConfirmationClick()}
              >
                Auftrag ohne Pfandbestätigung abschließen
              </Button>
            </div>
          </>
        }
      </div>
    )
  }
}


