import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {DropDownComponent} from "../Shared/DropDownComponent";
import BaseVMComponent from "../BaseVMComponent";
import HomePageViewModel from "../../ViewModels/HomePageViewModel";
import AllOrdersTableComponent from "../Orders/AllOrdersTableComponent";
import ProductsTableComponent from "../Products/ProductsTableComponent";
import AllContainersAtClientsList from "../Shared/AllContainersAtClientsList";


interface Props {
  navigation: any,
}

class _HomePageComponent extends BaseVMComponent<Props, any, any, HomePageViewModel> {

  protected initViewModel() {
    this.viewModel = new HomePageViewModel()
  }

  render(): JSX.Element {
    return (
      <div className="content-header">
        <div className="container-fluid">
          <div className="row m-2">
            <div className="col-sm-12">
              <div className="App">
                <header className="App-header">
                  <h2>Rosenbergquelle CRM</h2>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="card card-primary card-outline">
                        <div className="card-header">
                          <h5 className="m-0">Mandantschnellsuche</h5>
                        </div>
                        <div className="card-body">
                          <DropDownComponent
                            onValueChange={newIdValue => {
                              // navigate to details page
                              this.props.navigation("/admin/clients/client/" + newIdValue)
                            }}
                            options={this.viewModel.getClientOptions()}
                            labelTitle={"Mandantenschnellsuche"}
                            idValue={null}
                            disabled={false}
                            onInputValueChange={newIdValue => {
                              this.viewModel.onClientDropDownInputValueChange(newIdValue)
                            }}
                          />

                          <NavLink
                            to={"/admin/clients"}
                          >
                            <span className="btn btn-primary">zur Mandantenliste</span>
                          </NavLink>
                        </div>
                      </div>

                      <div className="card card-primary card-outline">
                        <div className="card-header">
                          <h5 className="m-0">Produkte</h5>
                        </div>
                        <div className="card-body">
                          <h6 className="card-title" style={{float: "unset"}}><b>meine Produkte</b></h6>
                          <div>
                            <ProductsTableComponent
                              showSimpleTable={true}
                              rowLimit={this.viewModel.productsLimit}
                            />
                          </div>
                          <NavLink
                            to={"/admin/products"}
                          >
                            <span className="btn btn-primary">zu allen Produkten</span>
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card card-primary card-outline">
                        <div className="card-header">
                          <h5 className="m-0">Aufträge</h5>
                        </div>
                        <div className="card-body">
                          <h6 className="card-title" style={{float: "unset"}}><b>älteste Aufträge</b></h6>

                          <div>
                            <AllOrdersTableComponent
                              showSimpleTable={true}
                              rowLimit={this.viewModel.orderLimit}
                            />
                          </div>
                          <NavLink
                            to={"/admin/orders"}
                          >
                            <span className="btn btn-primary">zur Auftragsliste</span>
                          </NavLink>
                        </div>
                      </div>

                      <div className="card card-primary card-outline">
                        <div className="card-header">
                          <h5 className="m-0">Gebinde</h5>
                        </div>
                        <div className="card-body">
                          <h6 className="card-title" style={{float: "unset"}}><b>Liste aller Gebinde bei Kunden</b></h6>

                          <div>
                            <AllContainersAtClientsList />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </header>
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
    )
  }
}

export default function HomePageComponent() {
  const navigation = useNavigate();

  return (
    <_HomePageComponent
      navigation={navigation}
    />
  )
};