import React from "react";
import {Button} from "react-bootstrap";
import BaseVMComponent from "../../BaseVMComponent";
import ContainerConfirmationForm from "../../Shared/ContainerConfirmationForm";
import ConfirmOrderContainersViewModel from "../../../ViewModels/Orders/CompleteOrder/ConfirmOrderContainersViewModel";
import {ContainerConfirmationContainer} from "../../../ViewModels/ContainerConfirmationFormViewModel";
import {Order} from "../../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";


interface Props {
  order: Order,
  containersToConfirm: ContainerConfirmationContainer[],
  onCompleteClick: () => void,
}
interface State {
  submitButtonIsDisabled: boolean,
}
export default class ConfirmOrderContainers extends BaseVMComponent<Props, State, any, ConfirmOrderContainersViewModel> {

  constructor(props: Props) {
    super(props);

    this.state = {
      submitButtonIsDisabled: true,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new ConfirmOrderContainersViewModel(
      this.props.order,
      this.props.containersToConfirm,
    )
  }

  private async onCompleteClick() {
    try {
      await this.viewModel.onMarkAsDoneButtonClick()
      this.props.onCompleteClick()
    } catch (e) {
      alert(e)
    }
  }

  private onCanvasChange(isValid: boolean, base64StringOfSignature: string) {
    this.setState({
      submitButtonIsDisabled: !isValid,
    })

    this.viewModel.setBase64StringOfSignature(base64StringOfSignature)
  }

  render(): JSX.Element {
    return (
      <div className="">
        <ContainerConfirmationForm
          clientName={this.viewModel.order.client.fullName}
          containerToConfirm={this.viewModel.containersToConfirm}
          onCanvasChange={(isValid, base64String) =>
            this.onCanvasChange(isValid, base64String)
          }
        />

        <div className={"mt-3"}>
          <Button
            type={"button"}
            variant={"success"}
            onClick={() => this.onCompleteClick()}
            disabled={this.state.submitButtonIsDisabled}
          >
            Bestätigen und Auftrag abschließen
          </Button>
        </div>
      </div>
    )
  }
}
