import BaseVMComponent from "../BaseVMComponent";
import React, {FormEvent, Fragment} from "react";
import {InputComponent} from "../Shared/InputComponent";
import OrderFormViewModel from "../../ViewModels/Orders/OrderFormViewModel";
import {DropDownComponent} from "../Shared/DropDownComponent";
import CreateClientButton from "../Shared/CreateClientButton";
import {DateInputComponent} from "../Shared/DateInput";
import OrderProductDetailsRow from "./OrderForm/OrderProductDetailsRow";
import OrderProduct from "../../Model/OrderProduct";
import ContainersAtClientComponent from "../Shared/ContainersAtClientComponent";
import {Button} from "react-bootstrap";
import {NavigateFunction, NavLink, useNavigate} from "react-router-dom";
import AddContainersForm from "../Containers/AddContainersForm";
import LoadingComponent from "../Shared/LoadingComponent";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";
import {CreateOrderRequestData} from "../../Services/APIServiceGQLDocs/OrderDocs/CreateOrderGQLDoc";
import AddProductsForm from "../Products/AddProductsForm/AddProductsForm";
import OrderContainerDetailsRow from "../Shared/OrderContainerDetailsRow";


interface Props {
  orderData: Order | null,
  onSuccessfullySaved: (closeAfterSave: boolean, newIdValue: string | null) => void,
  sendSaveRequest: (data: CreateOrderRequestData) => Promise<any>,
  inputDisabled: boolean,
  buttons: JSX.Element,
  formType: "create" | "edit",
}
interface _Props extends Props {
  navigation: NavigateFunction,
}
interface _State {
}
class _OrderForm extends BaseVMComponent<_Props, _State, any, OrderFormViewModel> {

  protected initViewModel(): void {
    this.viewModel = new OrderFormViewModel(
      this.props.sendSaveRequest,
      this.props.orderData,
      this.props.formType,
      this.props.inputDisabled
    )
  }

  private onSuccessfullySaved(closeAfterSave: boolean, newIdValue: string | null) {
    this.props.onSuccessfullySaved(closeAfterSave, newIdValue)
  }

  private async onSaveClick(e: FormEvent) {
    const closeAfterSave = (e.nativeEvent as any).submitter?.dataset.saveAndClose != null

    await this.viewModel.onSaveClick(
      closeAfterSave,
      this.onSuccessfullySaved.bind(this)
    )
  }

  private getOrderProductElements(kindOfProducts: 'free' | 'notFree'): JSX.Element[] {
    let orderProductElements: JSX.Element[] = []

    let orderProducts: OrderProduct[] = []
    if (kindOfProducts === "notFree") {
      orderProducts = this.viewModel.getNotFreeOrderProducts()
    } else {
      orderProducts = this.viewModel.getFreeOrderProducts()
    }

    if (orderProducts.length === 0) {
      return [
        <span key={"no-products"}>keine Produkte hinzugefügt</span>
      ]
    }

    orderProducts.forEach((orderProduct) => {
      const product = this.viewModel.getProducts().find(p => p.id === orderProduct.productId)
      if (!product || orderProduct.productQuantity === 0) {
        return
      }

      orderProductElements.push(
        <OrderProductDetailsRow
          key={"EditOrderProductInputRow-edit-" + orderProduct.id}
          product={product}
          quantity={orderProduct.productQuantity}
          price={orderProduct.productBruttoPrice}
          isFreeProduct={orderProduct.isFreeProduct}
        />
      )
    })

    return orderProductElements
  }

  private getOrderContainerElements(): JSX.Element[] {
    let orderContainers: JSX.Element[] = []

    if (this.viewModel.getOrderContainers().length === 0) {
      return [
        <span key={"no-products"}>kein Gebinde hinzugefügt</span>
      ]
    }

    this.viewModel.getOrderContainers().forEach(orderContainer => {
      const container = this.viewModel.getContainers().find(p => p.id === orderContainer.containerId)
      if (!container || orderContainer.containerQuantity === 0) {
        return
      }

      orderContainers.push(
        <OrderContainerDetailsRow
          key={"orderContainer-" + orderContainer.id}
          containerInputData={{
            name: orderContainer.containerName,
            quantity: orderContainer.containerQuantity,
            bruttoPrice: orderContainer.containerBruttoPrice,
          }}
        />
      )
    })

    return orderContainers
  }

  private getProductInputFields(): JSX.Element | null {
    if (this.props.inputDisabled) {
      return null
    }

    return (
      <Fragment>
        <div className={"mt-3"}>
          <AddProductsForm
            initialProducts={this.viewModel.getCurrentProductsOfOrder()}
            onNewProductChange={(orderProducts) => {
              this.viewModel.setNewOrderProducts(orderProducts)
            }}
            inputDisabled={this.props.inputDisabled}
          />
        </div>
      </Fragment>
    )
  }

  private getContainerInputFields(): JSX.Element | null {
    if (this.props.inputDisabled) {
      return null
    }

    return (
      <Fragment>
        <div className={"mt-3"}>
          <AddContainersForm
            initialContainers={this.viewModel.getCurrentContainersOfOrder()}
            onNewOrderContainersChange={(orderContainers) => {
              this.viewModel.setNewOrderContainersToAdd(orderContainers)
            }}
          />
        </div>
      </Fragment>
    )
  }

  private getContainersAtClientElements(): JSX.Element {
    let content: JSX.Element | null = (
      <ContainersAtClientComponent
        containersAtClient={this.viewModel.getCurrentContainersAtClient()}
      />
    )

    return (
      <Fragment>
        <h4>Gebinde bei Kunde</h4>
        {content}
      </Fragment>
    )
  }

  render() {
    return (
      <form
        id={"order-form"}
        onSubmit={(e) => {
          if (e == null || e.target == null || !(e.target instanceof HTMLFormElement) || e.target.id === 'order-form') {
            this.onSaveClick(e)
          }
          e.preventDefault()
        }}
      >
        <div
          className={"data-input-wrapper"}
        >
          <div className={"row"}>
            <div className={"col-md-6"}>
              <div className={"row align-items-end justify-content-end"}>
                <div className={"col-7"}>
                  <DropDownComponent
                    labelTitle={"Kunde"}
                    onValueChange={(newIdValue) => {
                      this.viewModel.onClientSelect(newIdValue)
                    }}
                    onInputValueChange={(newValue) => {
                      this.viewModel.onClientDropDownInputChange(newValue)
                    }}
                    options={this.viewModel.getClientOptions()}
                    disabled={this.props.inputDisabled}
                    idValue={this.viewModel.selectedClient?.id?.toString() || null}
                    required={true}
                  />
                </div>
                <div className={"col-5"}>
                  {!this.props.inputDisabled &&
                    <>
                      <div className={"d-block d-md-none"}>
                        <CreateClientButton
                          className={"mb-3"}
                          buttonLabel={"erstellen"}
                        />
                      </div>
                      <div className={"d-none d-md-block"}>
                        <CreateClientButton
                          className={"mb-3"}
                        />
                      </div>
                    </>
                  }
                  {this.props.inputDisabled && this.viewModel.selectedClient != null &&
                    <NavLink to={"/admin/clients/client/" + this.viewModel.selectedClient.id}>
                      <Button
                        type={"button"}
                        className={"mb-3"}
                        variant={"secondary"}
                      >
                        zum Mandanten
                      </Button>
                    </NavLink>
                  }
                </div>
              </div>

              <InputComponent
                labelTitle={"Anschrifft"}
                type={"text"}
                value={this.viewModel.getAddressAsString()}
                onValueChange={() => {}}
                disabled={true}
                required={true}
              />
              <DropDownComponent
                labelTitle={"Status"}
                onValueChange={(newIdValue) => {
                  this.viewModel.onOrderStatusSelect(newIdValue)
                }}
                onInputValueChange={(newValue) => {
                }}
                options={this.viewModel.getOrderStatusOptions()}
                disabled={this.props.inputDisabled}
                idValue={this.viewModel.selectedOrderStatus?.id?.toString() || null}
                required={true}
              />

              <DateInputComponent
                labelTitle={"gewünschtes Zustelldatum"}
                value={this.viewModel.getDate()}
                onChange={value => this.viewModel.setDate(value)}
                disabled={this.props.inputDisabled}
              />

              <InputComponent
                labelTitle={"Notizen"}
                type={"textarea"}
                value={this.viewModel.getNotes()}
                onValueChange={(value) => this.viewModel.setNotes(value)}
                disabled={this.props.inputDisabled}
                required={false}
              />
            </div>
            <div className={"col-md-6"}>
              <div className={"d-block d-md-none"}>
                <hr/>
              </div>
              { this.getContainersAtClientElements() }
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-md-6"}>
              <div className={"order-content mt-4"}>
                <div className={"d-block d-md-none"}>
                  <hr/>
                </div>

                <h4 className={"mb-3"}>Bestellungsumfang</h4>
                { !this.viewModel.isInCreateMode() && this.props.inputDisabled &&
                  <>
                    { this.getOrderProductElements("notFree") }
                    <h5 className={"mt-3"}>Gratis Kisten</h5>
                    { this.getOrderProductElements("free") }
                  </>
                }

                { this.getProductInputFields() }
              </div>
            </div>

            <div className={"col-md-6"}>
              <div className={"order-content mt-4"}>
                <h4>Gebinde der Bestellung</h4>
                { !this.viewModel.isInCreateMode() && this.props.inputDisabled &&
                  <>
                    { this.getOrderContainerElements() }
                  </>
                }
                { this.getContainerInputFields() }
              </div>
            </div>
          </div>
        </div>


        { this.viewModel.successfullySave.visible &&
          <div className="alert alert-success mt-4" role="alert">
            Auftrag wurde erfolgreich gespeichert
          </div>
        }
        { this.viewModel.error.visible &&
          <div className="alert alert-danger mt-4" role="alert">
            { this.viewModel.error.string }
          </div>
        }

        <div className={"buttons"}>
          { this.props.buttons }
        </div>

        <LoadingComponent shown={this.viewModel.saveIsLoading} positionAbsolute={true} />
      </form>
    );
  }
}


function OrderForm(props: Props) {
  const navigation = useNavigate()

  return (
    <_OrderForm
      {...props}
      navigation={navigation}
    />
  )
}
OrderForm.defaultProps = {
  orderData: null,
  inputDisabled: false,
  buttons: null,
}

export default OrderForm;