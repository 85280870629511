import React, {Component, Fragment} from "react";
import ClientOrdersTableComponent from "../../Orders/ClientOrdersTable/ClientOrdersTableComponent";
import {NavLink, useParams} from "react-router-dom";
import PageWrapper from "../../Shared/PageWrapper";


interface Props {
  clientId: number,
  page: number,
}
class _ClientOrdersPage extends Component<Props, {}> {

  render() {
    return (
      <div>
        <div className={"mt-4 mb-3"}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to={`/admin/clients/client/${this.props.clientId}`}>
                  Mandantendetails
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Aufträge von Mandant</li>
            </ol>
          </nav>
        </div>

        <div className={"row"}>
          <div className={"col-12"}>
            <h3>Aufträge von Mandant</h3>
          </div>
        </div>

        <ClientOrdersTableComponent
          clientId={this.props.clientId}
          page={this.props.page}
          showSimpleTable={false}
        />
      </div>
    )
  }
}


export default function ClientOrdersPage() {
  const { clientId, page } = useParams();

  return (
    <_ClientOrdersPage
      clientId={parseInt(clientId!)}
      page={parseInt(page!)}
    />
  )
}
