import BaseViewModel from "../../../ViewModels/BaseViewModel";
import APIService from "../../../Services/APIService";
import Product from "../../../Model/Product";
import {OrderProductsInput} from "../../../Services/APIServiceGQLDocs/OrderProductDocs";


export interface AddProductsProduct {
  orderProductsInput: OrderProductsInput,
  product: Product | null,
  valid: boolean,
}
export default class AddProductsFormVM extends BaseViewModel {

  private products: Product[] = []
  private newOrderProductsToAdd: AddProductsProduct[] = []


  constructor(
    initProducts?: AddProductsProduct[],
  ) {
    super();

    if (initProducts != null) {
      this.newOrderProductsToAdd = initProducts
    }
  }

  viewDidMount() {
    super.viewDidMount();

    this.loadProducts()
  }

  private async loadProducts(): Promise<void> {
    const resp = await APIService.getInstance().loadProducts(
      0,
      50,
    )
    this.products = []
    resp.products.forEach(prod => {
      const prodAsProd: Product = {
        id: prod.id.toString(),
        productName: prod.name,
        productNettoPrice: prod.basePrice,
        productBruttoPrice: prod.bruttoPrice,
        taxRate: prod.taxRate,
      }
      this.products.push(prodAsProd)

      if (this.newOrderProductsToAdd.find(el => el.orderProductsInput.productId === prod.id) != null) {
        return
      }

      this.newOrderProductsToAdd.push({
        orderProductsInput: {
          productId: prod.id.toString(),
          quantity: 0,
          bruttoPrice: prod.bruttoPrice,
          isFreeProduct: false,
        },
        valid: false,
        product: prodAsProd,
      })
    })
    this.updateView()
  }

  addNewProduct() {
    this.newOrderProductsToAdd.push({
      orderProductsInput: {
        productId: "",
        quantity: 0,
        bruttoPrice: 0,
        isFreeProduct: false,
      },
      valid: false,
      product: null,
    })
    this.updateView()
  }

  getProducts(): Product[] {
    return this.products
  }

  getNewOrderProductsToAdd(): AddProductsProduct[] {
    return this.newOrderProductsToAdd
  }

  setProductIdOfNewOrderProductsToAdd(index: number, product: Product) {
    this.newOrderProductsToAdd[index].orderProductsInput.productId = product.id
    this.newOrderProductsToAdd[index].orderProductsInput.bruttoPrice = product.productBruttoPrice
    this.newOrderProductsToAdd[index].orderProductsInput.isFreeProduct = false
    this.newOrderProductsToAdd[index].product = product
    this.newOrderProductsToAdd[index].valid = true
    this.updateView()
  }

  setQuantityOfNewOrderProductsToAdd(index: number, quantity: number) {
    this.newOrderProductsToAdd[index].orderProductsInput.quantity = quantity
    this.updateView()
  }

  setPriceOfNewOrderProductsToAdd(index: number, price: number) {
    this.newOrderProductsToAdd[index].orderProductsInput.bruttoPrice = price
    this.updateView()
  }

  setIsFreeProductOfNewOrderProductsToAdd(index: number, checked: boolean) {
    this.newOrderProductsToAdd[index].orderProductsInput.isFreeProduct = checked
    this.updateView()
  }

  getQuantityOfNewOrderProductsToAdd(index: number): number {
    return this.newOrderProductsToAdd[index].orderProductsInput.quantity
  }

  getPriceOfNewOrderProductsToAdd(index: number): number {
    return this.newOrderProductsToAdd[index].orderProductsInput.bruttoPrice
  }

  getIsFreeProductOfNewOrderProductsToAdd(index: number): boolean {
    return this.newOrderProductsToAdd[index].orderProductsInput.isFreeProduct
  }

}