import {gql} from "graphql-request";

export const sendRefreshTokenDoc = () => {
  return gql`
    mutation refreshToken(
      $refresh_token: String!
    ) {
      refreshToken(
        refreshToken: $refresh_token
      ) {
        access_token
        refresh_token
      }
    }
  `;
};

export const sendLoginDoc = () => {
  return gql`
    mutation login($username: String!, $password: String!) {
      login(
        username: $username,
        password: $password
      ) {
        access_token
        refresh_token
      }
    }    
  `;
};





