import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import {AscOrDesc} from "../../Services/APIServiceInterfaces/IItemOrder";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";
import {OrderItemOrder, PossibleOrderItemOrderColNames} from "../../Components/Orders/OrdersTableComponent";


export default class AllOrdersTableComponentViewModel extends BaseViewModel {

  private rowLimit: number|null
  orders: Order[]
  allOrdersCount: number
  private showDone: boolean
  page: number
  private itemOrder: OrderItemOrder


  constructor(rowLimit: number|null) {
    super();

    this.orders = []
    this.allOrdersCount = 0
    this.showDone = false
    this.page = 1

    this.rowLimit = rowLimit
    this.itemOrder = {
      colName: "date",
      ascOrDesc: "asc",
    }
  }

  setShowDone(showDone: boolean) {
    this.showDone = showDone
    if (!this.showDone) {
      this.itemOrder = {
        colName: "id",
        ascOrDesc: "asc",
      }
    } else {
      this.itemOrder = {
        colName: "id",
        ascOrDesc: "desc",
      }
    }

    this.loadOrders()
  }

  getItemOrder(): OrderItemOrder {
    return this.itemOrder
  }

  setItemOrder(itemOrder: OrderItemOrder) {
    this.itemOrder = {
      colName: itemOrder.colName,
      ascOrDesc: itemOrder.ascOrDesc,
    }

    this.loadOrders()
  }

  setPage(page: number) {
    this.page = page
  }

  async loadOrders() {
    this.enterLoadingProcess()
    this.updateView()

    const resp = await APIService.getInstance().loadOrders({
      showDone: this.showDone,
      offset: this.getRequestOffset(),
      limit: this.getRequestLimit(),
      // simple, only single order setting
      itemOrder: [this.itemOrder],
    })
    this.orders = resp.orders
    this.allOrdersCount = resp.count

    this.leaveLoadingProcess()
    this.updateView()
  }

  getRequestOffset(): number {
    return this.getRequestLimit() * (this.page - 1)
  }

  getRequestLimit(): number {
    if (this.rowLimit != null) {
      return this.rowLimit
    }
    return 20
  }

}