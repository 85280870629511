import BaseViewModel from "./BaseViewModel";
import APIService from "../Services/APIService";


export default class AllContainersAtClientsViewModel extends BaseViewModel {

  allContainersAtClients: {
    containerId?: number,
    containerName: string,
    quantity: number,
  }[]


  constructor() {
    super();

    this.allContainersAtClients = []
  }

  async viewDidMount() {
    super.viewDidMount();

    const resp = await APIService.getInstance().getAllContainersAtClients()
    this.allContainersAtClients = resp.allContainersAtClients.allContainersAtClients

    // add sum row
    if (this.allContainersAtClients != null && this.allContainersAtClients.length >= 1) {
      let sum: number = 0
      this.allContainersAtClients.forEach(cac => {
        sum += cac.quantity
      })

      this.allContainersAtClients.push({
        containerId: undefined,
        containerName: "Summe",
        quantity: sum,
      })
    }

    this.updateView()
  }

}