import BaseVMComponent from "../BaseVMComponent";
import React from "react";
import CreateOrderModal from "../Orders/CreateOrderModal";
import CreateProductButtonViewModel from "../../ViewModels/Products/CreateProductButtonViewModel";
import CreateProductModal from "../Products/CreateProductModal";

interface Props {
  onSuccessfullySaved: () => void,
}
interface State {
  createModalOpen: boolean,
}
export default class CreateProductButton extends BaseVMComponent<Props, State, any, CreateProductButtonViewModel> {
  static defaultProps = {
    onSuccessfullySaved: () => {},
  }

  constructor(props: any) {
    super(props);

    this.state = {
      createModalOpen: false,
    }
  }

  protected initViewModel(): void {
    this.viewModel = new CreateProductButtonViewModel()
  }

  private onOpenModalButtonClick() {
    this.setState({
      createModalOpen: true,
    })
  }

  render() {
    return (
      <>
        <button
          className={"btn btn-primary"}
          onClick={this.onOpenModalButtonClick.bind(this)}
        >
          Neues Produkt erstellen
        </button>

        { this.state.createModalOpen &&
          <CreateProductModal
            onModalClose={() => {
              this.setState({
                createModalOpen: false,
              })
            }}
            onSuccessfullySaved={() => {
              this.props.onSuccessfullySaved()
            }}
          />
        }
      </>
    );
  }
}