import BaseViewModel from "../BaseViewModel";
import {APIError} from "../../Services/APIService";
import {getErrorTextByError} from "../../global/Error";
import GoogleMapsService from "../../Services/GoogleMapsService";
import {CreateClientRequestData} from "../../Services/APIServiceInterfaces/Client";
import BaseFormViewModel from "../BaseFormViewModel";


export default class ClientFormViewModel extends BaseFormViewModel<CreateClientRequestData> {

  companyName: string | null = null
  firstName: string | null = null
  lastName: string | null = null
  leadingTitle: string | null = null
  trailingTitle: string | null = null
  phone: string | null = null
  mail: string | null = null
  isPrivateCustomer: boolean = true

  street: string | null = null
  location: string | null = null
  postalCode: string | null = null

  notes: string | null = null


  setCompanyName(value: string | null) {
    this.companyName = value
    this.updateView()
  }

  setFirstname(value: string) {
    this.firstName = value
    this.updateView()
  }

  setLastName(value: string) {
    this.lastName = value
    this.updateView()
  }

  setLeadingTitle(value: string | null) {
    this.leadingTitle = value
    this.updateView()
  }

  setTrailingTitle(value: string | null) {
    this.trailingTitle = value
    this.updateView()
  }

  setPhone(value: string | null) {
    this.phone = value
    this.updateView()
  }

  setMail(value: string | null) {
    this.mail = value
    this.updateView()
  }

  setIsPrivateClient() {
    this.isPrivateCustomer = true
    this.companyName = null
    this.updateView()
  }

  setIsCompanyClient() {
    this.isPrivateCustomer = false
    this.updateView()
  }

  setStreet(value: string) {
    this.street = value
    this.updateView()
  }
  setLocation(value: string) {
    this.location = value
    this.updateView()
  }
  setPostalCode(value: string) {
    this.postalCode = value
    this.updateView()
  }

  setNotes(value: string | null) {
    this.notes = value
    this.updateView()
  }

  formDataIsValid(): boolean {
    if (
      (
        (this.companyName == null || this.companyName === "") &&
        (this.firstName == null || this.firstName === "") &&
        (this.lastName == null || this.lastName === "")
      ) ||
      this.street == null ||
      this.location == null ||
      this.postalCode == null
    ) {
      return false
    }

    return true
  }

  getCreateRequestData(): CreateClientRequestData {
    return {
      companyName: this.companyName,
      firstName: this.firstName,
      lastName: this.lastName,
      leadingTitle: this.leadingTitle,
      trailingTitle: this.trailingTitle,
      phone: this.phone,
      mail: this.mail,
      isPrivateCustomer: this.isPrivateCustomer,
      street: this.street!,
      location: this.location!,
      postalCode: this.postalCode!,
      notes: this.notes,
    };
  }

  getGoogleMapsLink(): string | null {
    if (
      !this.street ||
      !this.location ||
      !this.postalCode
    ) {
      return null
    }

    return GoogleMapsService.createGoogleMapsLink(
      this.street,
      this.location,
      this.postalCode
    )
  }

}