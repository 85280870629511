import React from 'react';
import BaseVMComponent from "../BaseVMComponent";
import TopNavbarViewModel from "../../ViewModels/TopNavbarViewModel";
import {NavLink, useNavigate} from "react-router-dom";
import {SetUserDataFunc, useAuth} from "../../Context/AuthContext";
import {UserData} from "../../Services/AuthenticationService";


interface TopNavbarComponentProps {
  navigation: any,
  setUserData: SetUserDataFunc,
  userData: UserData,
}

class _TopNavbarComponent extends BaseVMComponent<TopNavbarComponentProps, {}, {}, TopNavbarViewModel> {

  constructor(props: TopNavbarComponentProps) {
    super(props);
  }

  protected initViewModel() {
    this.viewModel = new TopNavbarViewModel()
  }

  private onLogoutButtonClick() {
    this.viewModel.onLogoutButtonClick(this.props.setUserData)
  }

  render(): JSX.Element {
    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <NavLink to={"/admin/home"}>
              <span className="nav-link">Home</span>
            </NavLink>
          </li>
        </ul>

        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Notifications Dropdown Menu */}
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell"></i>
              <span className="badge badge-warning navbar-badge">15</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-header">15 Notifications</span>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2"></i> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2"></i> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2"></i> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link" role="button" onClick={this.onLogoutButtonClick.bind(this)}>
              <i className="fas fa-sign-out-alt"></i>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
              <i className="fas fa-th-large"></i>
            </a>
          </li>
        </ul>
      </nav>
    )
  }
}


export default function TopNavbarComponent() {
  const navigation = useNavigate();
  const { setUserData, userData } = useAuth();

  return (
    <_TopNavbarComponent
      navigation={navigation}
      setUserData={setUserData}
      userData={userData!}
    />
  )
}