import React, {Component} from "react";
import {Spinner} from "react-bootstrap";


interface Props {
  shown: boolean,
  positionAbsolute: boolean,
}
export default class LoadingComponent extends Component<Props, any> {
  static defaultProps = {
    positionAbsolute: false,
  }


  render() {
    if (!this.props.shown) {
      return undefined
    }

    const loadingEl: JSX.Element = (
      <div
        className={"d-flex justify-content-center mt-2 mb-4 "}
      >
        <Spinner
          variant={"primary"}
          style={{
            height: 50,
            width: 50,
          }}
        />
      </div>
    )

    if (!this.props.positionAbsolute) {
      return loadingEl;
    } else {
      return (
        <div
          className={"loading-wrapper position-absolute"}
          style={{
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ffffff52",
            width: "100%",
            height: "100%",
          }}
        >
          {loadingEl}
        </div>
      )
    }
  }
}