import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {useLocalStorage} from "../Hooks/LocalStorage";
import AuthenticationService, {UserData} from "../Services/AuthenticationService";
import jwtDecode from "jwt-decode";


export declare type SetUserDataFunc = (userData: { accessToken: string, refreshToken: string }|null) => void
export declare type UserIsLoggedIn = () => boolean

interface AuthContextData {
  userData: UserData|null,
  setUserData: SetUserDataFunc,
  userIsLoggedIn: UserIsLoggedIn,
  userIsManager: () => boolean,
}

const AuthContext = createContext<AuthContextData>({
  userData: null,
  setUserData: (userData) => {},
  userIsLoggedIn: () => {return false},
  userIsManager: () => false,
});

interface AuthProviderProps {
  children: any
}
export const AuthProvider = (props: AuthProviderProps) => {
  const [userData, setUserData] = useLocalStorage(AuthenticationService.getInstance().getUserDataLocalStorageKey());

  const value = useMemo(
    () => ({
      userData: userData,
      setUserData: (userData: { accessToken: string, refreshToken: string }|null) => {
        let _userData = null

        if (userData?.accessToken) {
          _userData = AuthenticationService.getInstance().getDataFromAccessToken(
            userData?.accessToken,
            userData?.refreshToken
          )
        }

        // set data to localstorage
        setUserData(_userData)
      },
      userIsLoggedIn: () => {
        return userData != null
      },
      userIsManager: () => {
        const _userData = userData as UserData
        // noinspection RedundantIfStatementJS
        if (
          _userData.resource_access &&
          _userData.resource_access["flaschenwash-app"] &&
          _userData.resource_access["flaschenwash-app"].roles &&
          _userData.resource_access["flaschenwash-app"].roles.includes("app-manager")
        ) {
          return true
        }

        return false
      },
    }),
    [userData]
  );
  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext<AuthContextData>(AuthContext);
};
