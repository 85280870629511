import BaseViewModel from "../BaseViewModel";
import APIService from "../../Services/APIService";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";


export default class ClientOrdersTableComponentViewModel extends BaseViewModel {

  clientId: number
  private rowLimit: number|null
  orders: Order[]
  allOrdersCount: number
  showDone: boolean
  page: number


  constructor(
    clientId: number,
    rowLimit: number|null
  ) {
    super();

    this.clientId = clientId
    this.orders = []
    this.allOrdersCount = 0
    this.showDone = false
    this.page = 1

    this.rowLimit = rowLimit
  }

  setShowDone(showDone: boolean) {
    this.showDone = showDone
    this.loadOrders()
  }

  setPage(page: number) {
    this.page = page
  }

  async loadOrders() {
    this.enterLoadingProcess()
    this.updateView()

    const resp = await APIService.getInstance().loadOrders({
      clientId: this.clientId,
      showDone: this.showDone,
      offset: this.getRequestOffset(),
      limit: this.getRequestLimit(),
    })
    this.orders = resp.orders
    this.allOrdersCount = resp.count

    this.leaveLoadingProcess()
    this.updateView()
  }

  getRequestOffset(): number {
    return this.getRequestLimit() * (this.page - 1)
  }

  getRequestLimit(): number {
    if (this.rowLimit != null) {
      return this.rowLimit
    }
    return 20
  }

}