import {gql} from "graphql-request";
import {CreateProductRequestData} from "../APIServiceInterfaces/Product";

const ProductDoc = () => {
  return gql`
    id
    name
    basePrice
    bruttoPrice
  `
}

export const getProductsDoc = () => {
  return gql`
    query products($offset: Int!, $limit: Int!) {
      products(
        offset: $offset,
        limit: $limit
      ) {
        products {
        ` + ProductDoc() + `
        }
        count
      }
    }    
  `;
};

export const createProductDoc = () => {
  return gql`
    mutation createProduct(
      $name: String!
      $basePrice: Float!
      $taxRate: Float!
    ) {
      createProduct(
        name: $name
        basePrice: $basePrice
        taxRate: $taxRate
      ) {
        ` + ProductDoc() + `
      }
    }    
  `;
};

