import {gql} from "graphql-request";

const ContainerDoc = () => {
  return gql`
    id
    name
    basePrice
    bruttoPrice
  `
}

export const getContainersDoc = () => {
  return gql`
    query containers($offset: Int!, $limit: Int!) {
      containers(
        offset: $offset,
        limit: $limit
      ) {
        containers {
        ` + ContainerDoc() + `
        }
        count
      }
    }    
  `;
};

export const createContainerDoc = () => {
  return gql`
    mutation createContainer(
      $name: String!
      $basePrice: Float!
      $taxRate: Float!
    ) {
      createContainer(
        name: $name
        basePrice: $basePrice
        taxRate: $taxRate
      ) {
        ` + ContainerDoc() + `
      }
    }    
  `;
};

