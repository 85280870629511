import BaseVMComponent from "../BaseVMComponent";
import TableComponentViewModel from "../../ViewModels/TableComponentViewModel";
import React, {CSSProperties, Fragment} from "react";
import LoadingComponent from "./LoadingComponent";
import {Property} from "csstype";
import {IItemOrder} from "../../Services/APIServiceInterfaces/IItemOrder";
import {first} from "lodash";



export interface TableColumn {
  title: string
  valueKey: string
  resolveValue?: (tableDataEntry: any) => string
  backgroundColor?: (tableDataEntry: any) => string|null
  onColumnHeaderClick?: () => void
}
export const ActionButtonsCol: TableColumn = {
  title: "Aktionen",
  valueKey: "action-buttons"
}
export interface TableCellStyle {
  backgroundColor?: (rowNumber: number, columnNumber: number) => string | null,
  fontWeight?: (rowNumber: number, columnNumber: number) => Property.FontWeight | null,
}

interface Props {
  title: string,
  columns: TableColumn[],
  cellStyle: TableCellStyle,
  tableData: any[],
  isLoading: boolean,
  offset: number,
  limit: number,
  totalCount: number,
  pageNumber?: number,
  showSimpleTable: boolean,
  cardStyle: React.CSSProperties | null,
  cardBodyStyle: React.CSSProperties | null,
  hideTableHeading: boolean,
  onPageClick: (page: number) => void,
  onRowDetailsClick: (rowIndex: number, tableDataEntry: any) => void,
  leftButtons: JSX.Element | undefined,
  itemOrder?: IItemOrder,
}
interface State {
}
export default class TableComponent extends BaseVMComponent<Props, State, any, TableComponentViewModel> {
  static defaultProps: Partial<Props> = {
    title: "",
    pageNumber: 1,
    showSimpleTable: false,
    cardStyle: null,
    cardBodyStyle: null,
    hideTableHeading: false,
    onPageClick: () => {},
    onRowDetailsClick: () => {},
    cellStyle: {
      backgroundColor: undefined,
      fontWeight: undefined,
    }
  }


  constructor(props: Props) {
    super(props);
  }

  protected initViewModel(): void {
    this.viewModel = new TableComponentViewModel()
  }

  private getRowHeading(): JSX.Element | null {
    if (this.props.hideTableHeading) {
      return null
    }

    return (
      <thead>
        <tr>
          { this.props.columns.map(row => {
            let classes: string[] = []
            let arrowElement: JSX.Element | null = null
            if (!this.props.showSimpleTable && row.onColumnHeaderClick != null) {
              // order changing possible
              classes.push('clickable')

              if (this.props.itemOrder != null && this.props.itemOrder.colName === row.valueKey) {
                if (this.props.itemOrder.ascOrDesc === "asc") {
                  arrowElement = (
                    <span className={"ml-1"}>
                      ↓
                    </span>
                  )
                } else {
                  arrowElement = (
                    <span className={"ml-1"}>
                      ↑
                    </span>
                  )
                }
              }
            }

            return (
              <th
                key={row.valueKey}
                className={classes.join(" ")}
                onClick={() => {
                  if (row.onColumnHeaderClick != null) {
                    row.onColumnHeaderClick()
                  }
                }}
              >
                <span>
                  {row.title}
                </span>

                {arrowElement}
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }

  private getRowDataRows(): JSX.Element[] {
    let rows: JSX.Element[] = []

    for (const tableDataEntry of this.props.tableData) {
      const rowIndex = this.props.tableData.indexOf(tableDataEntry);
      rows.push(
        <tr key={rowIndex}>
          {this.getRowSingleDataRow(rowIndex, tableDataEntry)}
        </tr>
      )
    }

    if (rows.length === 0) {
      rows.push(
        <tr>
          <td colSpan={this.props.columns.length} style={{ textAlign: "center", opacity: 0.66 }}>
            keine Einträge
          </td>
        </tr>
      )
    }

    return rows
  }

  private getRowSingleDataRow(rowIndex: number, tableDataEntry: any): JSX.Element[] {
    let tableDataElements: JSX.Element[] = []

    for (const column of this.props.columns) {
      const colIndex = this.props.columns.indexOf(column);

      if (column === ActionButtonsCol) {
        // action buttons
        tableDataElements.push(
          <td key={"td-" + colIndex} className={"action-buttons"}>
            <button
              type={"button"}
              className={"btn btn-primary"}
              onClick={() => {
                this.onRowDetailsClick(rowIndex, tableDataEntry)
              }}
            >
              Details
            </button>
          </td>
        )
      } else {
        // data rows
        let value = tableDataEntry[column.valueKey]
        if (column.resolveValue != null) {
          value = column.resolveValue(tableDataEntry)
        }

        // custom style
        let style: any = {}
        // style from column
        if (column.backgroundColor != null) {
          const backgroundColor = column.backgroundColor(tableDataEntry)
          if (backgroundColor != null) {
            style.backgroundColor = backgroundColor
          }
        }
        // style from cell
        let backgroundColor = this.props.cellStyle.backgroundColor != null ? this.props.cellStyle.backgroundColor(rowIndex, colIndex) : null
        let fontWeight = this.props.cellStyle.fontWeight != null ? this.props.cellStyle.fontWeight(rowIndex, colIndex) : null
        if (backgroundColor != null) {
          style.backgroundColor = backgroundColor
        }
        if (fontWeight != null) {
          style.fontWeight = fontWeight
        }

        tableDataElements.push(
          <td key={"td-" + colIndex} style={style}>{value}</td>
        )
      }
    }

    return tableDataElements
  }

  private onRowDetailsClick(rowIndex: number, tableDataEntry: any) {
    this.props.onRowDetailsClick(rowIndex, tableDataEntry)
  }

  private getPageButtons() {
    let numPages = this.viewModel.getNumberOfPages(
      this.props.limit,
      this.props.totalCount
    )

    let pageButtons: JSX.Element[] = []

    // previous page button
    let previousBtnClass = "paginate_button page-item previous"
    let previousBtnDisabled = false
    if (this.props.pageNumber === 1) {
      previousBtnClass += " disabled"
      previousBtnDisabled = true
    }

    pageButtons.push(
      <li
        className={previousBtnClass}
        key={"prev"}
        onClick={() => this.onPageClick(this.props.pageNumber! - 1, previousBtnDisabled)}
      >
        <a
          className="page-link"
        >
          Zurück
        </a>
      </li>
    )

    // page numbers
    const maxNumOfPageButtons = 5
    const maxNumPagesBeforeCurrent = (maxNumOfPageButtons - 1) / 2
    let firstPageNum = 0
    if (this.props.pageNumber != null && maxNumPagesBeforeCurrent < (this.props.pageNumber - 1)) {
      pageButtons.push(
        <li
          className={"paginate_button page-item"}
          key={"before"}
        >
            <span
              className="page-link"
            >
              ...
            </span>
        </li>
      )
      firstPageNum = Math.min((this.props.pageNumber - 1) - maxNumPagesBeforeCurrent, numPages - maxNumOfPageButtons)
    }

    for (let i = firstPageNum; i < numPages; i++) {
      let className = "paginate_button page-item"
      if (i + 1 === this.props.pageNumber) {
        className += " active"
      }

      if (i >= maxNumOfPageButtons + firstPageNum) {
        pageButtons.push(
          <li
            className={className}
            key={i}
          >
            <span
              className="page-link"
            >
              ...
            </span>
          </li>
        )
        break;
      }

      pageButtons.push(
        <li
          className={className}
          key={i}
          onClick={() => this.onPageClick(i + 1)}
        >
          <a
            className="page-link"
          >
            {i + 1}
          </a>
        </li>
      )
    }

    // next page button
    let nextBtnClass = "paginate_button page-item previous"
    let nextBtnDisabled = false
    if ((this.props.pageNumber || 1) >= numPages) {
      nextBtnClass += " disabled"
      nextBtnDisabled = true
    }
    pageButtons.push(
      <li
        className={nextBtnClass}
        key={"next"}
        onClick={() => this.onPageClick(this.props.pageNumber! + 1, nextBtnDisabled)}
      >
        <a
          className="page-link"
        >
          Weiter
        </a>
      </li>
    )

    return pageButtons
  }

  private onPageClick(page: number, disabled: boolean = false) {
    if (disabled) {
      return
    }
    this.props.onPageClick(page)
  }

  render() {
    let cardStyle: CSSProperties = {}
    let cardBodyStyle: CSSProperties = {}
    if (this.props.cardStyle == null) {
      if (this.props.showSimpleTable) {
        cardStyle = {
          boxShadow: "unset"
        }
      }
    } else {
      cardStyle = this.props.cardStyle
    }
    if (this.props.cardBodyStyle == null) {
      if (this.props.showSimpleTable) {
        cardBodyStyle = {
          padding: 0,
        }
        cardStyle.marginTop = "1rem"
      }
    } else {
      cardBodyStyle = this.props.cardBodyStyle
    }

    return (
      <div
        className="card"
        style={cardStyle}
      >
        {!this.props.showSimpleTable &&
          <div className="card-header">
            <h4 className="card-title">{this.props.title}</h4>
          </div>
        }
        <div
          className="card-body"
          style={cardBodyStyle}
        >
          <LoadingComponent
            shown={this.props.isLoading}
          />

          { !this.props.isLoading &&
            <Fragment>
              <table
                className="table table-bordered table-striped d-block d-md-table"
                style={{
                  width: "100%",
                  overflowX: "scroll",
                }}
              >
                { this.getRowHeading() }
                <tbody>
                { this.getRowDataRows() }
                </tbody>
              </table>

              { !this.props.showSimpleTable &&
                <div className={"table-footer mt-4"}>
                  <div className="">
                    {this.props.offset + 1} bis {this.props.offset + this.props.limit} angezeigt ({this.props.totalCount} insgesamt)
                  </div>

                  <div className="row mt-1">
                    <div className="col-sm-12 col-md-6">
                      { this.props.leftButtons &&
                        this.props.leftButtons
                      }
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="d-flex justify-content-end"
                        id="example1_paginate"
                      >
                        <ul className="pagination">
                          { this.getPageButtons() }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </Fragment>
          }
        </div>
      </div>
    )
  }
}


