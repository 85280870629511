import {gql} from "graphql-request";
import {IOrderDoc, Order} from "./interfaces/IOrderGQLDoc";
import {OrderProductsInput} from "../OrderProductDocs";
import {OrderContainersInput} from "../OrderContainersDocs";


export interface CreateOrderRequestData {
  clientId: string,
  orderStatusId: string,
  date: Date|null,
  notes: string|null,
  products: OrderProductsInput[]
  containers: OrderContainersInput[]
}
export declare type CreateOrderRequestResponse = {
  order: Order,
}

export const createOrderDoc = () => {
  return gql`
    mutation createOrder(
      $clientId: ID!
      $orderStatusId: ID!
      $date: Date
      $notes: String
      $products: [OrderProductsInput!]!
      $containers: [OrderContainersInput!]!
    ) {
      createOrder(
        clientId: $clientId
        orderStatusId: $orderStatusId
        date: $date
        notes: $notes
        products: $products
        containers: $containers
      ) {
        ` +
    IOrderDoc()
    + `
      }
    }  
  `;
};

