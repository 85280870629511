import React from "react";
import Product from "../../../Model/Product";
import AddNewOrderProductInputRow from "../../Shared/AddNewOrderProductInputRow";
import BaseVMComponent from "../../BaseVMComponent";
import AddProductsFormVM, {AddProductsProduct} from "./AddProductsFormVM";


interface Props {
  inputDisabled?: boolean,
  onNewProductChange: (orderProducts: AddProductsProduct[]) => void,
  initialProducts?: AddProductsProduct[],
}
interface State {
}

export default class AddProductsForm extends BaseVMComponent<Props, State, any, AddProductsFormVM> {

  constructor(props: Props) {
    super(props);

    console.log("debug", "initialProducts", props.initialProducts)
  }

  protected initViewModel(): void {
    this.viewModel = new AddProductsFormVM(
      this.props.initialProducts
    )
  }

  private onNewProductChange() {
    this.props.onNewProductChange(this.viewModel.getNewOrderProductsToAdd())
  }

  private ProductElements(): JSX.Element[] {
    let productElements: JSX.Element[] = []

    this.viewModel.getNewOrderProductsToAdd().forEach((newOrderProductToAdd, index) => {
      const selectedProduct: Product | null = this.viewModel.getProducts().find(p => {
        return p.id === newOrderProductToAdd.orderProductsInput.productId
      }) || null

      const key = "AddNewOrderProductInputRow-" + index + "-" + newOrderProductToAdd.orderProductsInput.productId

      productElements.push(
        <AddNewOrderProductInputRow
          id={key}
          key={key}
          onProductSelectionChange={(product) => {
            this.viewModel.setProductIdOfNewOrderProductsToAdd(index, product)

            this.onNewProductChange()
          }}
          selectableProducts={this.viewModel.getProducts()}
          selectedProduct={selectedProduct}
          quantity={this.viewModel.getQuantityOfNewOrderProductsToAdd(index)}
          onQuantityChange={(value) => {
            this.viewModel.setQuantityOfNewOrderProductsToAdd(index, value)

            this.onNewProductChange()
          }}
          price={this.viewModel.getPriceOfNewOrderProductsToAdd(index)}
          onPriceChange={(value) => {
            this.viewModel.setPriceOfNewOrderProductsToAdd(index, value)

            this.onNewProductChange()
          }}
          onIsFreeProductChange={checked => {
            this.viewModel.setIsFreeProductOfNewOrderProductsToAdd(index, checked)

            this.onNewProductChange()
          }}
          isFreeProduct={this.viewModel.getIsFreeProductOfNewOrderProductsToAdd(index)}
          inputDisabled={this.props.inputDisabled || false}
        />
      )
    })

    return productElements
  }

  render() {
    return (
      <div className={"mt-3"}>
        {this.ProductElements()}
        <button
          type="button"
          className="btn btn-secondary btn-sm mb-4"
          onClick={(e) => {
            this.viewModel.addNewProduct()
            e.preventDefault()
          }}
        >
          + Produkt hinzufügen
        </button>
      </div>
    );
  }
}