import BaseVMComponent from "../BaseVMComponent";
import React, {FormEvent} from "react";
import {InputComponent} from "../Shared/InputComponent";
import ContainerFormViewModel from "../../ViewModels/Containers/ContainerFormViewModel";
import {CreateContainerRequestData} from "../../Services/APIServiceInterfaces/Container";
import LoadingComponent from "../Shared/LoadingComponent";
import {Order} from "../../Services/APIServiceGQLDocs/OrderDocs/interfaces/IOrderGQLDoc";


interface Props {
  orderData: Order|null,
  onSuccessfullySaved: (closeAfterSave: boolean) => void,
  sendSaveRequest: (data: CreateContainerRequestData) => Promise<any>,
  inputDisabled: boolean,
  buttons: JSX.Element,
}
interface State {
}
export default class ContainerForm extends BaseVMComponent<Props, State, any, ContainerFormViewModel> {
  static defaultProps = {
    orderData: null,
    inputDisabled: false,
    buttons: null,
  }


  protected initViewModel(): void {
    this.viewModel = new ContainerFormViewModel(
      this.props.sendSaveRequest
    )
  }

  private onSuccessfullySaved(closeAfterSave: boolean) {
    this.props.onSuccessfullySaved(closeAfterSave)
  }

  private async onSaveClick(e: React.FormEvent<HTMLFormElement>) {
    const closeAfterSave = (e.nativeEvent as any).submitter?.dataset.saveAndClose != null

    await this.viewModel.onSaveClick(
      closeAfterSave,
      this.onSuccessfullySaved.bind(this)
    )
  }

  render() {
    return (
      <form
        className={"position-relative"}
        onSubmit={(e) => {
          this.onSaveClick(e)
          e.preventDefault()
        }}
      >
        <div
          className={"data-input-wrapper"}
        >
          <InputComponent
            labelTitle={"Name"}
            type={"text"}
            value={this.viewModel.getName()}
            onValueChange={(value) => this.viewModel.setName(value)}
            required={true}
          />
          <InputComponent
            labelTitle={"Nettopreis"}
            type={"number"}
            value={this.viewModel.getNettoPrice()}
            onValueChange={(value) => this.viewModel.setNettoPrice(value)}
            required={true}
          />
          <InputComponent
            labelTitle={"Steuersatz (in %)"}
            type={"number"}
            value={this.viewModel.getTaxRate()}
            onValueChange={(value) => this.viewModel.setTaxRate(value)}
            required={true}
          />
          <InputComponent
            labelTitle={"Bruttopreis"}
            type={"number"}
            value={this.viewModel.getBruttoPrice()}
            onValueChange={() => {}}
            disabled={true}
            required={true}
          />
        </div>


        { this.viewModel.successfullySave.visible &&
          <div className="alert alert-success mt-4" role="alert">
            Gebindeart wurde erfolgreich gespeichert
          </div>
        }
        { this.viewModel.error.visible &&
          <div className="alert alert-danger mt-4" role="alert">
            { this.viewModel.error.string }
          </div>
        }

        <div className={"buttons"}>
          { this.props.buttons }
        </div>

        <LoadingComponent
          shown={this.viewModel.saveIsLoading}
          positionAbsolute={true}
        />
      </form>
    );
  }
}


