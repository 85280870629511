import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {useAuth} from "../../Context/AuthContext";

function SideNavbarComponent(): JSX.Element {
  const { userData, userIsManager } = useAuth();

  let userName = ""
  if (userData?.name) {
    userName = userData.name
  }
  if (userIsManager()) {
    userName += " (Manager)"
  }

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <a href="index3.html" className="brand-link" style={styles.brandLink}>
        <div
          // style={styles.imageWrapper}
          style={{ display: "none" }}
        >
          <img
            src="/assets/logo.svg"
            alt="RBQ Logo"
            className="brand-image img-circle elevation-3"
            style={styles.image}
          />
        </div>
        <span className="brand-text font-weight-light" style={styles.brandSpan}>RBQ CRM</span>
      </a>

      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div
            className="image"
            style={styles.imageWrapper}
          >
            <img
              src="/assets/logo.svg"
              className="img-circle elevation-2"
              alt="User Image"
              style={styles.image}
            />
          </div>
          <div className="info">
            <a href="#" className="d-block">{userName}</a>
          </div>
        </div>

        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink to={"/admin/home"} className={"nav-link"}>
                <i className="nav-icon fas fa-home"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to={"/admin/clients"} className={"nav-link"}>
                <i className="nav-icon fas fa-user"></i>
                <p>Mandanten</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to={"/admin/orders"} className={"nav-link"}>
                <i className="nav-icon fas fa-box"></i>
                <p>Aufträge</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to={"/admin/products"} className={"nav-link"}>
                <i className="nav-icon fas fa-box"></i>
                <p>Produkte</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to={"/admin/containers"} className={"nav-link"}>
                <i className="nav-icon fas fa-box"></i>
                <p>Gebinde</p>
              </NavLink>
            </li>
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  )
}

export default SideNavbarComponent;


const styles: { [key: string]: React.CSSProperties } = {
  brandLink: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  brandSpan: {
    marginLeft: 10,
  },
  imageWrapper: {
    background: "#F2F8FB",
    width: 35,
    height: 35,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
    borderRadius: "100%",
  },
  image: {
    height: "100%",
    width: "auto",
    margin: 0,
  }
}